import styled from '@emotion/styled/macro';
import { Divider, Typography } from '@mui/material';
import { tyrioUI } from '@tyrio/ui-library';
import { useVehicleCtx } from '../../../../context/VehicleContext';
import { get, isEmpty } from 'lodash';
import PhotoIcon from '@mui/icons-material/Photo';
import { PhotoShape } from '@tyrio/dto';
import { useCallback, useMemo } from 'react';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import { usePosCtx } from '../../../../context/POSContext';

interface IWheelsCard {
  brand: string | null;
  color: string | null;
  rimSizes: (string | null)[];
  stockListRims: (string | null)[];
  price: (
    | {
        price: number | null;
        inclusiveVatPrice: number;
      }
    | undefined
  )[];
  modelName: string | null;
  onClick: () => void;
  photos?: PhotoShape[];
  isSelected?: boolean;
}

const Rim = ({
  size,
  selected,
  onLager,
}: {
  size: string;
  selected: boolean;
  onLager: boolean;
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <RimDetails selected={selected}>{size}</RimDetails>
      {onLager && <OnLager />}
    </div>
  );
};

export const WheelsCard = ({
  brand,
  color,
  rimSizes,
  price,
  modelName,
  onClick,
  photos,
  isSelected = false,
  stockListRims,
}: IWheelsCard) => {
  const { filter } = useVehicleCtx();
  const { isVatIncluded } = usePosCtx();
  const allRimSizes = rimSizes.filter((r) => r !== null) as string[];
  const filterRimSize = get(filter, 'rimDiameter', null);

  const getPrice = useCallback(() => {
    if (price && !isEmpty(price)) {
      const p = isVatIncluded
        ? price.flatMap((p) => p?.inclusiveVatPrice)
        : price.flatMap((p) => p?.price);

      const min = Math.min(...(p as number[])).toFixed(2);
      const max = Math.max(...(p as number[])).toFixed(2);
      if (min === max) return `${min}€`;
      return `${min}€ - ${max}€`;
    } else return undefined;
  }, [isVatIncluded, price]);

  const priceRange = useMemo(() => {
    return getPrice();
  }, [getPrice]);

  return (
    <WheelsCardWrapper onClick={onClick} selected={isSelected}>
      <ModelWrapper>
        <Typography fontSize={18} fontWeight={700}>
          {brand}
        </Typography>
        <Typography
          color={tyrioUI.colors.orange.O100}
          fontSize={18}
          fontWeight={700}
        >
          {modelName}
        </Typography>

        {photos && photos.length > 0 ? (
          <Img src={photos[0].url} alt="wheel" />
        ) : (
          <PhotoIcon
            color="disabled"
            sx={{ height: '200px', width: '200px' }}
          />
        )}

        <Typography color={tyrioUI.colors.black.B70}>{color}</Typography>
      </ModelWrapper>

      <Divider />

      <Div>
        <TextWrapper>
          <Typography color={tyrioUI.colors.black.B70} fontSize={12}>
            Available rim sizes:
          </Typography>
          {stockListRims.length > 0 && (
            <Typography
              fontSize={12}
              color={tyrioUI.colors.green.B100}
              fontWeight={600}
            >
              ON STOCK
            </Typography>
          )}
        </TextWrapper>
        <RimWrapper>
          {(allRimSizes ?? []).map((r: string, idx) => (
            <Rim
              size={r}
              key={idx}
              selected={filterRimSize?.includes(r) ?? false}
              onLager={stockListRims.includes(r)}
            />
          ))}
        </RimWrapper>
      </Div>

      <Div>
        <Typography color={tyrioUI.colors.black.B70} fontSize={12}>
          Price:
        </Typography>
        {priceRange ? (
          <Typography
            color={tyrioUI.colors.black.B100}
            fontSize={20}
            fontWeight={400}
          >
            {priceRange}
          </Typography>
        ) : (
          <BlockRoundedIcon color="warning" />
        )}
      </Div>
    </WheelsCardWrapper>
  );
};

const WheelsCardWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  border: ${({ selected }) =>
    selected
      ? ` 2px solid ${tyrioUI.colors.lightBlue};`
      : `1px solid ${tyrioUI.colors.black.B40}`};
  border-radius: 8px;
  width: 239px;
  height: 421px;
  padding: ${({ selected }) => (selected ? '16px' : '17px')};

  justify-content: space-between;
  cursor: pointer;

  &:hover {
    border-color: ${tyrioUI.colors.lightBlue};
  }
`;

const ModelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Div = styled.div``;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RimWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px 10px;
`;

const RimDetails = styled.div<{ selected?: boolean; onLager?: boolean }>`
  border-radius: 50%;
  border: 1px solid ${({ selected }) => (selected ? '#1976D2' : '#dfe3e8')};
  height: 24px;
  width: 24px;
  font-size: 14px;
  color: ${({ selected }) => (selected ? '#1976D2' : '#919eab')};

  align-items: center;
  display: flex;
  justify-content: center;
  background: ${({ selected, onLager }) =>
    onLager ? 'green' : selected && '#1976D20A'};
`;

const Img = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Line = styled.div`
  height: 1px;
  width: 24px;
  background: ${tyrioUI.colors.orange.O100};
  position: absolute;
  top: 50%;
  rotate: -45deg;
`;

const OnLager = styled.div`
  height: 3px;
  width: 24px;
  background: ${tyrioUI.colors.green.B100};
  margin-top: 2px;
`;
