import styled from '@emotion/styled/macro';
import { Divider } from '@mui/material';
import { MainBranchLocations } from '@tyrio/dto';
import {
  Location,
  SingleStorageItem,
  StockListHeader,
} from '@tyrio/wms-ui-library';
import { useContext, useEffect, useState } from 'react';
import NoItemsFound from '../../../../components/NoItems/NoItemsFound';
import { StockListContext } from '../../../../context/StockListContext';
import { useLocations } from './helpers/locations-helper';
import { isDotOlderThanThreeYears } from './helpers/dot-helper';
import { usePosCartData } from '../../../pos/steps/Cart/helpers/cart-data';

interface SingleStorageProps {
  branchName: string;
  item: MainBranchLocations[];
}

export const ProductDetailsStock = (props: SingleStorageProps) => {
  const { branchName, item } = props;
  const { setActiveDrawer, onlyAvailable, setSelectedLocation } =
    useContext(StockListContext);

  const { modalData: posData } = usePosCartData();

  const handleClick = (data: MainBranchLocations) => {
    setActiveDrawer('LOCATION_INFO');
    setSelectedLocation(data);
  };

  useEffect(() => {
    if (onlyAvailable)
      setItemLength(
        item.filter((item) => item.quantity - item.reserved > 0).length
      );
    else setItemLength(item.length);
  }, [item, onlyAvailable]);

  const shouldRenderRow = (available: number) => {
    return !onlyAvailable || (onlyAvailable && available > 0);
  };

  const sortedLocations = useLocations(item);

  const [itemLength, setItemLength] = useState(sortedLocations.length);

  return (
    <Wrapper>
      <StockListHeader branchName={branchName} onlyAvailable={onlyAvailable} />
      <Divider />

      <div>
        {itemLength > 0 ? (
          sortedLocations?.map((p, index) => {
            let posReserved = 0;
            posData.forEach((item) => {
              if (item.key.startsWith(`${item.req.ean}_${item.req.dot}`))
                posReserved += item.req.reserved;
            });
            return (
              shouldRenderRow(p.quantity - p.reserved - posReserved) && (
                <SingleStorageItem
                  onStock={p.quantity ?? 0}
                  reserved={p.reserved + posReserved}
                  available={
                    p.quantity ? p.quantity - p.reserved - posReserved : 0
                  }
                  component={
                    <Location
                      locationName={p.locationMeta.displayName}
                      color={p.locationMeta.zoneColor}
                      handleClick={() => handleClick(p)}
                    />
                  }
                  year={p.dot}
                  chipColor={
                    isDotOlderThanThreeYears(p.dot)
                      ? 'error'
                      : p.dot === 'XXXX'
                      ? 'primary'
                      : 'success'
                  }
                  key={index}
                  setActiveDrawer={setActiveDrawer}
                  onlyAvailable={onlyAvailable}
                />
              )
            );
          })
        ) : (
          <NoItemsFound />
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 16px 0;
`;

export const StockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 200px;
  width: 100%;
  justify-content: space-around;
`;
