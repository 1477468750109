/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, FormControl, MenuItem, TextField } from '@mui/material';
import { InputOption } from '@tyrio/dto';
import { Control, Controller } from 'react-hook-form';

interface SubcategoryDropdownProps {
  control: Control<any, any>;
  id: string;
  label: string;
  subcategoryId?: number;
  subcategories: InputOption[];
}
export const SubcategoryDropdownInput = ({
  id,
  control,
  label,
  subcategories,
}: SubcategoryDropdownProps) => {
  return (
    <FormControl fullWidth>
      <Controller
        name={id}
        control={control}
        render={({ field, formState: { errors } }: any) => {
          let value = field.value;
          if (typeof field.value === 'number')
            value = subcategories.find((c) => c.value === field.value);
          if (typeof field.value === 'string')
            value = subcategories.find(
              (c) => c.value === parseInt(field.value, 10)
            );

          return (
            <Autocomplete
              fullWidth
              disablePortal
              id={id}
              options={subcategories.length > 0 ? subcategories : []}
              onChange={(e, x) => field.onChange(x)}
              value={value}
              getOptionLabel={(data) => data.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors[id]}
                  helperText={errors[id]}
                  label={label}
                />
              )}
              renderOption={(item, el) => (
                <MenuItem {...item}>{el.label}</MenuItem>
              )}
            />
          );
        }}
      />
    </FormControl>
  );
};
