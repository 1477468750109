import {
  BranchesStockResponse,
  DBProductPayload,
  PosItem,
  PosLineItems,
  StockType,
  SupplierLineItems,
  SupplierStockResponse,
} from '@tyrio/dto';
import { useContext } from 'react';
import { CartData, usePosCartCtx } from '../../../../../context/PosCartContext';
import { POSContext } from '../../../../../context/POSContext';
import { useWS } from '../../../../../context/WSContext';
import { useGetSuppliersStock } from '../../../query/get-suppliers-stock';
import { useGetProductByEan } from '../queries/useProduct';
import { usePosCartData } from './cart-data';
import { useGetBranchesStock } from '../../../query/get-branches-stock';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { customToast } from '../../../../stock-list/components/Cart/CartToast';

const findBranchesLineItem = (
  branchesStock: BranchesStockResponse[],
  ean: string
) => {
  for (const branch of branchesStock) {
    const foundItem = branch.lineItems.find(
      (item: PosLineItems) => item.ean === ean
    );
    if (foundItem) return { branch, item: foundItem };
  }
  return undefined;
};

const findSupplierLineItem = (
  supplierStock: SupplierStockResponse[],
  scannedBarcode: string
) => {
  for (const supplier of supplierStock) {
    const foundItem = supplier.lineItems.find(
      (item: SupplierLineItems) => item.ean === scannedBarcode
    );
    if (foundItem) return { supplier, item: foundItem };
  }
  return undefined;
};

const maxAvailableQtyReached = (setScannedBarcode: (a: string) => void) => {
  ToastHelper.showToast(
    'Cart',
    ToastType.WARNING,
    ToastMessageType.CUSTOM_ERROR,
    'You reached maximum available quantity!'
  );
  setScannedBarcode('');
};

const prepareCartData = (
  product: DBProductPayload,
  data: PosItem | SupplierStockResponse | BranchesStockResponse,
  stockType: StockType,
  dot: string,
  price: number,
  inclusiveVat: number,
  reserved: number,
  cashPrice: number,
  vatCashPrice: number,
  purchasePrice: number
) => {
  const branchName =
    stockType !== 'SUPPLIERS STOCK'
      ? (data as unknown as BranchesStockResponse).branchName
      : (data as unknown as SupplierStockResponse).supplierName;

  const generatedKey = `${product?.ean}_${dot}_${branchName}_${stockType}`;

  const model = product.model;
  const item = {
    key: generatedKey,
    req: {
      quantity: 1,
      ean: product.ean,
      dot: dot,
      sku: product.sku,
      uid: product.uid,
      productName: product.productName,
      productBrand: model?.brand ?? '',
      productDescription: `${product.sku ?? ''}  ${
        model?.category?.name ? `• ${model.category?.name}` : ''
      }`,
      discount: 0,
      price,
      rebate: 0,
      total: 0,
      reserved,
      realQuantity: 0,
      categoryId: model?.category?.id,
      inclusiveVat,
      cashPrice,
      vatCashPrice,
      purchasePrice,
    },
    stockType,
    branchId:
      stockType !== 'SUPPLIERS STOCK'
        ? (data as unknown as BranchesStockResponse).branchId
        : undefined,
    supplierId:
      stockType === 'SUPPLIERS STOCK'
        ? (data as unknown as SupplierStockResponse).supplierId
        : '',
  };

  return item;
};

export const useCurrentPOSCartData = () => {
  const ws = useWS();
  const { scannedBarcode, setScannedBarcode, timer } = usePosCartCtx();
  const { modalData } = usePosCartData();

  const {
    myStockItems,
    branchesStockItems,
    supplierStockItems,
    selectedWarehouseId,
    selectedCustomer,
    badgeContent,
    setBadgeContent,
    selectedTableRow,
    shouldShowDot,
  } = useContext(POSContext);

  const { suppliersStock } = useGetSuppliersStock(
    scannedBarcode ?? '',
    '',
    selectedWarehouseId?.toString() as string,
    selectedCustomer?.id ?? 'default-customer',
    badgeContent,
    setBadgeContent
  );

  const { onStock, warehouseStock } = useGetBranchesStock(
    scannedBarcode ?? '',
    selectedWarehouseId?.toString(),
    badgeContent,
    setBadgeContent,
    selectedTableRow?.stockType,
    shouldShowDot
  );

  const product = useGetProductByEan(scannedBarcode);

  const doesProductExist = () => {
    if (!product.productData) return false;
    return true;
  };

  const isProductInCart = () => {
    const isInCart = modalData.find((item) => item.req.ean === scannedBarcode);
    return isInCart ? true : false;
  };

  const addExisting = () => {
    if (scannedBarcode !== '') {
      modalData.forEach((posItem: CartData) => {
        if (posItem.req.ean === scannedBarcode) {
          if (posItem.req.realQuantity < posItem.req.quantity + 1) {
            maxAvailableQtyReached(setScannedBarcode);
            return;
          }

          ws.socket?.emit('update-pos-cart-item', {
            quantity: posItem.req.quantity + 1,
            key: posItem.key,
            stockType: posItem.stockType,
          });
          timer?.restart();
          customToast('Product added to cart!', 'success');

          setScannedBarcode('');
        }
      });
    }
    return true;
  };

  const addNew = () => {
    if (product.productData && product.productData.model) {
      let item = null;
      let stockType = '';

      const inMyStock = myStockItems.unmatchedItems.find(
        (item) => item.product.uid === product.productData?.uid
      );
      const inBranchesStock = branchesStockItems.unmatchedItems.find(
        (item) => item.product.uid === product.productData?.uid
      );
      const inSupplierStock = supplierStockItems.unmatchedItems.find(
        (item) => item.product.uid === product.productData?.uid
      );

      if (inMyStock) {
        if (onStock && findBranchesLineItem(onStock, scannedBarcode)) {
          const found = findBranchesLineItem(onStock, scannedBarcode);
          if (found) {
            if (found.item.quantity === 0) {
              maxAvailableQtyReached(setScannedBarcode);
              return;
            }
            item = prepareCartData(
              product.productData,
              found.branch,
              'ON STOCK',
              found?.item.dot,
              found?.item.price ?? 0,
              found?.item.inclusiveVatPrice ?? 0,
              found?.item.reserved,
              found?.item.cashPrice ?? 0,
              found?.item.vatCashPrice ?? 0,
              found?.item.purchasePrice ?? 0
            );
          }
          stockType = 'ON STOCK';
        }
      } else if (inBranchesStock) {
        if (
          warehouseStock &&
          findBranchesLineItem(warehouseStock, scannedBarcode)
        ) {
          const found = findBranchesLineItem(warehouseStock, scannedBarcode);
          if (found) {
            if (found.item.quantity === 0) {
              maxAvailableQtyReached(setScannedBarcode);
              return;
            }
            item = prepareCartData(
              product.productData,
              found.branch,
              'BRANCHES STOCK',
              found?.item.dot,
              found?.item.price ?? 0,
              found?.item.inclusiveVatPrice ?? 0,
              found?.item.reserved,
              found?.item.cashPrice ?? 0,
              found?.item.vatCashPrice ?? 0,
              found?.item.purchasePrice ?? 0
            );
          }
          stockType = 'BRANCHES STOCK';
        }
      } else if (inSupplierStock) {
        if (findSupplierLineItem(suppliersStock, scannedBarcode)) {
          const found = findSupplierLineItem(suppliersStock, scannedBarcode);

          if (found) {
            if (found.item.quantity === 0) {
              maxAvailableQtyReached(setScannedBarcode);
              return;
            }

            item = prepareCartData(
              product.productData,
              found.supplier,
              'SUPPLIERS STOCK',
              found?.item.dot,
              found?.item.price ?? 0,
              found?.item.inclusiveVatPrice ?? 0,
              found?.item.reserved,
              found?.item.cashPrice ?? 0,
              found?.item.vatCashPrice ?? 0,
              found?.item.purchasePrice ?? 0
            );
          }
          stockType = 'SUPPLIERS STOCK';
        }
      } else {
        ToastHelper.showToast(
          'Wrong product',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          `Could not find product: ${scannedBarcode}!`
        );
        setScannedBarcode('');
        return;
      }

      if (item) {
        ws.socket?.emit('add-to-pos-cart', {
          key: item.key,
          req: {
            quantity: item.req.quantity,
            ean: item.req.ean,
            dot: item.req.dot,
            sku: item.req.sku,
            uid: item.req.uid,
            productName: item.req.productName,
            productBrand: item.req.productBrand,
            productDescription: item.req.productDescription,
            price: item.req.price,
            reserved: item.req.quantity,
            total: item.req.total,
            realQuantity: item.req.realQuantity,
            categoryId: item.req.categoryId,
            inclusiveVat: item.req.inclusiveVat,
            pcd: undefined,
            et: undefined,
            cashPrice: item.req.cashPrice,
            vatCashPrice: item.req.vatCashPrice,
            purchasePrice: item.req.purchasePrice ?? 0,
          },
          stockType,
          branchId: item.branchId,
          supplierId: item.supplierId,
        });

        setScannedBarcode('');
      }
    }
  };

  return { doesProductExist, isProductInCart, addExisting, addNew };
};
