import { useQuery } from 'react-query';
import { useContext, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled/macro';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import { StyledDivider } from '../../../stock-list/components/InboundOrders/InboundOrders.style';
import { DesiredOptionSelector } from '../../components/SearchPOS/ReusableSelectors/DesiredOptionSelector';
import { RimSizeCard } from '../../components/SearchPOS/RimSizeCard';
import {
  PosSearchActiveTab,
  PosSearchContext,
} from '../../components/SearchPOS/SearchContext';
import { SearchInput } from '../../components/SearchPOS/SearchInput';
import {
  favoriteWidthsByCategory,
  searchOptions,
  topBrands,
} from '../../components/SearchPOS/SearchPosConstants';
import { SeasonSelector } from '../../components/SearchPOS/ReusableSelectors/SeasonSelector';
import { SelectedSize } from '../../components/SearchPOS/SelectedSize';
import { StepperCard } from '../../components/SearchPOS/StepperCard';
import { TabSelector } from '../../components/SearchPOS/TabSelector';
import { BrandSelector } from '../../components/SearchPOS/ReusableSelectors/BrandSelector';
import api from '@tyrio/api-factory';
import { DBProductCategoryApi, PosApi } from '@tyrio/dto';
import { SubcategorySelector } from '../../components/SearchPOS/ReusableSelectors/SubcategorySelector';
import { RimSizeSelector } from '../../components/SearchPOS/ReusableSelectors/RimSizeSelector';
import { ColorGroupSelector } from '../../components/SearchPOS/ReusableSelectors/ColorGroupSelector';
import { COLOR } from '@tyrio/shared-vars';
import { POSContext } from '../../../../context/POSContext';
import _ from 'lodash';
import { useGetPosFilters } from '../../components/SearchPOS/query/get-filter';

interface SelectStepperProps {
  listOfDimensions?: Record<
    string,
    Record<string, Record<string, (string | null)[]>>
  >;
}

export const SelectStepper = ({ listOfDimensions }: SelectStepperProps) => {
  // const { filterValues, setFilterValue } = useFilter();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const colors = Object.keys(COLOR);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    setSelectedTableRow,
    setIsSupplierStockExpanded,
    setIsBranchesStockExpanded,
    filterValues,
    setFilterValue,
  } = useContext(POSContext);
  const {
    category,
    setCategory,
    width,
    setWidth,
    height,
    setHeight,
    rimSize,
    setRimSize,
    resetAllValues,
    categoryId,
    setSelectedSizes,
    selectedSizes,
    addNewValue,
    setAddNewValue,
    activeStep,
    currentStepIndex,
    setCurrentStepIndex,
    motoDesiredOption,
    setMotoDesiredOption,
    activeTab,
    setActiveTab,
    setIsSetTyreSettingsOpen,
    setsSettings,
    selectedMotoDesiredOptions,
    setSelectedMotoDesiredOptions,
  } = useContext(PosSearchContext);
  const { setFiltersPos } = useGetPosFilters(filterValues);
  const { data: mainCategories } = useQuery(['all_categories'], () =>
    api.fetch<DBProductCategoryApi['list']>(`all_categories`)
  );

  const searchFilters = useMemo(() => {
    return {
      size: filterValues['size'],
    };
  }, [filterValues]);

  const { refetch } = useQuery(
    ['get_search_filters'],
    () => api.fetch<PosApi['getFilters']>('get_search_filters', filterValues),
    {
      onSuccess: (res) => {
        setFiltersPos(res);
      },
    }
  );

  const subcategories = mainCategories
    ?.filter((category) => category.parent_category_id === categoryId)
    .map((el) => {
      return { value: el.id, label: el.name.toLocaleUpperCase() };
    });

  useEffect(() => {
    refetch();
  }, [searchFilters, refetch]);

  const categories =
    listOfDimensions &&
    Object.entries(listOfDimensions).map((item) => item[0].split('|'));

  const widths =
    listOfDimensions && category && listOfDimensions?.[category]
      ? Object.entries(listOfDimensions?.[category])
          ?.map((el) => el[0])
          .sort((a, b) => Number(a) - Number(b))
      : null;

  const heights =
    listOfDimensions &&
    category &&
    width &&
    listOfDimensions?.[category]?.[width]
      ? Object.entries(listOfDimensions[category][width]).map((el) => el[0])
      : [null];

  const rimSizes =
    listOfDimensions &&
    width &&
    height &&
    category &&
    listOfDimensions?.[category]?.[width]?.[height]
      ? Object.entries(listOfDimensions[category][width][height]).map(
          (el) => el[1]
        )
      : [null];

  const isCategoryAutoTyre =
    categoryId === 4 || categoryId === 7 || categoryId === 1;
  const isCategoryTubesOrBicycleTyre = categoryId === 9 || categoryId === 5;
  const isCategoryMotoTyre = categoryId === 2 || categoryId === 3;

  const potentialWidthsForBicAndTubes =
    category &&
    listOfDimensions?.[category] &&
    Object.entries(listOfDimensions[category]).map((el) => el[0]);

  const rimSizesForBicycleAndTubeTires =
    isCategoryTubesOrBicycleTyre && potentialWidthsForBicAndTubes
      ? _.uniq(
          potentialWidthsForBicAndTubes
            .map((el) => listOfDimensions[category][el]['null']?.flat())
            .reduce((acc, curr) => acc.concat(curr), [])
            .sort(function (a, b) {
              return Number(a) - Number(b);
            })
        )
      : null;

  const fullSizeValue = isCategoryTubesOrBicycleTyre
    ? ''
    : width + '/' + height + `${isCategoryMotoTyre ? ' - ' : ' R '}`;

  const lastSteps = ['SEASON', 'BRAND', 'SUBCATEGORY', 'COLOR_RIMS'];

  const isLastStep = lastSteps.includes(activeStep);

  const isWheelCategory = categoryId === 8;

  const handleSelectRimSize = (rimSize: string, isWheel?: boolean) => {
    const newSelectedSizes = selectedSizes ?? [];
    const selectedFullSize = isWheel
      ? width + 'x' + rimSize
      : fullSizeValue + rimSize;

    if (addNewValue) {
      if (!selectedSizes?.includes(selectedFullSize))
        newSelectedSizes.push(selectedFullSize);
    } else {
      newSelectedSizes.pop();
      newSelectedSizes.push(selectedFullSize);
    }
    setRimSize(rimSize);

    setFilterValue({
      ...filterValues,
      size: newSelectedSizes,
      categoryId: categoryId?.toString(),
      moto: motoDesiredOption ? motoDesiredOption : undefined,
      setRules:
        categoryId === 2 || categoryId === 1
          ? JSON.stringify(setsSettings)
          : {},
    });

    setSelectedSizes(newSelectedSizes);

    if (motoDesiredOption === 'FRONT/REAR' && selectedSizes?.length !== 2) {
      setCurrentStepIndex(2);
      setAddNewValue(true);
    } else {
      setCurrentStepIndex(currentStepIndex + 1);
      setAddNewValue(false);
    }
  };

  const handleAddNewValue = () => {
    setAddNewValue(true);
    setWidth(null);
    setHeight(null);
    setRimSize(null);
    setCurrentStepIndex(1);
    if (
      filterValues['categoryId'] === '2' &&
      selectedSizes &&
      selectedSizes.length === 1
    ) {
      if (motoDesiredOption === 'FRONT/REAR') {
        setSelectedMotoDesiredOptions(['FRONT/REAR']);
      } else {
        const newMotoDesiredOption =
          motoDesiredOption === 'FRONT' ? 'REAR' : 'FRONT';
        setMotoDesiredOption(newMotoDesiredOption);
        setSelectedMotoDesiredOptions([
          ...selectedMotoDesiredOptions,
          newMotoDesiredOption,
        ]);
        setCurrentStepIndex(2);
      }
    }
  };

  const handleTyreSetSetting = () => {
    setIsSetTyreSettingsOpen(true);
  };

  let widthFavoriteSizeId = categoryId?.toString();

  if (motoDesiredOption) {
    if (motoDesiredOption === 'FRONT') widthFavoriteSizeId += '-front';
    if (motoDesiredOption === 'REAR') widthFavoriteSizeId += '-rear';
  }

  const unsetOverflow =
    activeTab === PosSearchActiveTab.wheels_search ? 'unset' : 'auto';

  const primaryCategories = [
    [1, 'AUTO GUME'],
    [4, 'TERETNE GUME'],
    [2, 'MOTO GUME'],
    [3, 'ATV GUME'],
    [7, 'AGRO GUME'],
    [5, 'BICIKL GUME'],
    [8, 'FELGE'],
    [102, 'DODACI ZA FELGE'],
    [9, 'ZRACNICE'],
    [10, 'VENTILI'],
  ];

  const availableCategories = useMemo(() => {
    return Object.keys(listOfDimensions ?? {}).map((m) => {
      const s = m.split('|');
      return s[0];
    });
  }, [listOfDimensions]);

  const otherCategories = useMemo(() => {
    const primaryCategoryIds = [
      '1',
      '4',
      '8',
      '2',
      '3',
      '7',
      '5',
      '9',
      '10',
      '102',
    ];
    const otherIds = _.difference(availableCategories, primaryCategoryIds);

    const otherCategories: string[][] = [];
    categories?.forEach((c) => {
      const id = c[0];
      if (otherIds.includes(id)) otherCategories.push(c);
    });
    return otherCategories;
  }, [availableCategories, categories]);

  const getSizesLabel = (index: number) => {
    if (isCategoryMotoTyre) {
      if (motoDesiredOption === 'FRONT/REAR')
        return `${_.capitalize(motoDesiredOption)} tyre`;
      return `${_.capitalize(selectedMotoDesiredOptions[index])} tyre`;
    } else if (isWheelCategory) return `PCD ${index + 1}`;
    else return `Size ${index + 1}`;
  };

  return (
    <Container>
      {!category && (
        <>
          <SearchInput
            filterQuery={filterValues}
            setFilterQuery={setFilterValue}
            options={searchOptions}
          />
          <StyledDivider />
        </>
      )}
      <Content
        height={windowHeight - 160 + 'px'}
        id="select_stepper"
        overflow={unsetOverflow}
      >
        {primaryCategories && currentStepIndex === 0 && (
          <CardWrapper
            style={{ justifyContent: 'space-between' }}
            id="card_wrapper"
          >
            {primaryCategories.map((item) => {
              const categoryId = item[0];
              const categoryName = item[1];

              return (
                <StepperCard
                  key={categoryId}
                  value={categoryId.toString()}
                  label={categoryName.toString()}
                  setSelectedValue={setCategory}
                  selectedValue={category}
                  setCurrentStepIndex={() => {
                    setCurrentStepIndex(currentStepIndex + 1);
                  }}
                  maxWidth="48%"
                  disabled={
                    !availableCategories.includes(categoryId.toString())
                  }
                  handleClick={() => {
                    if (categoryId === 8)
                      setActiveTab(PosSearchActiveTab.wheels_search);
                  }}
                />
              );
            })}
          </CardWrapper>
        )}

        {otherCategories &&
          currentStepIndex === 0 &&
          otherCategories.length > 0 && (
            <CardWrapper>
              <StyledDivider />

              <OptionsWrapper>
                <HeaderText>Other categories</HeaderText>
              </OptionsWrapper>

              {otherCategories.map((item) => {
                const categoryId = item[0];
                const categoryName = item[1];

                return (
                  <StepperCard
                    key={categoryId}
                    value={categoryId.toString()}
                    label={categoryName.toString()}
                    setSelectedValue={setCategory}
                    selectedValue={category}
                    setCurrentStepIndex={() => {
                      setCurrentStepIndex(currentStepIndex + 1);
                    }}
                    customHeight="60px"
                  />
                );
              })}
            </CardWrapper>
          )}

        {category && categoryId && (
          <TabSelector category={category}>
            <>
              {activeStep === 'DESIRED_OPTION' && !selectedSizes && (
                <DesiredOptionSelector />
              )}

              {widths &&
                (activeStep === 'WIDTH' || activeStep === 'HOLE_NUMBER') && (
                  <>
                    {activeStep === 'HOLE_NUMBER' ? (
                      <OptionsWrapper>
                        <HeaderText>Select hole number</HeaderText>
                      </OptionsWrapper>
                    ) : (
                      <CardWrapper>
                        <OptionsWrapper>
                          <HeaderText>Select width</HeaderText>
                        </OptionsWrapper>
                        {widthFavoriteSizeId &&
                          favoriteWidthsByCategory[widthFavoriteSizeId] &&
                          favoriteWidthsByCategory[widthFavoriteSizeId].map(
                            (item) => (
                              <StepperCard
                                key={item}
                                value={item}
                                setSelectedValue={setWidth}
                                selectedValue={width}
                                setCurrentStepIndex={() =>
                                  setCurrentStepIndex(currentStepIndex + 1)
                                }
                              />
                            )
                          )}

                        <StyledDivider />
                        <OptionsWrapper>
                          <HeaderText>All available sizes</HeaderText>
                        </OptionsWrapper>
                      </CardWrapper>
                    )}

                    <CardWrapper>
                      {_.uniq(widths.map((item) => item.trim())).map(
                        (item) =>
                          item &&
                          item !== '' && (
                            <StepperCard
                              key={item}
                              value={item}
                              setSelectedValue={setWidth}
                              selectedValue={width}
                              setCurrentStepIndex={() =>
                                setCurrentStepIndex(currentStepIndex + 1)
                              }
                              maxWidth={'17%'}
                              customHeight="60px"
                            />
                          )
                      )}
                    </CardWrapper>
                  </>
                )}

              {heights &&
                (activeStep === 'HEIGHT' || activeStep === 'HOLE_CIRCLE') && (
                  <CardWrapper>
                    <>
                      {activeStep === 'HOLE_CIRCLE' && (
                        <OptionsWrapper>
                          <HeaderText>Select hole circle</HeaderText>
                        </OptionsWrapper>
                      )}

                      {heights.map(
                        (item) =>
                          item &&
                          item !== '' && (
                            <StepperCard
                              key={item}
                              value={item}
                              setSelectedValue={setHeight}
                              selectedValue={height}
                              setCurrentStepIndex={() =>
                                setCurrentStepIndex(currentStepIndex + 1)
                              }
                              wheelSubmit={() =>
                                isWheelCategory &&
                                handleSelectRimSize(item, true)
                              }
                            />
                          )
                      )}
                    </>
                  </CardWrapper>
                )}

              {(rimSizes || rimSizesForBicycleAndTubeTires) &&
                activeStep === 'RIM_SIZE' && (
                  <div>
                    <CardWrapper>
                      {rimSizesForBicycleAndTubeTires &&
                        rimSizesForBicycleAndTubeTires.map(
                          (item) =>
                            item && (
                              <StepperCard
                                key={item}
                                value={item}
                                setSelectedValue={() =>
                                  handleSelectRimSize(item)
                                }
                                selectedValue={rimSize}
                                setCurrentStepIndex={() =>
                                  setCurrentStepIndex(currentStepIndex + 1)
                                }
                              />
                            )
                        )}
                    </CardWrapper>

                    {rimSizes && (
                      <ColumnCardWrapper>
                        {rimSizes.map(
                          (item) =>
                            item &&
                            item !== '' && (
                              <RimSizeCard
                                key={item}
                                value={item}
                                size={fullSizeValue}
                                selectedValue={rimSize ?? ''}
                                handleClick={() => handleSelectRimSize(item)}
                              />
                            )
                        )}
                      </ColumnCardWrapper>
                    )}
                  </div>
                )}

              {isLastStep && selectedSizes && selectedSizes.length > 0 && (
                <SelectedSizesWrapper>
                  <HeaderText>
                    Selected {isWheelCategory ? 'PCD' : 'sizes'}
                  </HeaderText>

                  {selectedSizes.map((el, index) => (
                    <SelectedSize
                      key={el}
                      label={getSizesLabel(index) ?? ''}
                      width={el}
                      onReset={() => {
                        if (selectedSizes.length === 1) {
                          resetAllValues();
                          setCurrentStepIndex(0);
                          setSelectedTableRow(null);
                          setIsBranchesStockExpanded(false);
                          setIsSupplierStockExpanded(false);
                        }

                        if (selectedSizes.length > 1) {
                          const filterSelectedSize = selectedSizes.filter(
                            (size) => size !== el
                          );
                          setSelectedSizes(filterSelectedSize);
                          setFilterValue({
                            size: filterSelectedSize,
                          });
                          if (selectedMotoDesiredOptions[index] === 'FRONT') {
                            setMotoDesiredOption('REAR');
                            setSelectedMotoDesiredOptions(['REAR']);
                          } else if (
                            selectedMotoDesiredOptions[index] === 'REAR'
                          ) {
                            setMotoDesiredOption('FRONT');
                            setSelectedMotoDesiredOptions(['FRONT']);
                          } else {
                            setSelectedMotoDesiredOptions(['FRONT/REAR']);
                            setMotoDesiredOption('FRONT/REAR');
                          }
                        }
                      }}
                    />
                  ))}

                  {selectedSizes.length < 2 &&
                    motoDesiredOption !== 'FRONT/REAR' && (
                      <Button
                        color="info"
                        endIcon={<AddCircleOutlineIcon />}
                        sx={{ marginTop: '10px' }}
                        onClick={() => handleAddNewValue()}
                      >
                        ADD ANOTHER SIZE
                      </Button>
                    )}

                  {(filterValues['categoryId'] === '2' ||
                    filterValues['categoryId'] === '1') &&
                    selectedSizes.length === 2 && (
                      <Button
                        color="info"
                        variant="outlined"
                        startIcon={<SettingsIcon />}
                        sx={{ marginTop: '10px' }}
                        onClick={() => handleTyreSetSetting()}
                      >
                        TYRE SET SETTING
                      </Button>
                    )}

                  <StyledDivider style={{ marginTop: '80px' }} />

                  {isCategoryAutoTyre && <SeasonSelector />}

                  {isCategoryMotoTyre && (
                    <BrandSelector
                      label="Filter top brands"
                      favoriteValues={topBrands}
                      isMotoLabelStyle={true}
                    />
                  )}

                  {isCategoryTubesOrBicycleTyre && subcategories && (
                    <SubcategorySelector
                      label="Filter categories"
                      selectOptions={subcategories}
                      filterValues={filterValues}
                      setFilterValue={setFilterValue}
                    />
                  )}

                  {isWheelCategory && (
                    <div style={{ width: '100%' }}>
                      <RimSizeSelector
                        label={'Filter rim size'}
                        options={rimSizes.map((el) => (el ? el : ''))}
                      />

                      <StyledDivider style={{ marginTop: '20px' }} />

                      <ColorGroupSelector
                        label={'Filter color group'}
                        options={colors}
                      />
                    </div>
                  )}
                </SelectedSizesWrapper>
              )}
            </>
          </TabSelector>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div``;

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Content = styled.div<{ height: string; overflow?: string }>`
  height: 100%;
  max-height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: ${({ overflow }) => overflow ?? 'auto'};
`;

export const ColumnCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderText = styled.div`
  font-size: 14px;
  color: #919eab;
  margin-top: 10px;
`;

const CardWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  min-width: 560px;
  height: 100%;
`;

const SelectedSizesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
