import styled from '@emotion/styled';
import { DBService } from '@prisma/client';
import { ServicePriceShape } from '@tyrio/dto';
import { useContext, useMemo } from 'react';
import { AddedItemsButton } from '../../../../components/Button/AddedItemsButton';
import { PosCartContext, StockType } from '../../../../context/PosCartContext';
import { usePosCtx } from '../../../../context/POSContext';
import { useWS } from '../../../../context/WSContext';
import { formatCurrencyWithSymbol } from '../../../../helpers/currency-format';
import QuantityComponent from '../../components/StockItemDetails/QuantityComponent/QuantityComponent';
import { usePosCartData } from '../Cart/helpers/cart-data';

interface ServiceCardProps {
  service: DBService;
}

export const ServiceCard = ({ service }: ServiceCardProps) => {
  const ws = useWS();
  const { servicesData, modalData } = usePosCartData();
  const { timer } = useContext(PosCartContext);
  const { isVatIncluded } = usePosCtx();

  const priceData = service.servicePrice as unknown as ServicePriceShape;

  const exclusiveVatPrice = priceData.fixedPrice
    ? priceData.fixedPrice?.exclusiveVat
    : priceData.chargedByLaborTime?.finalPrice ?? 0;

  const inclusiveVatPrice = priceData.fixedPrice
    ? priceData.fixedPrice?.inclusiveVat
    : priceData.chargedByLaborTime?.finalPrice ?? 0;

  const finalPrice = priceData.fixedPrice
    ? isVatIncluded
      ? inclusiveVatPrice
      : exclusiveVatPrice
    : priceData.chargedByLaborTime?.finalPrice ?? 0;

  const addServiceToCart = (quantity: number) => {
    ws.socket?.emit('add-to-pos-cart', {
      key: service.id,
      req: {
        quantity,
        ean: '',
        dot: '',
        sku: '',
        uid: '',
        productName: service.name,
        productBrand: '',
        productDescription: service.code,
        price: exclusiveVatPrice ?? 0,
        reserved: quantity,
        total: 0,
        realQuantity: 1000,
        inclusiveVat: inclusiveVatPrice ?? 0,
        serviceCategory: service.serviceCategoryId,
        id: service.id,
        erpId: service.erpId,
        code: service.code,
      },
      stockType: 'SERVICES',
    });
    timer?.restart();
  };

  const itemInCart = useMemo(() => {
    return servicesData.find((item) => item.key === service.id);
  }, [service.id, servicesData]);

  const handleDelete = () => {
    ws.socket?.emit('remove-item-from-pos-cart', { key: service.id });
  };

  const predefinedQuantity = useMemo(() => {
    const items = modalData.filter(
      (item) =>
        (
          service.subcategory as unknown as { value: number; label: string }[]
        ).find((el) => Number(el.value) === Number(item?.req?.categoryId)) !==
          undefined && item.stockType !== ('SERVICES' as StockType)
    );
    const quantity =
      items.reduce((acc, curr) => acc + curr.req.quantity, 0) ?? 0;
    return quantity;
  }, [modalData, service]);

  return (
    <Wrapper>
      <DetailsWrapper>
        {service.name}
        <div>{formatCurrencyWithSymbol(finalPrice ?? 0)}</div>
      </DetailsWrapper>
      {itemInCart ? (
        <AddedItemsButton
          quantity={itemInCart.req.quantity}
          onClick={handleDelete}
        />
      ) : (
        <QuantityComponent
          quantity={predefinedQuantity}
          maxAvailableQuantity={1000}
          showButton={true}
          isDisabled={itemInCart}
          handleClick={addServiceToCart}
          cart={true}
          minQuantity={0}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  height: 74px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfe3e8;
  gap: 15px;
`;

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Barlow;
  font-weight: 400;
  font-size: 16px;
  gap: 20px;
`;
