import styled from '@emotion/styled';
import { FormControlLabel, Grid, Switch } from '@mui/material';

interface AutomaticOrdersProps {
  automaticOrders: boolean;
  setAutomaticOrders: (val: boolean) => void;
}

export const AutomaticOrders = (props: AutomaticOrdersProps) => {
  const { automaticOrders, setAutomaticOrders } = props;

  return (
    <Wrapper>
      <Grid display="flex" alignItems="center" justifyContent="center">
        <FormControlLabel
          label="Process order automatically"
          control={
            <Switch
              checked={automaticOrders}
              onChange={(e) => {
                setAutomaticOrders(e.target.checked);
              }}
            />
          }
        />
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 16px;
`;
