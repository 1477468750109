import { useMutation } from 'react-query';
import { DBStockCountApi, DBStockCountCreate } from '@tyrio/dto';
import api from '@tyrio/api-factory';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { queryClient } from '../../../../../../src/app/query-client';

export const useAddStockCount = ({
  setData,
}: {
  setData: (v: DBStockCountCreate | null) => void;
}) => {
  const mutation = useMutation(
    (req: DBStockCountCreate) =>
      api.fetch<DBStockCountApi['create']>('create_stock_count', {
        ...req,
      }),
    {
      mutationKey: 'create_stock_count',
      onSuccess: () => {
        queryClient.refetchQueries('get_stock_counts');
        queryClient.refetchQueries('count_stock_count_status');

        setData(null);

        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
      },
    }
  );

  return { ...mutation };
};
