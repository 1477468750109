import styled from '@emotion/styled/macro';
import tyre from './car-wheel.svg';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Close, DeleteOutline } from '@mui/icons-material';
import { Card, StyledCheckbox } from './BrandSelector';
import { usePosSearchContext } from '../SearchContext';
import { useContext, useEffect, useMemo } from 'react';
import { POSContext } from '../../../../../context/POSContext';
import _ from 'lodash';
import {
  PosItem,
  PosItemResponse,
  PosItemSet,
  SetGenericOptions,
} from '@tyrio/dto';
import {
  CheckProps,
  defaultSettings,
  SetSelectProps,
  setSettingsMotoCheck,
  setSettingsAutoCheck,
  setSettingsSelect,
  motoDefaultSetSettings,
  autoSetSettings,
} from '../constants/setConstants';

// GET all options values for select
const setOptionsValues = (
  data: PosItemResponse,
  path: string[],
  position: string,
  categoryId?: string
) => {
  const getValue = (obj: any, path: string[]) => _.get(obj, path);
  const unmatchedValues = data.unmatchedItems
    .filter((item) =>
      categoryId !== '2' ? item : item.product.model.wheelPosition === position
    )
    .map((item: PosItem) => getValue(item, path));
  const matchedValues = data.matchedSets.flatMap((set: PosItemSet) =>
    set.items
      .filter((item) =>
        categoryId !== '2'
          ? item
          : item.product.model.wheelPosition === position
      )
      .map((item: PosItem) => getValue(item, path))
  );

  const allValues = [...new Set([...unmatchedValues, ...matchedValues])].sort();
  if (position === 'front') return [SetGenericOptions.ALL, ...allValues];
  else if (position === 'rear') {
    switch (path[path.length - 1]) {
      case 'brand':
        return [SetGenericOptions.SAME_AS_FRONT, ...allValues];
      case 'modelName':
        return [SetGenericOptions.SAME_AS_FRONT, ...allValues];
      case 'speedIndex':
        return [SetGenericOptions.SAME_OR_HIGHER, ...allValues];
      case 'compound':
        return [SetGenericOptions.ALL_COMPOUNDS, ...allValues];
      case 'outletDot':
        return [SetGenericOptions.SAME_OR_NEWER, ...allValues];
      default:
        return [SetGenericOptions.SAME_AS_FRONT, ...allValues];
    }
  } else return [...allValues];
};

export const TyreSetSettings = () => {
  const { setIsSetTyreSettingsOpen, setsSettings, setSetsSettings } =
    usePosSearchContext();

  const {
    myStockItems,
    branchesStockItems,
    supplierStockItems,
    filterValues,
    setFilterValue,
  } = useContext(POSContext);

  // Setting sets rules on default values first
  useEffect(() => {
    setSetsSettings(defaultSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoryId = filterValues['categoryId'];
  const options = useMemo(() => {
    const data: PosItemResponse = {
      unmatchedItems: [
        ...myStockItems.unmatchedItems,
        ...branchesStockItems.unmatchedItems,
        ...supplierStockItems.unmatchedItems,
      ],
      matchedSets: [
        ...myStockItems.matchedSets,
        ...branchesStockItems.matchedSets,
        ...supplierStockItems.matchedSets,
      ],
    };
    const frontModelNames = setOptionsValues(
      data,
      ['product', 'model', 'modelName'],
      'front'
    );
    const rearModelNames = setOptionsValues(
      data,
      ['product', 'model', 'modelName'],
      'rear'
    );
    const frontSpeedIndex = setOptionsValues(
      data,
      ['product', 'speedIndex'],
      'front'
    );

    const rearSpeedIndex = setOptionsValues(
      data,
      ['product', 'speedIndex'],
      'rear'
    );

    const frontCompounds = setOptionsValues(
      data,
      ['product', 'compound'],
      'front'
    );
    const rearCompounds = setOptionsValues(
      data,
      ['product', 'compound'],
      'rear'
    );
    const frontDots = setOptionsValues(
      data,
      ['features', 'outletDot'],
      'front'
    );
    const rearDots = setOptionsValues(data, ['features', 'outletDot'], 'rear');

    return {
      data,
      frontCompounds,
      rearCompounds,
      frontDots,
      frontModelNames,
      rearDots,
      rearModelNames,
      frontSpeedIndex,
      rearSpeedIndex,
    };
  }, [myStockItems, branchesStockItems, supplierStockItems]);

  const reset = () => {
    setSetsSettings(defaultSettings);
  };

  const selectingSet =
    categoryId === '1'
      ? setSettingsSelect.filter((item) => item.label !== 'compound')
      : setSettingsSelect;
  const renderSelectionFields = (data: PosItemResponse, position: string) => {
    const handleChange = (name: string, value: string) => {
      setSetsSettings({ ...setsSettings, [name]: value });
    };
    return selectingSet.map((item: SetSelectProps) => {
      switch (item.label) {
        //TODO : This is going to be added later
        /*       case 'Brand':
          item.options = setOptionsValues(
            data,
            ['product', 'model', 'brand'],
            position
          );
          break; */
        case 'model_name':
          item.optionsFront = options.frontModelNames;
          item.optionsRear = options.rearModelNames;
          break;
        case 'speed_index':
          item.optionsFront = options.frontSpeedIndex;
          item.optionsRear = options.rearSpeedIndex;
          break;
        case 'compound':
          item.optionsFront = options.frontCompounds;
          item.optionsRear = options.rearCompounds;
          break;
        case 'dot':
          item.optionsFront = options.frontDots;
          item.optionsRear = options.rearDots;
          break;
        default:
          item.optionsFront = [];
          item.optionsRear = [];
          break;
      }
      const optionsValues =
        position === 'front' ? item.optionsFront : item.optionsRear;
      return (
        <FormControl>
          <InputLabel id={`${item.label}_${position}`}>
            {_.capitalize(item.label.replace('_', ' '))}
          </InputLabel>
          <Select
            labelId={`${item.label}_${position}`}
            label={_.capitalize(item.label.replace('_', ' '))}
            id={`${item.label}_${position}`}
            defaultValue={
              position === 'front' ? item.optionsFront[0] : item.optionsRear[0]
            }
            fullWidth={true}
            value={setsSettings[`${item.label}_${position}`] as string}
            endAdornment={
              <Close
                style={{
                  width: '15px',
                  height: '15px',
                  position: 'absolute',
                  right: '35px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  handleChange(`${item.label}_${position}`, optionsValues[0])
                }
              />
            }
            onChange={(event) =>
              handleChange(`${item.label}_${position}`, event.target.value)
            }
          >
            {optionsValues.map((option) => (
              <MenuItem key={`${item.label}_${option}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    });
  };

  const handleCheck = (name: string) => {
    setSetsSettings({
      ...setsSettings,
      [name]: !setsSettings[name],
    });
  };

  const handleSubmit = () => {
    filterValues['setRules'] = JSON.stringify(setsSettings);
    setFilterValue(filterValues);
    setIsSetTyreSettingsOpen(false);
  };

  useEffect(() => {
    if (filterValues['setRules']) {
      setSetsSettings(JSON.parse(filterValues['setRules'] as string));
    } else {
      setSetsSettings(
        filterValues['categoryId'] === '2'
          ? motoDefaultSetSettings
          : autoSetSettings
      );
    }
  }, [filterValues, setSetsSettings]);
  const setSettingsCheck =
    categoryId === '1' ? setSettingsAutoCheck : setSettingsMotoCheck;
  return (
    <Wrapper>
      <TitleWrapper>
        <img src={tyre} alt="Car wheel" />
        <Typography fontWeight={500} fontSize={18} letterSpacing={0.3}>
          TYRE SET ADVANCED SETTINGS
        </Typography>
      </TitleWrapper>
      <TypesWrapper>
        <Typography textAlign={'center'} fontSize={16} left={0.3} flex={1}>
          Front tyre
        </Typography>
        <Typography
          textAlign={'center'}
          fontSize={16}
          letterSpacing={0.3}
          flex={1}
        >
          Rear tyre
        </Typography>
      </TypesWrapper>
      <FormControl style={{ flex: 'column' }}>
        <PositionsWrapper>
          <SelectionWrapper>
            {renderSelectionFields(options.data, 'front')}
          </SelectionWrapper>
          <SelectionWrapper>
            {renderSelectionFields(options.data, 'rear')}
          </SelectionWrapper>
        </PositionsWrapper>

        <CheckWrapper>
          {setSettingsCheck.map((item: CheckProps) => {
            return (
              <Card
                onClick={() => {
                  handleCheck(item.name);
                }}
                selected={!!setsSettings[item.name]}
                key={item.name}
                width={264}
                style={{ fontSize: '20px', lineHeight: '28px' }}
              >
                <StyledCheckbox
                  type="checkbox"
                  id={item.name}
                  key={item.name}
                  checked={!!setsSettings[item.name]}
                  onChange={() => handleCheck(item.name)}
                  style={{ marginRight: '10px' }}
                />
                {item.label}
              </Card>
            );
          })}
        </CheckWrapper>
        <Button
          endIcon={<DeleteOutline />}
          sx={{
            color: '#B9C4CE',
            fontSize: '14px',
            marginRight: 0,
            marginLeft: 'auto',
            textTransform: 'lowercase',
          }}
          onClick={() => {
            reset();
          }}
        >
          <span style={{ textTransform: 'uppercase' }}>D</span>iscard changes
        </Button>

        <ButtonsWrapper>
          <Button
            variant="outlined"
            style={{ flex: 1, padding: '16px 22px' }}
            color={'info'}
            onClick={() => setIsSetTyreSettingsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ flex: 1, padding: '16px 22px' }}
            color={'info'}
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ButtonsWrapper>
      </FormControl>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px;
`;
const TitleWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const TypesWrapper = styled.div`
  display: flex;
  flex-basis: 50%;
  padding: 16px 0;
  border-bottom: 2px solid #dfe3e8;
  margin-bottom: 32px;
`;
const PositionsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  max-width: 264px;
`;

const CheckWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 8px;
  border-top: 2px solid #dfe3e8;
  padding: 24px 0;
  display: flex;
  gap: 24px;
`;
