import * as z from "zod"
import { DBCustomerOrderTypeEnum, DBPaymentStatusEnum } from ".prisma/client"
import { CompleteDBClientCustomerOrder, RelatedDBClientCustomerOrderModel, CompleteDBClient, RelatedDBClientModel, CompleteDBShopTypes, RelatedDBShopTypesModel, CompleteDBPaymentTypes, RelatedDBPaymentTypesModel, CompleteDBDeliveryTypes, RelatedDBDeliveryTypesModel, CompleteDBDeliveryAddress, RelatedDBDeliveryAddressModel, CompleteDBBillingAddress, RelatedDBBillingAddressModel, CompleteDBUser, RelatedDBUserModel, CompleteDBOrderDocument, RelatedDBOrderDocumentModel, CompleteDBAppointments, RelatedDBAppointmentsModel, CompleteDBTrackingInfo, RelatedDBTrackingInfoModel, CompleteDBWorkOrders, RelatedDBWorkOrdersModel, CompleteDBClientSupplierOrderItem, RelatedDBClientSupplierOrderItemModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBCustomerOrderItemModel = z.object({
  id: z.string(),
  orderId: z.string().nullish(),
  clientId: z.string(),
  orderStatus: jsonSchema,
  reference: z.string().nullish(),
  details: jsonSchema,
  shopId: z.string().nullish(),
  paymentId: z.string().nullish(),
  paymentDetails: jsonSchema,
  deliveryId: z.string().nullish(),
  customer: jsonSchema,
  deliveryAddressId: z.string().nullish(),
  billingAddressId: z.string().nullish(),
  lineItems: jsonSchema,
  supplierMeta: z.string().array(),
  services: jsonSchema,
  summary: jsonSchema,
  orderType: z.nativeEnum(DBCustomerOrderTypeEnum),
  paymentStatus: z.nativeEnum(DBPaymentStatusEnum),
  trackingInfo: jsonSchema,
  cancelReason: z.string().nullish(),
  lockedBy: z.string().nullish(),
  assignedToId: z.string().nullish(),
  estimatedDeliveryDateFrom: z.date().nullish(),
  estimatedDeliveryDateTo: z.date().nullish(),
  orderedAt: z.date(),
  updatedAt: z.date().nullish(),
  customerRemark: z.string().nullish(),
  internalRemark: z.string().nullish(),
  favoritedBy: z.string().array(),
  lastEditedBy: jsonSchema,
  orderNumber: z.string().nullish(),
  vehicleInfo: jsonSchema,
  isItemsPicked: z.boolean().nullish(),
  erpOrder: z.string().nullish(),
  branchErpId: z.string().nullish(),
  createdBy: jsonSchema,
})

export interface CompleteDBCustomerOrderItem extends z.infer<typeof DBCustomerOrderItemModel> {
  order?: CompleteDBClientCustomerOrder | null
  client: CompleteDBClient
  shop?: CompleteDBShopTypes | null
  payment?: CompleteDBPaymentTypes | null
  delivery?: CompleteDBDeliveryTypes | null
  deliveryAddress?: CompleteDBDeliveryAddress | null
  billingAddress?: CompleteDBBillingAddress | null
  assignedTo?: CompleteDBUser | null
  DBOrderDocument: CompleteDBOrderDocument[]
  appointments: CompleteDBAppointments[]
  trackingInfoData: CompleteDBTrackingInfo[]
  workOrders: CompleteDBWorkOrders[]
  clientSupplierOrders: CompleteDBClientSupplierOrderItem[]
}

/**
 * RelatedDBCustomerOrderItemModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBCustomerOrderItemModel: z.ZodSchema<CompleteDBCustomerOrderItem> = z.lazy(() => DBCustomerOrderItemModel.extend({
  order: RelatedDBClientCustomerOrderModel.nullish(),
  client: RelatedDBClientModel,
  shop: RelatedDBShopTypesModel.nullish(),
  payment: RelatedDBPaymentTypesModel.nullish(),
  delivery: RelatedDBDeliveryTypesModel.nullish(),
  deliveryAddress: RelatedDBDeliveryAddressModel.nullish(),
  billingAddress: RelatedDBBillingAddressModel.nullish(),
  assignedTo: RelatedDBUserModel.nullish(),
  DBOrderDocument: RelatedDBOrderDocumentModel.array(),
  appointments: RelatedDBAppointmentsModel.array(),
  trackingInfoData: RelatedDBTrackingInfoModel.array(),
  workOrders: RelatedDBWorkOrdersModel.array(),
  clientSupplierOrders: RelatedDBClientSupplierOrderItemModel.array(),
}))
