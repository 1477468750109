import * as z from "zod"
import { DBStockCountType, DBStockCountStatus } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBBranch, RelatedDBBranchModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBStockCountModel = z.object({
  id: z.string(),
  clientId: z.string(),
  branchId: z.number().int(),
  scheduledDate: z.date(),
  completedDate: z.date().nullish(),
  dotScan: z.boolean(),
  dotMandatory: z.boolean(),
  countType: z.nativeEnum(DBStockCountType),
  status: z.nativeEnum(DBStockCountStatus),
  description: z.string(),
  docNumber: z.string(),
  createdAt: z.date(),
  createdBy: z.string(),
  countedBy: z.string().nullish(),
  countedAt: z.string().nullish(),
  expected: jsonSchema,
  expectedMeta: jsonSchema,
  countedByMeta: jsonSchema,
  scanned: jsonSchema,
})

export interface CompleteDBStockCount extends z.infer<typeof DBStockCountModel> {
  client: CompleteDBClient
  branch: CompleteDBBranch
}

/**
 * RelatedDBStockCountModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBStockCountModel: z.ZodSchema<CompleteDBStockCount> = z.lazy(() => DBStockCountModel.extend({
  client: RelatedDBClientModel,
  branch: RelatedDBBranchModel,
}))
