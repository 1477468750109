/* eslint-disable @typescript-eslint/no-explicit-any */

import { Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography/Typography';
import { tyrioUI } from '@tyrio/ui-library';
import { useEffect, useState } from 'react';
import { WmsIcon } from '../WmsIcon/WmsIcon';
import { MenuItemWrapper } from './MenuItem.style';

type StockListActiveStep =
  | 'SEARCH'
  | 'DETAILS'
  | 'INBOUND'
  | 'OUTBOUND'
  | 'PASTE_LIST'
  | 'PRINT'
  | 'CART';

type POSActiveStep =
  | 'SEARCH'
  | 'DETAILS'
  | 'BRANCHES'
  | 'SUPPLIERS'
  | 'SERVICES'
  | 'TYRE HOTEL'
  | 'CART'
  | 'WHEEL_DETAILS';

type StockCountActiveStep = 'SUMMARY' | 'SEARCH' | 'DETAILS';

type WarehouseActiveStep = 'UPLOAD' | 'WAREHOUSE' | 'DETAILS';

const iconColors = {
  WARNING: '#EA6C42',
  CHECKED: '#3EB274',
  HOVER: '#1976D2',
  DEFAULT: tyrioUI.colors.black.B80,
  ERROR: '#DB1E1E',
  SELECTED: '#1976D2',
};

const hoverColors = {
  WARNING: '#EA6C42',
  CHECKED: '#3EB274',
  HOVER: '#1976D2',
  DEFAULT: '#1976D2',
  ERROR: '#DB1E1E',
  SELECTED: '#1976D2',
};

interface MenuItemProps {
  icon: any;
  onClick: (a: any) => void;
  text: string;
  status: 'WARNING' | 'CHECKED' | 'HOVER' | 'DEFAULT' | 'ERROR' | 'SELECTED';
  disabled?: boolean;
  isBadgeInvisible: boolean;
  activeStep:
    | StockListActiveStep
    | POSActiveStep
    | WarehouseActiveStep
    | StockCountActiveStep;
  badgeContent: number;
  componentType: 'STOCK' | 'POS' | 'WAREHOUSE';
}

const MenuItem = ({
  icon,
  onClick,
  text,
  status,
  disabled = false,
  isBadgeInvisible = true,
  activeStep,
  badgeContent,
  componentType,
}: MenuItemProps) => {
  const hoverColor = hoverColors[status as keyof typeof hoverColors];
  const iconColor = iconColors[status as keyof typeof iconColors];
  const [backgroundColor, setBackgroundColor] = useState('');
  const [borderColor, setBorderColor] = useState('');

  useEffect(() => {
    if (
      activeStep === text ||
      (componentType === 'STOCK' &&
        activeStep === 'PASTE_LIST' &&
        text === 'PASTE LIST') ||
      (componentType === 'POS' &&
        activeStep === 'WHEEL_DETAILS' &&
        text === 'DETAILS')
    ) {
      setBackgroundColor('white');
      setBorderColor(hoverColors['HOVER' as keyof typeof hoverColors]);
    } else if (status === 'ERROR') setBackgroundColor('#F8CECE');
    else {
      setBackgroundColor(tyrioUI.colors.black.B10);
      setBorderColor(tyrioUI.colors.black.B10);
    }
  }, [activeStep, componentType, status, text]);

  return (
    <Tooltip
      title={disabled && 'Disabled'}
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          style: { display: !disabled ? 'none' : '' },
        },
      }}
      followCursor
    >
      <MenuItemWrapper
        background={backgroundColor}
        hoverColor={hoverColor}
        status={status}
        onClick={onClick}
        disabled={disabled}
        selected={
          activeStep === text ||
          (activeStep === 'PASTE_LIST' && text === 'PASTE LIST') ||
          (activeStep === 'WHEEL_DETAILS' && text === 'DETAILS')
        }
        border={borderColor}
      >
        <Badge
          badgeContent={badgeContent}
          color={text === 'CART' ? 'error' : 'success'}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{ left: '30px !important' }}
          invisible={isBadgeInvisible}
        />
        <WmsIcon icon={icon} sx={{ color: iconColor }} />
        <Typography
          variant="caption"
          fontWeight={400}
          letterSpacing={0.3}
          sx={{ color: iconColor }}
        >
          {text}
        </Typography>
      </MenuItemWrapper>
    </Tooltip>
  );
};

export default MenuItem;
