import api from '@tyrio/api-factory';
import { DBStockCountApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetStatusCount = (req: qs.ParsedQs) => {
  const query = useQuery(['count_stock_count_status'], () =>
    api.fetch<DBStockCountApi['countStatus']>('count_stock_count_status', {
      ...req,
    })
  );

  return { ...query };
};
