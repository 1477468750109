/* eslint-disable @typescript-eslint/no-explicit-any */
import { PosProductResponse } from '@tyrio/dto';
import React from 'react';
import { ImageGallery } from '../../../components/Drawers/ImageGallery/ImageGallery';
import PreviewEprel from '../../../components/Drawers/PreviewEprel/PreviewEprel';
import { ActiveDrawer } from '../../../context/StockCountContext';

const FieldComponent: any = {
  GALLERY: ImageGallery,
  PRINT: PreviewEprel,
};

interface DrawersProps {
  activeDrawer: string;
  setActiveDrawer: (a: ActiveDrawer) => void;
  selectedProduct?: PosProductResponse | null;
}

export const Drawers = ({
  activeDrawer,
  setActiveDrawer,
  selectedProduct,
}: DrawersProps) => {
  if (
    Object.keys(FieldComponent).find((a) => a === activeDrawer) === undefined
  ) {
    console.log('Error - Step passed in is not expected!');
    return;
  }

  if (activeDrawer in Object.keys(FieldComponent)) {
    console.log('Error - Step passed in is not expected!');
  }
  return React.createElement(FieldComponent[activeDrawer], {
    activeDrawer,
    setActiveDrawer,
    selectedProduct,
  });
};
