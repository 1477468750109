import styled from '@emotion/styled';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { DBOrderStatus } from '@prisma/client';
import {
  DBClientSupplierOrderItemFilters,
  DBClientSupplierOrderItemResponse,
} from '@tyrio/dto';
import { tyrioUI } from '@tyrio/ui-library';
import _ from 'lodash';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getDropdownLabel } from '../../../../helpers/enum-parser';
import { useAuth } from '../../context/AuthContext';
import { PurchaseOrdersContext } from '../../context/PurchaseOrdersContext';
import { TooltipData } from '../customer-orders/helpers/tooltip-data';
import { getOrderStatusColor } from './helpers/generate-color';

interface PurchaseOrderTableProps {
  setSelectedOrder: (val: DBClientSupplierOrderItemResponse | null) => void;
  data: DBClientSupplierOrderItemResponse[];
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isUploadInProcess: boolean;
  selectedOrder: DBClientSupplierOrderItemResponse | null;
  handleAllChecked: () => void;
  areAllTrue: boolean;
  filters: DBClientSupplierOrderItemFilters;
}

interface LineItem {
  ordered: number;
  received: number;
  backorder: number;
  confirmed: number;
}

export enum SelectedProductStatuses {
  FAVORITE,
  SELECTED,
  NORMAL,
}

const PurchaseOrderTable = ({
  data,
  setSelectedOrder,
  isDirty,
  setIsDirty,
  isUploadInProcess,
  selectedOrder,
  handleAllChecked,
  areAllTrue,
  filters,
}: PurchaseOrderTableProps) => {
  const { user } = useAuth();
  const {
    checked,
    setChecked,
    areAllChecked,
    setCheckedSupplier,
    checkedSupplier,
  } = useContext(PurchaseOrdersContext);

  const openOrderDetails = (order: DBClientSupplierOrderItemResponse) => {
    if (isDirty) {
      const feedback = window.confirm(
        'You have unsaved changes. Are you sure you want to continue?'
      );
      if (feedback) {
        setSelectedOrder(order);
        setIsDirty(false);
      }
    } else if (isUploadInProcess) {
      window.alert(`You can't switch orders while uploading documents!`);
    } else setSelectedOrder(order);
  };

  let orderedSum = 0;
  let receivedSum = 0;

  const [isOrderNumberDesc, setIsOrderNumberDesc] = useState(false);
  const [isReferenceDesc, setIsReferenceDesc] = useState(false);

  const handleOrderNumberSort = () => {
    setIsOrderNumberDesc((prevState) => !prevState);
    return data.sort((a, b) =>
      !isOrderNumberDesc
        ? a.orderNumber < b.orderNumber
          ? 1
          : -1
        : a.orderNumber > b.orderNumber
        ? 1
        : -1
    );
  };

  const handleReferenceSort = () => {
    setIsReferenceDesc((prevState) => !prevState);
    return data.sort((a, b) => {
      if (a.reference !== null && b.reference !== null) {
        return !isReferenceDesc
          ? a.reference < b.reference
            ? 1
            : -1
          : a.reference > b.reference
          ? 1
          : -1;
      } else return 0;
    });
  };

  const isFavorited = (orderId: string) => {
    const order = data.find((item) => item.id === orderId);
    if (user) return order?.favoritedBy.includes(user.id);
    return false;
  };

  useEffect(() => {
    if (_.isEmpty(checked)) setCheckedSupplier({ id: '', name: '' });
    Object.keys(checked).forEach((key) => {
      if (!checked[key] && _.size(checked) !== 0) {
        const filteredChecked: Record<string, boolean> = _.omit(checked, key);
        setChecked(filteredChecked);
      }
    });
  }, [checked, setChecked, setCheckedSupplier]);

  const handleCheckedChange = (
    id: string,
    supplierId: string,
    supplierName: string
  ) => {
    if (_.isEmpty(checked)) {
      setCheckedSupplier({
        id: supplierId,
        name: supplierName,
      });
    }
    setChecked((prevValue) => ({
      ...prevValue,
      [id]: !prevValue[id],
    }));
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: '30px',
        borderRadius: 0,
      }}
    >
      <Table
        aria-label="simple table"
        style={{ whiteSpace: 'nowrap', width: '100%' }}
      >
        <TableHead style={{ position: 'sticky', top: 0, zIndex: 2 }}>
          <TableRow
            sx={{
              background: '#ECF0F4',
            }}
          >
            <TableCell>
              <Grid item xs={12} lg={2.5} style={{ width: '20px' }}>
                <FormControlLabel
                  label=""
                  control={
                    <Checkbox
                      color="info"
                      checked={areAllChecked || areAllTrue}
                      onChange={handleAllChecked}
                      disabled={
                        (_.isEmpty(checked) &&
                          filters?.supplierIds?.length === 0) ||
                        !checkedSupplier.id
                      }
                    />
                  }
                  style={{ margin: 0 }}
                />
              </Grid>
            </TableCell>

            <NumberTableCell>Status</NumberTableCell>
            <Tooltip
              title={
                isOrderNumberDesc
                  ? 'Click to sort ascending'
                  : 'Click to sort descending'
              }
              placement="top"
              componentsProps={{
                tooltip: { style: { background: '#1B2130' } },
                arrow: { style: { color: '#1B2130' } },
              }}
              followCursor
              arrow
            >
              <TableCell>
                <TableSortLabel
                  IconComponent={UnfoldMoreIcon}
                  sx={{
                    '& .MuiTableSortLabel-icon': {
                      opacity: 1,
                    },
                  }}
                  onClick={handleOrderNumberSort}
                >
                  Order number
                </TableSortLabel>
              </TableCell>
            </Tooltip>

            <TableCell>Supplier</TableCell>
            <Tooltip
              title={
                isReferenceDesc
                  ? 'Click to sort ascending'
                  : 'Click to sort descending'
              }
              placement="top"
              componentsProps={{
                tooltip: { style: { background: '#1B2130' } },
                arrow: { style: { color: '#1B2130' } },
              }}
              followCursor
              arrow
            >
              <TableCell>
                <TableSortLabel
                  IconComponent={UnfoldMoreIcon}
                  sx={{
                    '& .MuiTableSortLabel-icon': {
                      opacity: 1,
                    },
                  }}
                  onClick={handleReferenceSort}
                >
                  Reference
                </TableSortLabel>
              </TableCell>
            </Tooltip>
            <TableCell>Delivery type</TableCell>
            <TableCell>Delivery location</TableCell>
            <NumberTableCell>Ordered</NumberTableCell>
            <NumberTableCell>Arrived</NumberTableCell>
            <TableCell>Remark</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            overflowY: 'auto',
          }}
        >
          {data &&
            data.map((order: DBClientSupplierOrderItemResponse) => {
              orderedSum = 0;
              receivedSum = 0;
              Object.values(
                order.orderLineItemMeta as unknown as LineItem
              ).forEach((item: LineItem) => {
                orderedSum += item['ordered'];

                receivedSum += item['received'];
              });

              const tooltip = TooltipData();

              const status =
                selectedOrder?.id === order.id
                  ? SelectedProductStatuses.SELECTED
                  : isFavorited(order.id)
                  ? SelectedProductStatuses.FAVORITE
                  : SelectedProductStatuses.NORMAL;

              return (
                <StyledTableRow
                  sx={{
                    background: isFavorited(order.id) ? '#fffbf2' : '#fff',
                    '&:hover': {
                      background: 'rgba(25, 118, 210, 0.03)',
                      cursor: 'pointer',
                      borderBottom: '1.5px solid rgba(224, 224, 224, 1)',
                    },
                  }}
                  onClick={() => openOrderDetails(order)}
                  key={order.id}
                  status={status}
                >
                  <StyledTableCell
                    status={status}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Grid item xs={12} lg={2.5} style={{ width: '20px' }}>
                      <FormControlLabel
                        label=""
                        control={
                          <Checkbox
                            color="info"
                            checked={checked[order.id] || false}
                            value={checked[order.id] || false}
                            onChange={() =>
                              handleCheckedChange(
                                order.id,
                                order.supplier.id,
                                order.supplier.companyShortName
                              )
                            }
                            disabled={
                              (order.supplier.id !== checkedSupplier.id &&
                                checkedSupplier.id !== '') ||
                              order.orderStatus !== 'PROCESSING'
                            }
                          />
                        }
                        style={{ margin: 0 }}
                      />
                    </Grid>
                  </StyledTableCell>

                  <StyledTableCell status={status}>
                    <CenterStatus>
                      <OrderStatus
                        color={getOrderStatusColor(order.orderStatus)}
                      >
                        {getDropdownLabel(
                          DBOrderStatus,
                          order.orderStatus
                        ).toUpperCase()}
                      </OrderStatus>
                    </CenterStatus>
                  </StyledTableCell>
                  <StyledTableCell status={status}>
                    <div>{order.orderNumber}</div>
                    <CellDetails>{`${moment(order.orderedAt).format(
                      'DD.MM - h:mm'
                    )}`}</CellDetails>
                  </StyledTableCell>
                  <StyledTableCell status={status}>
                    <div>{order.supplier.companyShortName}</div>
                    <CellDetails>{order.orderReference}</CellDetails>
                  </StyledTableCell>
                  <StyledTableCell status={status}>
                    <div>{order.reference}</div>
                    <CellDetails>{`${moment(order.orderedAt).format(
                      'DD.MM - h:mm'
                    )}`}</CellDetails>
                  </StyledTableCell>
                  <StyledTableCell status={status}>
                    <div>{order.deliveryType}</div>
                    <CellDetails>{`${moment(
                      order.estimatedDeliveryDateFrom
                    ).format('DD.MM')} - ${moment(
                      order.estimatedDeliveryDateTo
                    ).format('DD.MM')}`}</CellDetails>
                  </StyledTableCell>
                  <StyledTableCell status={status}>
                    <div style={{ textTransform: 'uppercase' }}>
                      {JSON.parse(JSON.stringify(order.deliveryAddress)).city}
                    </div>
                    <CellDetails>
                      {
                        JSON.parse(JSON.stringify(order.deliveryAddress))
                          .address_line_2
                      }
                    </CellDetails>
                  </StyledTableCell>
                  <StyledTableCell status={status}>
                    <OrderNumbers>
                      <NumBorder>{orderedSum}</NumBorder>
                    </OrderNumbers>
                  </StyledTableCell>
                  {/*<StyledTableCell status={status}>*/}
                  {/*  <OrderNumbers>*/}
                  {/*    <NumBorder>{confirmedSum}</NumBorder>*/}
                  {/*  </OrderNumbers>*/}
                  {/*</StyledTableCell>*/}
                  {/*<StyledTableCell status={status}>*/}
                  {/*  {backorderedSum > 0 && (*/}
                  {/*    <OrderNumbers>*/}
                  {/*      <NumBorder*/}
                  {/*        style={{ background: '#454F5B', color: '#fff' }}*/}
                  {/*      >*/}
                  {/*        {backorderedSum}*/}
                  {/*      </NumBorder>*/}
                  {/*    </OrderNumbers>*/}
                  {/*  )}*/}
                  {/*</StyledTableCell>*/}
                  <StyledTableCell status={status}>
                    <OrderNumbers>
                      <NumBorder>{receivedSum}</NumBorder>
                    </OrderNumbers>
                  </StyledTableCell>
                  <StyledTableCell status={status}>
                    <div>{order.supplierRemark}</div>
                    <CellDetails style={{ color: 'red' }}>
                      {order.remark}
                    </CellDetails>
                  </StyledTableCell>
                  <StyledTableCell status={status}>
                    {order.metaDeliveryLate && tooltip?.delivery_late}
                    {order.metaOrderDropShipping && tooltip?.drop_shipping}
                    {order.metaOrderForStock && tooltip?.pre_order}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const StyledTableRow = styled(TableRow)<{
  status: SelectedProductStatuses;
}>`
  height: 60px;
  background: ${(props) =>
    props.status === SelectedProductStatuses.SELECTED
      ? tyrioUI.colors.blue.B10
      : props.status === SelectedProductStatuses.FAVORITE
      ? tyrioUI.colors.yellow.B10
      : 'white'};

  &:hover {
    background: ${tyrioUI.colors.black.B10};
    border-bottom: 1.5px solid ${tyrioUI.colors.black.B40};
  }
`;

export const OrderStatus = styled.div<{
  color: string;
  textColor?: string;
  border?: boolean;
}>`
  width: fit-content;
  border-radius: 100px;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  color: white;
  font-weight: 500;
  background: ${(prop) => prop.color};
  color: ${(prop) => prop.textColor};
  border: ${(props) => (props.border ? '1px solid #DFE3E8' : 'none')};
  display: flex;
  align-items: center;
`;

export const OrderNumbers = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NumBorder = styled.div`
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid grey;
  padding: 10px;
  font-weight: 400;
`;

export const NumberTableCell = styled(TableCell)`
  text-align: center;
`;

export const CellDetails = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #919eab;
`;

export const CenterStatus = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledTableCell = styled(TableCell)<{
  status: SelectedProductStatuses;
  opacity?: boolean;
}>`
  border-bottom: 1px solid
    ${(props) =>
      props.status === SelectedProductStatuses.SELECTED
        ? tyrioUI.colors.blue.B100
        : props.status === SelectedProductStatuses.FAVORITE
        ? tyrioUI.colors.yellow.B100
        : tyrioUI.colors.black.B40};
  opacity: ${(props) => (props.opacity ? '0.3' : '1')};
`;

export default PurchaseOrderTable;
