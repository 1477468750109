import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import { DBProduct } from '@prisma/client';
import { PosProductResponse } from '@tyrio/dto';
import { generateSpecialMarkings } from '@tyrio/products';
import { tyrioUI } from '@tyrio/ui-library';
import { useContext, useState } from 'react';
import { StockCountContext } from '../../../context/StockCountContext';

export enum PosItemRowStatus {
  FAVORITE,
  SELECTED,
  NORMAL,
}

interface SingleTableRowProps {
  product: PosProductResponse;
  isFavorite: boolean;
  expected: number;
  scanned: number;
}

export const ProductItem = ({
  product,
  isFavorite,
  expected,
  scanned,
}: SingleTableRowProps) => {
  const [hovered, setHovered] = useState(false);
  const {
    selectedProduct,
    setSelectedProduct,
    setActiveStep,
    showDifferences,
  } = useContext(StockCountContext);

  const isSelected = selectedProduct?.ean === product.ean;

  const favorite = isFavorite;

  const status: PosItemRowStatus = isSelected
    ? PosItemRowStatus.SELECTED
    : favorite
    ? PosItemRowStatus.FAVORITE
    : PosItemRowStatus.NORMAL;

  const diff = scanned - expected;

  const getScannedColor = () => {
    if (scanned === 0) return '#212B36';

    if (expected === scanned) return '#38A169';

    if (expected > scanned) return '#FFCC4D';
    else return '#DB1E1E';
  };

  const getDiffColor = () => {
    if (diff < 0) return '#DB1E1E';

    return '#212B36';
  };

  const isBolded = () => {
    return scanned !== 0 || diff < 0;
  };

  return (
    <TableRowStyled
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      status={status}
      onClick={() => {
        setSelectedProduct(product);
        setActiveStep('DETAILS');
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {
            <StyledTableCell sx={{ minWidth: '150px', maxWidth: '150px' }}>
              {product?.model?.brand}
              <CellDetails>{product?.model?.season}</CellDetails>
            </StyledTableCell>
          }

          <StyledTableCell sx={{ maxWidth: '150px', minWidth: '150px' }}>
            {product.model?.category?.parent_category_name
              ? product.model?.category.parent_category_name
              : product.model?.category?.name}
            <CellDetails>{product.model?.category?.name}</CellDetails>
          </StyledTableCell>
          <StyledTableCell style={{ overflowX: 'auto', minWidth: '400px' }}>
            {product.productName}
            <SpecialMarkings
              style={{
                color:
                  status === PosItemRowStatus.SELECTED || hovered
                    ? '#e75524'
                    : '#919eab',
              }}
            >
              {generateSpecialMarkings(product as unknown as DBProduct)?.map(
                (item) => item + ' '
              )}
            </SpecialMarkings>
          </StyledTableCell>
          <StyledTableCell
            style={{
              overflowX: 'auto',
              minWidth: '50px',
              maxWidth: '150px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          ></StyledTableCell>
          <StyledTableCell
            padding="none"
            sx={{
              maxWidth: showDifferences ? '230px' : '230px',
              minWidth: showDifferences ? '230px' : '230px',
            }}
          >
            <QtyPriceWrapper hovered={hovered}>
              {!showDifferences && (
                <QuantityPopoverWrapper>
                  <QuantityTypography>{expected}</QuantityTypography>
                </QuantityPopoverWrapper>
              )}
              {!showDifferences && (
                <QuantityPopoverWrapper>
                  <QuantityTypography
                    sx={{
                      fontWeight: isBolded() ? 600 : 400,
                      color: getScannedColor(),
                    }}
                  >
                    {scanned}
                  </QuantityTypography>
                </QuantityPopoverWrapper>
              )}
              <QuantityPopoverWrapper>
                <QuantityTypography
                  sx={{
                    color: getDiffColor(),
                    fontWeight: isBolded() ? 600 : 400,
                  }}
                >
                  {diff}
                </QuantityTypography>
              </QuantityPopoverWrapper>
            </QtyPriceWrapper>
          </StyledTableCell>
        </div>
      </div>
    </TableRowStyled>
  );
};

const TableRowStyled = styled(Grid)<{
  status?: PosItemRowStatus;
  isRecommended?: boolean;
  recommendedColor?: string;
}>`
  display: flex;

  width: 100%;
  cursor: pointer;
  background: ${(props) =>
    props.status === PosItemRowStatus.FAVORITE
      ? tyrioUI.colors.yellow.B10
      : props.status === PosItemRowStatus.SELECTED
      ? tyrioUI.colors.blue.B10
      : 'white'};

  border-bottom: 1px solid
    ${(props) =>
      props.status === PosItemRowStatus.FAVORITE
        ? tyrioUI.colors.yellow.B100
        : props.status === PosItemRowStatus.SELECTED
        ? tyrioUI.colors.blue.B100
        : tyrioUI.colors.black.B40};

  border-left: ${(props) =>
    props.isRecommended && props.recommendedColor
      ? `6px solid ${props.recommendedColor}`
      : ''};

  height: ${(props) => (props.isRecommended ? '90px' : '72px')};
  border-bottom: 1px solid ${tyrioUI.colors.black.B40};
  align-items: center;
  &:hover {
    background: ${tyrioUI.colors.black.B10};
    border-bottom: 1px solid ${tyrioUI.colors.black.B40};
    #quantity_wrapper {
      background: ${tyrioUI.colors.black.B30};
    }
    #product_name_details {
      color: #e75524;
      font-weight: 500;
    }
  }
  #product_name_details {
    color: ${(props) =>
      props.status === PosItemRowStatus.SELECTED ? '#e75524' : '#919eab'};
  }
`;

const StyledTableCell = styled(Grid)`
  border: 0;
  font-weight: 400;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  padding: 6px 16px;
  &:last-child {
    padding-right: 0;
  }
`;

export const CellDetails = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #919eab;
`;

const SpecialMarkings = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #919eab;
  &:hover {
    color: #e75524;
  }
`;

export const QtyPriceWrapper = styled.div<{
  hovered?: boolean;
}>`
  width: 100%;
  max-width: 320px;
  height: 100%;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const QuantityPopoverWrapper = styled.div`
  background: #f4f6f8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 100%;
`;

export const QuantityTypography = styled(Typography)`
  font-size: '16px';
  font-family: 'Barlow';
`;
