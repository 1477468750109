import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { WmsIcon } from '@tyrio/wms-ui-library';
import { FunctionComponent, SVGProps } from 'react';

interface VehicleInfoCardProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  text: string;
  details: string;
  detailsText?: string;
  borderStyle?: 'solid' | 'dashed';
}

export const VehicleInfoCard = ({
  icon,
  text,
  details,
  detailsText,
  borderStyle = 'solid',
}: VehicleInfoCardProps) => {
  return (
    <Wrapper borderStyle={borderStyle}>
      <InfoWrapper>
        <AddressWrapper>
          <WmsIcon
            icon={icon}
            sx={{ color: '#1976D2' }}
            viewBox={'0 0 32 32'}
            stroke={'#1976D2'}
          />
          <AddressDetailsText>{text}</AddressDetailsText>
        </AddressWrapper>
        <Text style={{ marginTop: '2px' }}>{details}</Text>
      </InfoWrapper>

      {detailsText !== '' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <Text>Licence plate:</Text>
          <AddressDetailsText>{detailsText}</AddressDetailsText>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  borderStyle: 'solid' | 'dashed';
}>`
  border: ${(props) => `1px ${props.borderStyle} #e6e6e6`};
  border-radius: 8px;
  height: 74px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px;
  width: 100%;
`;

const AddressWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;

const Text = styled(Typography)<{ fontSize?: string }>`
  color: #919eab;
  font-family: Barlow;
  font-weight: 400;
  font-size: 12px;
  font-size: ${(props) => (props.fontSize ? '10px' : '12px')};
`;

const AddressDetailsText = styled(Typography)<{ fontSize?: string }>`
  font-family: Barlow;
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')}
  overflow-x: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
`;
