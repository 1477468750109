import { wmsIcons } from '@tyrio/wms-ui-library';

export const MenuList = [
  {
    icon: wmsIcons.summary,
    text: 'SUMMARY',
    disabled: false,
  },
  {
    icon: wmsIcons.search,
    text: 'SEARCH',
    disabled: false,
  },
  {
    icon: wmsIcons.details,
    text: 'DETAILS',
    disabled: false,
  },
];
