import api from '@tyrio/api-factory';
import { DBStockCountApi, DBStockCountGetByIdRes } from '@tyrio/dto';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { StockCountContext } from '../../../context/StockCountContext';

export const useGetStockCountById = () => {
  const { setSelectedStockCountData } = useContext(StockCountContext);

  const mutation = useMutation<DBStockCountGetByIdRes | null, Error, string>(
    (id: string) =>
      api.fetch<DBStockCountApi['id']>('get_stock_count_by_id', {
        id,
      }),
    {
      onSuccess: (d: DBStockCountGetByIdRes | null) => {
        if (d) {
          setSelectedStockCountData(d);
        }
      },
    }
  );

  return { ...mutation };
};
