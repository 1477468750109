import { PermissionsValues } from "@tyrio/dto";
import _ from "lodash";

 export const hasPermissionToView = (permissionList: PermissionsValues, permissionPath: string) : boolean=> {
    return _.get(permissionList, `${permissionPath}.view`) === true;
  };
  
  export const hasSubcategoryPermission = (permissionList: PermissionsValues, subcategories: any[]) : boolean=> {
    return subcategories.some((subcategory) => {
      if (hasPermissionToView(permissionList, subcategory.permissionPath)) {
        return true;
      }
      return false;
    });
  };