import { DBStockCountGetByIdRes, PosProductResponse } from '@tyrio/dto';
import { Dispatch, SetStateAction, createContext } from 'react';

export type ActiveStep = 'SUMMARY' | 'SEARCH' | 'DETAILS';
export type ActiveDrawer = 'GALLERY' | 'PRINT' | '';
export type ViewType = 'SHELF' | 'PRODUCT';

type StockCountContextInterface = {
  selectedStockCount: string | null;
  setSelectedStockCount: Dispatch<SetStateAction<string | null>>;
  view: ViewType;
  setView: Dispatch<SetStateAction<ViewType>>;
  showDifferences: boolean;
  setShowDifferences: Dispatch<SetStateAction<boolean>>;
  selectedStockCountData: DBStockCountGetByIdRes | null;
  setSelectedStockCountData: Dispatch<
    SetStateAction<DBStockCountGetByIdRes | null>
  >;
  selectedProduct: PosProductResponse | null;
  setSelectedProduct: Dispatch<SetStateAction<PosProductResponse | null>>;
  activeStep: ActiveStep;
  setActiveStep: Dispatch<SetStateAction<ActiveStep>>;
  activeDrawer: ActiveDrawer;
  setActiveDrawer: Dispatch<SetStateAction<ActiveDrawer>>;
  filters: qs.ParsedQs;
  setFilters: (data: Partial<qs.ParsedQs>) => void;
};

export const StockCountContext = createContext<StockCountContextInterface>({
  selectedStockCount: null,
  setSelectedStockCount: () => {
    return;
  },
  selectedStockCountData: null,
  setSelectedStockCountData: () => {
    return;
  },
  view: 'PRODUCT',
  setView: () => {
    return;
  },
  showDifferences: false,
  setShowDifferences: () => {
    return;
  },
  filters: {},
  setFilters: () => {
    return;
  },
  selectedProduct: null,
  setSelectedProduct: () => {
    return;
  },
  activeStep: 'SUMMARY',
  setActiveStep: () => {
    return;
  },
  activeDrawer: '',
  setActiveDrawer: () => {
    return;
  },
});

interface StockCountProviderProps {
  children: React.ReactNode;
  value: StockCountContextInterface;
}

const StockCountProvider = ({ children, value }: StockCountProviderProps) => {
  return (
    <StockCountContext.Provider value={value}>
      {children}
    </StockCountContext.Provider>
  );
};

export default StockCountProvider;
