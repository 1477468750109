import { DBProduct, Prisma } from '@prisma/client';
import { PhotoShape } from './product-model';

export enum Availability {
  my_stock = 'My stock',
  my_stock_and_branches = 'My stock and my branch stock',
  supplier_stock = 'Supplier stock',
}

interface Payload {
  page: number;
  pageSize: number;
  filter: SearchFilters;
}
export interface SearchFilters {
  cb: string[];
  pcd: string;
  et: number[];
  winter?: boolean;
  width?: string[];
  rimDiameter?: string[];
  colorGroup?: string[];
  requiredRimSizes?: string[];
  brand?: string[];
  modelName?: string[];
  colorFinish?: string[];
  availability?: string[];
  warehouseId?: string;
  customerId: string;
}

export interface ISupplierStock {
  suggestedRetailPrice: number | null;
  purchasePrice: number | null;
  transportCost: number;
  ecoTax: number;
  supplierId: string;
  quantity: number | null;
  productId: string;
}

export interface SearchWheelsResponse {
  availableRimSizes: (string | null)[];
  stockListRimSizes: (string | null)[];
  model: {
    uid: string;
    season: string | null;

    color: string | null;
    brand: string | null;
    modelName: string | null;
    colorGroup: string | null;
    photos: PhotoShape[];
  };
  price: (
    | {
        price: number | null;
        inclusiveVatPrice: number;
      }
    | undefined
  )[];
  productIds?: string[];
  productModelUid: string;
  stockListItems?: IStockListItems[];
  supplierStock?: ISupplierStock[];
}

export type IDBProduct = DBProduct & { supplierStock: ISupplierStock[] } & {
  stockListItems: IStockListItems[];
};

export interface IProduct {
  uid: string;
  rimDiameter: string | null;
  productName: string;
  et: number[];
  ean: string;
  width: string | null;
  cb: number | null;
  pcd: string[] | null;
  sku: string;
  customColor?: string | '';
}

export interface WheelModelDetails {
  uid: string;
  brand: string | null;
  modelName: string | null;
  color: string | null;
  colorGroup: string | null;
  photos?: PhotoShape[];
  DBProduct: IProduct[];
  category: {
    name: string;
    id: number;
  };
  availableRimSizes: string[];
}

export interface IStockListItems {
  quantity: number;
  productId: string;
  branchId: number;
  price: number;
  dot: string | null;
}

export interface IFilterData {
  cb: (number | null)[];
  colorGroup: (string | null)[];
  rimSize: (string | null)[];
  width: (string | null)[];
}

export type MemoryProduct = Record<
  string,
  | string
  | number
  | null
  | Array<string>
  | Array<number>
  | Date
  | Prisma.JsonValue
>;

export interface IMemoryProduct {
  category_id: string;
  width: string | null;
  height: string | null;
  holeCircle: string[];
  holeNumber: string[];
  rimSize: string;
  colorGroup: string;
  cb: number;
  pcd: string[];
  et: number[];
  modelName: string;
  brand: string;
  modelId: string;
  productId: string;
  timestamp: Date;
  color: string;
  photos: [];
  ean: string;
  colorFinish: string[];
  supplierStock: ISupplierStock[];
}
export interface DBPosWheelsApi {
  list: {
    requestBody: Payload;
    requestParams: Payload;
    request: Payload;
    response: {
      data: SearchWheelsResponse[];
      filterData: IFilterData;
      count: number;
      hasNextPage: boolean;
    };
  };
  getFilter: {
    requestBody: {
      cb: string;
      pcd: string;
      rimDiameter?: number[];
      et?: number[];
    };
    requestParams: {
      cb: string;
      pcd: string;
      rimDiameter?: number[];
      et?: number[];
    };
    request: { cb: string; pcd: string; rimDiameter?: number[]; et?: number[] };
    response: Record<string, string[] | number[]>;
  };
  getWheelDetails: {
    requestBody: never;
    requestParams: {
      modelName: string;
      brand: string;
      pcd?: string;
      et_min?: number;
      et_max?: number;
      minRimSize?: number;
    };
    request: {
      modelName: string;
      brand: string;
      pcd?: string;
      et_min?: number;
      et_max?: number;
      minRimSize?: number;
    };
    response: WheelModelDetails[];
  };
}
