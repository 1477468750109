import { useCallback, useContext, useMemo } from 'react';
import { SalesOrdersFlowContext } from '../SalesOrdersFlowContext';
import { DBProduct } from '@prisma/client';
import { get, sumBy } from 'lodash';
import { Split, Title, Wrapper, Pill } from './OrderItem';
import styled from '@emotion/styled';
import { PriceCalculationMeta } from '@tyrio/dto';

interface OrderItemProps {
  branchId: string;
  product: DBProduct;
  purchasedQuantity: number;
  selected?: boolean;
  lowestSupplierPrice: number;
}
export const LagerItem = ({
  branchId,
  product,
  lowestSupplierPrice,
}: OrderItemProps) => {
  const ctx = useContext(SalesOrdersFlowContext);

  const branch = ctx.data.warehouses[branchId];
  const warehouseQuantity =
    ctx.data.warehouseQty?.[product.uid]?.[Number(branchId)]?.quantity ?? 0;

  const meta = ctx.data.warehouseQty?.[product.uid]?.[Number(branchId)]
    ?.calculatedPrice?.['meta'] as PriceCalculationMeta;

  const prices = meta?.['prices'];

  const purchasingPrice =
    Number(prices?.purchasePrice ?? 0) +
    Number(prices?.ecoTax ?? 0) +
    Number(prices?.transportCost ?? 0);
  const price = purchasingPrice ?? 0;

  const setQuantity = useCallback(
    (value: string) => {
      const converted = Number(value);
      const parsedQty = isNaN(converted) ? 0 : converted;

      const supplierQty = sumBy(Object.values(ctx?.qty?.supplier), product.uid);
      const stockQty = sumBy(Object.values(ctx?.qty?.stock), product.uid);
      const filledQty = supplierQty + stockQty;

      const requestedQty = ctx.data.requested[product.uid];

      let finalValue = Math.max(0, parsedQty);

      const branchQty = get(ctx.qty.stock[branchId], `${product.uid}`, 0);

      if (
        filledQty === requestedQty &&
        finalValue + filledQty > requestedQty &&
        finalValue > branchQty
      )
        return;

      finalValue = Math.min(finalValue, requestedQty);

      ctx.setQty(branchId, product.uid, finalValue, 'stock');
    },
    [branchId, ctx, product.uid]
  );

  const inputQty = useMemo(() => {
    const value = ctx.qty.stock?.[branchId]?.[product.uid] ?? undefined;
    return String(value) ?? '';
  }, [branchId, product.uid, ctx]);

  const isSelected = get(ctx.qty.stock, [branchId, product.uid], 0) > 0;

  const requestedQuantity = ctx.data.requested?.[product.uid] ?? 0;

  const pillColorClassName =
    warehouseQuantity === 0
      ? 'red'
      : warehouseQuantity < requestedQuantity
      ? 'orange'
      : 'dark';

  return (
    <Wrapper
      selected={isSelected}
      onClick={() => {
        ctx.setSelected(branchId, product.uid);
      }}
    >
      <Split>
        <Title>{branch?.branchName ?? ''}</Title>
        <Pill canFulfillQuantity={warehouseQuantity >= requestedQuantity}>
          <input
            className="light"
            value={inputQty}
            placeholder={'0'}
            type="number"
            disabled={warehouseQuantity <= 0}
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          ></input>
          <div className={pillColorClassName}>{warehouseQuantity ?? 0}</div>
        </Pill>

        <Price
          isLargerThanLowestPrice={
            price > lowestSupplierPrice && warehouseQuantity > 0
          }
        >
          {price.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',
          })}
        </Price>
      </Split>
    </Wrapper>
  );
};

const Price = styled.div<{ isLargerThanLowestPrice?: boolean }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.3px;
  color: #212b36;
  padding: 2px 8px;
  border-radius: 8px;
  ${(props) =>
    props.isLargerThanLowestPrice && props.isLargerThanLowestPrice
      ? `color: white; background:#DB1E1E`
      : ''};
`;
