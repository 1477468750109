import { TextField, Tooltip, Typography } from '@mui/material';
import { CartStockListItem } from '@tyrio/dto';
import { isEmpty, isEqual, omit } from 'lodash';
import { useContext, useState } from 'react';
import { StockListContext } from '../../../../context/StockListContext';
import { useCartData } from '../Cart/helper/cart-helper';
import { BranchesProp } from './WarehouseItems';
import { LineItems, SingleBranch } from './helper/interfaces';
import { ItemWrapper } from './styles/Warehouse.style';

interface i {
  item: LineItems | BranchesProp;
  index: number;
  mainBranch: LineItems;
  setMainBranchStock: (a: number) => void;
  mainBranchStock: number;
}
//  SINGLE ROW COMPONENT FOR OUTBOUND ORDERS
export const RenderItem = ({
  item,
  index,
  mainBranch,
  setMainBranchStock,
  mainBranchStock,
}: i) => {
  const {
    outboundOrders,
    setOutboundOrders,
    setOutboundOrderItems,
    outboundOrderItems,
    setOutboundError,
  } = useContext(StockListContext);

  const { newCartOutboundData } = useCartData();

  const current_stock = (item as LineItems)?.quantity | 0;

  const [incoming, setIncoming] = useState(0);
  const [error, setError] = useState(false);

  const preventNegativeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value) && value < 0) {
      e.target.value = '0';
    }
    if (!Number.isInteger(value)) e.target.value = '0';
  };

  const getOldQty = () => {
    if (!item.subBranchId) {
      const newItem = item as LineItems;
      const a = newCartOutboundData.filter(
        (d) =>
          d[0].includes(newItem?.branchId.toString()) &&
          d[0].includes(mainBranch?.branchId.toString())
      );

      const key1 = newItem.ean + '_' + newItem.dot + '_' + newItem.branchId;

      return !isEmpty(a)
        ? (a[0][1][key1] as CartStockListItem)?.quantity | 0
        : 0;
    } else return 0;
  };

  const addDataToOutboundOrderItems = (
    index: number,
    order: SingleBranch,
    newValue: number,
    deleteItem: boolean, //THIS INDICATES IF WE NEED TO DELETE LINE ITEM FROM outboundOrderItems
    newItem?: LineItems | undefined
  ) => {
    let itemInOutboundOrderItems;

    // THIS ITEM DOESN'T EXIST ON SUBBRANCH
    if (item.subBranchId !== undefined) {
      itemInOutboundOrderItems = outboundOrderItems.find(
        (o) =>
          o.branchId === (item as LineItems)?.subBranchId &&
          o.warehouseId === mainBranch.branchId
      );
    } else {
      itemInOutboundOrderItems = outboundOrderItems.find(
        (o) =>
          o.branchId === (item as LineItems)?.branchId &&
          o.warehouseId === mainBranch.branchId
      );
    }

    if (itemInOutboundOrderItems === undefined && deleteItem) {
      setOutboundOrderItems([]);
      return;
    }

    // ARRAY IS EMPTY-> ADD ITEMS
    if (itemInOutboundOrderItems === undefined && !deleteItem) {
      let itemNew;
      if (outboundOrders[index] === undefined) {
        itemNew = newItem;
      } else {
        // CHANGE QUANTITY OF EXISTING ITEM
        itemNew = outboundOrders[index]?.lineItems.find(
          (singleItem: LineItems) => isEqual(singleItem, item)
        );
        // itemNew.quantity = newValue;
      }

      const orderItem = {
        branchId: order.branchId,
        branchName: order.branchName,
        branchCity: order.branchCity,
        warehouseId: mainBranch.branchId,
        stockLocationId: order.stockLocationId,
        lineItems: [
          {
            ...itemNew,
            quantity: newValue,
          },
        ],
      };

      setOutboundOrderItems([...outboundOrderItems, orderItem]);
    }
    // ARRAY IS NOT EMPTY, THERE ARE TWO CONDITIONS WE NEED TO CHECK
    else {
      const idx = outboundOrderItems.indexOf(itemInOutboundOrderItems);

      const lineItemExists = outboundOrderItems[idx].lineItems.find(
        (singleItem: LineItems) =>
          isEqual(
            omit(singleItem, 'quantity', 'reserved', 'createdAt'),
            omit(item, 'quantity', 'reserved', 'createdAt')
          )
      );

      if (!deleteItem && newValue > 0) {
        // 1. LINE ITEM EXISTS, CHANGE QUANTITY
        if (lineItemExists !== undefined) {
          lineItemExists.quantity = newValue;
          setOutboundOrderItems([...outboundOrderItems]);
        } else {
          // 2. LINE ITEM DOESN'T EXISTS, ADD NEW ITEM
          const c = outboundOrderItems[idx].lineItems;

          if (item.subBranchId !== undefined && newItem !== undefined) {
            outboundOrderItems[idx].lineItems = [
              ...c,
              { ...newItem, quantity: newValue },
            ];
          } else {
            outboundOrderItems[idx].lineItems = [
              ...c,
              { ...item, quantity: newValue },
            ];
          }

          setOutboundOrderItems([...outboundOrderItems]);
        }
      }
      // DELETE LINE ITEM
      else {
        // 1. ONLY ONE LINE ITEM EXIST, DELETE WHOLE OBJECT
        if (outboundOrderItems[idx].lineItems.length === 1) {
          outboundOrderItems.splice(idx, 1);

          setOutboundOrderItems([...outboundOrderItems]);
        } else {
          // 2. DELETE SINGLE LINE ITEM

          outboundOrderItems[idx].lineItems.splice(
            outboundOrderItems[idx].lineItems.indexOf(lineItemExists),
            1
          );

          setOutboundOrderItems([...outboundOrderItems]);
        }
      }
    }
  };

  const handleChange = (val: number) => {
    let deleteItem = false;
    const numberOfFigures = val.toString().length;

    // if (numberOfFigures > 1) {
    // number has mulptiple figures, reset main qty to previous
    // mainBranch.quantity += incoming;
    // }

    if (mainBranchStock - val < 0) {
      setError(true);
      setOutboundError(true);
    } else {
      setError(false);
      setOutboundError(false);
    }

    if (isNaN(val) || val < 0 || mainBranchStock - val < 0) val = 0;

    const itemInOutboundOrders = outboundOrders.find(
      (o) => o.branchId === (item as LineItems)?.branchId
    );
    const indexOfItemInOutboundOrders =
      outboundOrders.indexOf(itemInOutboundOrders);

    setIncoming(val);

    if (val === 0) deleteItem = true;

    let decrease = val;

    //   ITEM EXISTS, ONLY CHANGE ITS QUANTITY AND INCOMING VALUE
    if (indexOfItemInOutboundOrders >= 0) {
      if (incoming !== 0 && incoming !== val && numberOfFigures <= 1) {
        decrease = val - incoming;
      }
      // // DECREASE QUANTITY OF MAIN BRANCH
      setMainBranchStock(mainBranchStock - decrease);
      // mainBranch.quantity -= decrease;

      if (val < 0) val = 0;

      addDataToOutboundOrderItems(
        indexOfItemInOutboundOrders,
        itemInOutboundOrders,
        val,
        deleteItem
      );
    }
    // ITEM DOES NOT EXIST, WE HAVE TO CREATE IT
    else if (indexOfItemInOutboundOrders === -1 && !deleteItem) {
      const itemInOutboundOrders = outboundOrders.find(
        (o) => o.branchId === item?.subBranchId
      );

      const newItem: LineItems = {
        branchId: item?.subBranchId as number,
        ean: mainBranch.ean,
        manufacturerCode: mainBranch.manufacturerCode,
        productName: mainBranch.productName,
        productionYear: mainBranch.productionYear,
        sku: mainBranch.sku,
        stockListItemId: null,
        uid: mainBranch.uid,
        quantity: 0,
        reserved: mainBranch.reserved,
        dot: mainBranch.dot,
        calculatedPrice: mainBranch.calculatedPrice,
        brand: mainBranch.brand ?? '',
      };

      itemInOutboundOrders.lineItems = [
        ...itemInOutboundOrders.lineItems,
        newItem,
      ];

      // DECREASE QUANTITY OF MAIN BRANCH
      setMainBranchStock(mainBranchStock - val);
      // mainBranch.quantity -= val;

      addDataToOutboundOrderItems(
        indexOfItemInOutboundOrders,
        itemInOutboundOrders,
        val,
        deleteItem,
        newItem
      );
    }
    setOutboundOrders([...outboundOrders]);
  };

  const disableArrows = (e: {
    keyCode: number;
    preventDefault: () => void;
  }) => {
    if (e.keyCode === 40 || e.keyCode === 38) e.preventDefault();
  };

  const disabled = getOldQty() > 0 ? true : false;

  return (
    <ItemWrapper borderR={index === 0 ? true : false} key={index}>
      <Typography
        fontSize={16}
        fontWeight={600}
        style={current_stock === 0 ? { color: 'red' } : { color: '#212B36' }}
      >
        {current_stock || 0}
      </Typography>
      <Tooltip
        title={
          error
            ? "Main branch doesn't have this many products on stock"
            : disabled
            ? 'Item is already in cart'
            : ''
        }
      >
        <TextField
          variant="outlined"
          sx={{ width: '60px' }}
          placeholder={(incoming || 0).toString()}
          onChange={(e) => handleChange(Number(e.target.value))}
          error={error}
          disabled={disabled}
          type="number"
          InputProps={{
            style: {
              background: 'white',
              height: '40px',
              width: '60px',
              borderColor: '#E6E8F0',
              textAlign: 'center',
              zIndex: '10',
            },
            inputProps: {
              style: { textAlign: 'center' },
              min: 0,
              onInput: preventNegativeInput,
              onKeyDown: disableArrows,
            },
          }}
        />
      </Tooltip>
    </ItemWrapper>
  );
};
