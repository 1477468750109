import { DBDCollectionOrderType } from '@prisma/client';
import { PrinterFunctionalities } from './printers';

export enum OrderTypesEnum {
  TRANSFER_ORDER = 'TRANSFER_ORDER',
  CUSTOMER_ORDER = 'CUSTOMER_ORDER',
}

export enum Couriers {
  DPD = 'DPD',
  GLS = 'GLS',
  GEBRUDER_WEISS = 'GEBRUDER WEISS',
}

export type DpdType = 'DPD Croatia' | 'DPD Croatia (Drophip)';
export type GlsType = 'GLS Croatia' | 'GLS Croatia (Drophip)';

export interface RequestBody {
  orderId: string; //TO or CO id
  orderType: OrderTypesEnum;
  nrOfParcels: number; //  indicates the count of prepared delivery packages
  stockOutType: PrinterFunctionalities;
  orderInfo: {
    id: string; //work order or transfer order id
    type: DBDCollectionOrderType;
  };
  printType: 'delivery-note' | 'work-order' | 'both';
  reference: string;
  courier?: 'DPD' | 'GLS' | 'GEBRUDER WEISS';
  abbreviation?: string;
  clientCourierId?: string | null;
}

export interface BarcodeData {
  ean: string;
  quantity: number;
}
export interface PrintBarcodeBody {
  data: BarcodeData[];
  printerId: number;
}

export interface DBShipAndPrintApi {
  printBarcode: {
    requestBody: PrintBarcodeBody;
    requestParams: never;
    request: PrintBarcodeBody;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: any;
  };
}
