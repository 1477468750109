import styled from '@emotion/styled';
import { DBStockCountGetByIdRes, PosProductResponse } from '@tyrio/dto';
import { useState } from 'react';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import StockCountProvider, {
  ActiveDrawer,
  ActiveStep,
  ViewType,
} from '../../context/StockCountContext';
import { Menu } from '../../features/stock-count/menu/Menu';
import { StockCountDetails } from '../../features/stock-count/pages/StockCountDetails';
import { StockCountList } from '../../features/stock-count/pages/StockCountList';
import { Steps } from '../../features/stock-count/steps/Steps';
import { useFilter } from '../../hooks/useFilter';
import { Drawers } from '../../features/stock-count/drawer/Drawer';

const StockCount = () => {
  const [activeStep, setActiveStep] = useState<ActiveStep>('SUMMARY');
  const [activeDrawer, setActiveDrawer] = useState<ActiveDrawer>('');

  const [selectedStockCount, setSelectedStockCount] = useState<string | null>(
    null
  );

  const [selectedStockCountData, setSelectedStockCountData] =
    useState<DBStockCountGetByIdRes | null>(null);

  const [selectedProduct, setSelectedProduct] =
    useState<PosProductResponse | null>(null);

  const [view, setView] = useState<ViewType>('PRODUCT');
  const [showDifferences, setShowDifferences] = useState(false);

  const { filterValues: filters, setFilterValue: setFilters } = useFilter({});

  const width = '650px';

  return (
    <PageTemplateWrapper>
      <StockCountProvider
        value={{
          activeStep,
          setActiveStep,
          activeDrawer,
          setActiveDrawer,
          filters,
          setFilters,
          selectedStockCount,
          setSelectedStockCount,
          selectedStockCountData,
          setSelectedStockCountData,
          selectedProduct,
          setSelectedProduct,
          view,
          setView,
          showDifferences,
          setShowDifferences,
        }}
      >
        {selectedStockCount === null ? (
          <StockCountList />
        ) : (
          <TemplatesWrapper>
            <PageTemplateContent
              style={{
                width: 'calc(100% - 650px)',
                transition: 'all 0.3s ease',
              }}
            >
              <StockCountDetails />
            </PageTemplateContent>

            <PageTemplateContent
              style={{
                background: '#f5f6f8',
                minWidth: width,
                maxWidth: width,
                marginRight: '0px',
                height: 'calc(100svh - 14px)',
                display: 'flex',
                zIndex: 99,
                transition: 'all 0.3s ease',
              }}
            >
              <DrawerWrapper>
                {activeDrawer &&
                  Drawers({
                    activeDrawer,
                    setActiveDrawer,
                    selectedProduct,
                  })}
                {activeStep && Steps(activeStep)}
              </DrawerWrapper>
              <Menu activeStep={activeStep} setActiveStep={setActiveStep} />
            </PageTemplateContent>
          </TemplatesWrapper>
        )}
      </StockCountProvider>
    </PageTemplateWrapper>
  );
};

const DrawerWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10;
  position: relative;
`;

export const TemplatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export default StockCount;
