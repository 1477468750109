import { SetGenericOptions } from "@tyrio/dto";

 export  const defaultSettings = {
    model_name_front: SetGenericOptions.ALL,
    model_name_rear: SetGenericOptions.SAME_AS_FRONT,
    speed_index_front: SetGenericOptions.ALL,
    speed_index_rear: SetGenericOptions.SAME_OR_HIGHER, 
    dot_front: SetGenericOptions.ALL,
    dot_rear: SetGenericOptions.SAME_OR_NEWER,
    product_oem: false,
    product_demo: false, 
    product_rfd: false,
  };
  export const motoDefaultSetSettings = {
    ...defaultSettings,
    compound_front: SetGenericOptions.ALL,
    compound_rear: SetGenericOptions.ALL_COMPOUNDS,
    product_structure: false,
    product_sidewall: false,
    product_tubeType: false,
  }

  export const autoSetSettings = {
    ...defaultSettings,
    product_electric: false,
    product_seal: false,
    product_silent: false,
  }
  export interface SetSelectProps {
    label: string;
    optionsFront: string[];
    optionsRear: string[]
  }
  export const setSettingsSelect: SetSelectProps[] = [
    // TODO: add this later
    //{ label: 'brand', options: [] },
    { label: 'model_name', optionsFront: [], optionsRear: [] },
    { label: 'speed_index', optionsFront: [], optionsRear: [] },
    { label: 'compound', optionsFront: [], optionsRear: [] },
    { label: 'dot', optionsFront: [], optionsRear: [] },
  ];
  
  export const setSettingsAutoCheck = [ 
    { name: 'product_electric', label: 'Electric 100% match' },
    { name: 'product_oem', label: 'OEM 100% match' },
    { name: 'product_seal', label: 'Seal 100% match' },
    { name: 'product_silent', label: 'Silent 100% match' },
    { name: 'product_rfd', label: 'RFD 100% match' },
    {
      name: 'product_demo',
      label: 'Demo 100% match',
    }]
  export const setSettingsMotoCheck = [
    { name: 'product_structure', label: 'Structure 100% match' },
    { name: 'product_oem', label: 'OEM 100% match' },
    { name: 'product_sidewall', label: 'Sidewall 100% match' },
    { name: 'product_tubeType', label: 'TubeType 100% match' },
    { name: 'product_rfd', label: 'RFD 100% match' },
    {
      name: 'product_demo',
      label: 'Demo 100% match',
    },
  ];

  export interface CheckProps {
    label: string;
    name: string;
  }
  