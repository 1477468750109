import { cartIcons } from '@tyrio/ui-library';

export const informationList = [
  {
    icon: cartIcons.customer,
    text: 'Customer',
    disabled: false,
    altText: 'Customer icon',
  },
  {
    icon: cartIcons.truck,
    text: 'Delivery method',
    disabled: false,
    altText: 'Delivery method icon',
  },
  {
    icon: cartIcons.appointment,
    text: 'Appointment',
    disabled: false,
    altText: 'Appointment icon',
  },
  {
    icon: cartIcons.vehicle,
    text: 'Vehicle',
    disabled: false,
    altText: 'Vehicle icon',
  },
  // {
  //   icon: cartIcons.payment,
  //   text: 'Payment',
  //   disabled: false,
  //   altText: 'Payment icon',
  // },
];
