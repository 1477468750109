import { TableCell, Typography } from '@mui/material';
import { YearChip } from '@tyrio/wms-ui-library';
import { useEffect, useState } from 'react';
import { RenderItem } from './SingleItem';
import { LineItems } from './helper/interfaces';
import { StyledTableCell, StyledTableRow } from './styles/Warehouse.style';
import { getReservedAndUpcomingValues } from '../Cart/helper/socket-helper';
import { useWS } from '../../../../context/WSContext';
import { useAuth } from '../../../../context/AuthContext';
import { isDotOlderThanThreeYears } from '../ProductDetails/helpers/dot-helper';

export interface BranchesProp {
  subBranchId: number;
}
interface WarehouseItemsProps {
  branches: (LineItems | BranchesProp)[];
  mainBranch: LineItems;
}

export const WarehouseTransfer = ({
  branches,
  mainBranch,
}: WarehouseItemsProps) => {
  const yearChipColor = isDotOlderThanThreeYears(mainBranch.dot)
    ? 'error'
    : mainBranch.dot === 'XXXX'
    ? 'primary'
    : 'success';

  const ws = useWS();
  const { user } = useAuth();

  const { reserved } = getReservedAndUpcomingValues(
    ws?.state?.cart?.userCart,
    mainBranch.ean + '_' + mainBranch.dot,
    mainBranch.branchId,
    user?.id
  );

  const stock = mainBranch.quantity - mainBranch.reserved - reserved;
  const [mainBranchStock, setMainBranchStock] = useState(stock);

  useEffect(() => {
    if (stock !== mainBranchStock && stock >= 0) setMainBranchStock(stock);
  }, [mainBranchStock, stock]);

  return (
    <StyledTableRow>
      <StyledTableCell>
        <Typography
          fontSize={16}
          fontWeight={600}
          style={{ marginBottom: '5px' }}
        >
          {mainBranchStock}
        </Typography>
        {mainBranch.dot && (
          <YearChip year={mainBranch.dot} color={yearChipColor} />
        )}
      </StyledTableCell>

      {branches.map((b: LineItems | BranchesProp, i: number) => {
        return (
          <TableCell
            style={{ width: '100px', padding: 0, paddingBottom: '8px' }}
            key={i}
          >
            <RenderItem
              item={b}
              index={i}
              mainBranch={mainBranch}
              setMainBranchStock={setMainBranchStock}
              mainBranchStock={mainBranchStock}
            />
          </TableCell>
        );
      })}
    </StyledTableRow>
  );
};
