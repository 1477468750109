import { Checkbox, TextField, Typography } from '@mui/material';
import { PaymentTypeEnum } from '@prisma/client';
import {
  PaymentCardWrapper,
  TextWrapper,
} from '../../../../styles/PaymentForm.style';

interface PaymentCardProps {
  paymentType: PaymentTypeEnum | null;
  paymentName: string;
  paymentCode: string;
  onClick: (
    isChecked: boolean,
    title: string,
    paymentType: PaymentTypeEnum | null,
    paymentCode: string,
    applyCashDiscount: boolean
  ) => void;
  value: number;
  selected?: boolean;
  changeAmount: (title: string, value: string) => void;
  disableCheckbox?: boolean;
  applyCashDiscount: boolean;
}

export const PaymentCard = ({
  paymentType,
  paymentName,
  paymentCode,
  selected = false,
  value,
  onClick,
  changeAmount,
  disableCheckbox,
  applyCashDiscount,
}: PaymentCardProps) => {
  return (
    <PaymentCardWrapper selected={selected}>
      <TextWrapper
        onClick={() =>
          onClick(
            !selected,
            paymentName,
            paymentType,
            paymentCode,
            applyCashDiscount
          )
        }
      >
        <Checkbox
          disabled={disableCheckbox}
          color="info"
          onChange={({ target: { checked } }) => {
            onClick(
              checked,
              paymentName,
              paymentType,
              paymentCode,
              applyCashDiscount
            );
          }}
          checked={selected}
        />
        <Typography>{paymentName}</Typography>
      </TextWrapper>

      {selected && (
        <TextField
          id={paymentName}
          value={
            value !== 0 && value - Math.floor(value) === 0
              ? Number(value).toFixed(2).replace(/^0+/, '')
              : value - Math.floor(value) !== 0
              ? Number(value).toFixed(2)
              : Number(0).toFixed(2)
          }
          label={'Amount'}
          size="small"
          placeholder={'Amount'}
          type="number"
          sx={{ width: '30%' }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onKeyDown={(e: any) => {
            if (e.keyCode === 190) e.preventDefault();
          }}
          onChange={(event) => changeAmount(paymentName, event.target.value)}
          variant={'standard'}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </PaymentCardWrapper>
  );
};
