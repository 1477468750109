import { Tooltip, Typography } from '@mui/material';
import { DBStockList, IWorkOrderLineItem } from '@tyrio/dto';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { useWS } from '../../../../context/WSContext';
import noDocument from '../../assets/no-document.png';
import { findReservedValue } from '../Cart/helper/socket-helper';
import CartReservedItems from './CartReservedItems';
import {
  DocumentDetailsWrapper,
  DocumentNameWrapper,
  DocumentReferenceWrapper,
  FileIconNameWrapper,
  ReservedNumberWrapper,
  ReservedTextWrapper,
  ReservedWrapper,
} from './ItemReservationInfo.style';
import { DBWorkOrders } from '@prisma/client';
import { tyrioUI } from '@tyrio/ui-library';
import styled from '@emotion/styled/macro';
import _ from 'lodash';

interface WorkOrdersReservationProps {
  data: DBWorkOrders[];
  selectedStockListItem: DBStockList;
  locationQrCode?: string;
}

export const WorkOrdersReservation = ({
  data,
  selectedStockListItem,
}: WorkOrdersReservationProps) => {
  const [hoverState, setHoverState] = useState<Record<number, boolean>>({
    0: false,
  });

  const getReservedQuantityByOrder = (item: DBWorkOrders) => {
    if (item.lineItems) {
      const selectedItem = (
        item.lineItems as unknown as IWorkOrderLineItem[]
      ).filter((item) => item.ean === selectedStockListItem?.ean);

      return selectedItem[0].quantity;
    }

    return 0;
  };

  const { user } = useAuth();

  // TODO (stock-list): FIX!!
  const { userKeys } = findReservedValue(
    [
      selectedStockListItem.ean,
      selectedStockListItem.dot,
      selectedStockListItem.branchId,
    ].join('_'),
    useWS(),
    user?.id
  );

  const handleHoverState = (state: string, index: number) => {
    if (!isEmpty(userKeys)) {
      if (state === 'enter') {
        hoverState[index] = true;
        setHoverState({ ...hoverState });
      } else {
        hoverState[index] = false;
        setHoverState({ ...hoverState });
      }
    }
  };

  return (
    <>
      {data.map((item, index) => {
        const url = `/dashboard/customer-orders?searchFilter=orderNumber&pageSize=20&page=1&selected=${item.customerOrderId}`;

        const reservedOnLocation = (
          item.lineItems as unknown as IWorkOrderLineItem[]
        )
          .filter((item) => item.ean === selectedStockListItem?.ean)
          .flatMap((item) => item.reservedOnLocation);

        return !hoverState[index] ? (
          <Wrapper>
            <DocumentDetailsWrapper
              style={{
                border: 0,
              }}
              key={item.id}
              onMouseEnter={() => handleHoverState('enter', index)}
              onMouseLeave={() => handleHoverState('leave', index)}
              onClick={() => window.open(url, '_blank')}
            >
              <ReservedWrapper>
                <ReservedTextWrapper>Reserved</ReservedTextWrapper>
                <ReservedNumberWrapper>
                  {getReservedQuantityByOrder(item)}
                </ReservedNumberWrapper>
              </ReservedWrapper>

              <Tooltip
                title={''}
                children={
                  <FileIconNameWrapper>
                    <img
                      src={noDocument}
                      alt="no document"
                      style={{ width: '20px', height: '21px' }}
                    />
                    <ReservedWrapper
                      style={{ alignItems: 'flex-start', gap: '5px' }}
                    >
                      <DocumentNameWrapper>Order</DocumentNameWrapper>
                      <DocumentReferenceWrapper>
                        {item.orderNumber}{' '}
                        <span
                          style={{
                            color: tyrioUI.colors.black.B70,
                            fontSize: '14px',
                          }}
                        >
                          ({item.erpOrderId})
                        </span>
                      </DocumentReferenceWrapper>
                    </ReservedWrapper>
                  </FileIconNameWrapper>
                }
              />
            </DocumentDetailsWrapper>

            {reservedOnLocation && !_.isEmpty(reservedOnLocation) && (
              <LocationsWrapper>
                {(reservedOnLocation ?? []).map((item) => (
                  <ReservedOnLocationWrapper>
                    <Typography variant="subtitle2" color="text.secondary">
                      Location:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item?.qrCode}
                    </Typography>

                    <Typography variant="subtitle2" color="text.secondary">
                      Reserved quantity:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item?.reserved}
                    </Typography>
                  </ReservedOnLocationWrapper>
                ))}
              </LocationsWrapper>
            )}
          </Wrapper>
        ) : (
          <CartReservedItems
            reserved={getReservedQuantityByOrder(item)}
            handleHoverState={handleHoverState}
            index={index}
            userIds={userKeys as string[]}
          />
        );
      })}
    </>
  );
};

export const Wrapper = styled.div`
  border: 1px solid #d2d4d2;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const ReservedOnLocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-end;
`;

export const LocationsWrapper = styled.div`
  padding: 22px;
  border-top: 1px solid #d2d4d2;
`;
