export interface IOutboundOrderItems {
  branchCity: string;
  branchId: number;
  branchName: string;
  lineItems: Record<string, string | number | null>[];
  stockLocationId: string;
  warehouseId: number;
}

export const formDataForCart = (outboundOrderItems: IOutboundOrderItems[]) => {
  const send = outboundOrderItems.map((outbound) => {
    const { branchId, branchName, branchCity, warehouseId } = outbound;
    const key = [branchId, branchName, branchCity, warehouseId].join('_');

    const lineItems = outbound.lineItems.map(
      (singleItem: Record<string, string | number | null>) => {
        const key = [
          singleItem['ean'],
          singleItem['dot'],
          singleItem['branchId'],
        ].join('_');

        const obj = {
          [key]: {
            quantity: singleItem['quantity'],
            ean: singleItem['ean'],
            ipc: singleItem['manufacturerCode'],
            sku: singleItem['sku'],
            dot: singleItem['dot'],
            productionYear: singleItem['productionYear'],
            productName: singleItem['productName'],
            uid: singleItem['uid'],
            stockListItemId: singleItem['stockListItemId'],
            createdAt: new Date(),
            calculatedPrice: singleItem['calculatedPrice'] ?? {},
            brand: singleItem['brand'] ?? '',
          },
        };

        return obj;
      }
    );

    const orderItem = {
      [key]: Object.assign({}, ...lineItems),
    };
    return orderItem;
  });

  return Object.assign({}, ...send);
};
