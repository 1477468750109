import { tyrioIcons } from '@tyrio/ui-library';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import {
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import api from '@tyrio/api-factory';
import {
  ClientShippingCourierApi,
  ClientShippingResponse,
  ShippingCourierApi,
} from '@tyrio/dto';
import { DBClientShippingCourier, DBShippingCourier } from '@prisma/client';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useAddShippingCouriers } from '../../../features/shipping-couriers/query/create';

interface ShippingCourierNewProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}

const ShippingCourierNew = ({
  setIsModalOpen,
  isModalOpen,
}: ShippingCourierNewProps) => {
  const [selectedCouriers, setSelectedCouriers] = useState<string[]>([]);
  const queryClient = useQueryClient();

  const onClose = () => {
    setIsModalOpen(false);
  };

  const {
    data: shippingCouriersData,
    isFetching: isShippingCouriersDataFetching,
  } = useQuery(['get_all_shipping_couriers'], () =>
    api.fetch<ShippingCourierApi['list']>('get_all_shipping_couriers')
  );

  const { data: clientsCouriers, isFetching: isClientsCouriersFetching } =
    useQuery(['get_client_shipping_couriers'], () =>
      api.fetch<ClientShippingCourierApi['list']>(
        'get_client_shipping_couriers'
      )
    );

  const couriers = shippingCouriersData
    ?.map((courier: DBShippingCourier) => {
      const findCourier = clientsCouriers?.find(
        (clientCourier: DBClientShippingCourier) =>
          courier.id === clientCourier.shippingCourierId
      );
      if (!findCourier) {
        return {
          id: courier.id,
          name: courier.abbreviation,
          imgSrc:
            courier.abbreviation === 'DPD Croatia' ||
            courier.abbreviation === 'DPD Croatia (Dropship)'
              ? tyrioIcons.dpd
              : courier.abbreviation === 'GLS Croatia' ||
                courier.abbreviation === 'GLS Croatia (Dropship)'
              ? tyrioIcons.gls
              : tyrioIcons.gebruderWeiss,
        };
      } else return null;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((item: any) => item);

  useEffect(() => {
    if (
      !isClientsCouriersFetching &&
      clientsCouriers &&
      clientsCouriers.length > 0
    ) {
      const data = clientsCouriers.map(
        (item: ClientShippingResponse) => item.shippingCourier.id
      );
      setSelectedCouriers([...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClientsCouriersFetching]);

  const { createShippingCourier } = useAddShippingCouriers(
    queryClient,
    setIsModalOpen
  );
  const onConfirm = () => {
    const newCouriers = selectedCouriers.filter((courier: string) => {
      return clientsCouriers?.find(
        (courierItem) => courierItem.shippingCourierId !== courier
      );
    });

    if (clientsCouriers?.length === 0) createShippingCourier(selectedCouriers);
    else createShippingCourier(newCouriers);
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={isModalOpen}
      onClose={onClose}
    >
      <ModalWrapper>
        <ModalContentWrapper>
          <ModalContent>
            <ModalHeader>
              <ModalHeaderText>Shipping Couriers</ModalHeaderText>
              <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
            </ModalHeader>
            <Divider light />
            <Grid
              sx={{
                padding: '20px',
              }}
            >
              {isShippingCouriersDataFetching ? (
                <Grid
                  sx={{
                    paddingTop: '25%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid>
                  <Grid
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                      marginBottom: '40px',
                    }}
                  >
                    {couriers?.map((item) => (
                      <ShippingCard
                        isSelected={selectedCouriers.includes(
                          item?.id as string
                        )}
                        onClick={() => {
                          const activeCourier = clientsCouriers?.find(
                            (el) => el.shippingCourierId === item?.id
                          );
                          if (!activeCourier) {
                            const newData = [...selectedCouriers];
                            const index = selectedCouriers.indexOf(
                              item?.id as string
                            );
                            if (index === -1) {
                              newData.push(item?.id as string);
                            } else {
                              newData.splice(index, 1);
                            }
                            setSelectedCouriers(newData);
                          }
                        }}
                      >
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'center',
                          }}
                        >
                          <CompanyLogoImage
                            src={item?.imgSrc}
                            alt={item?.name}
                          />

                          <Typography
                            sx={{
                              fontStyle: 'normal',
                              fontWeight: '600',
                              fontSize: '18px',
                              lineHeight: '28px',
                              letterSpacing: '0.3px',
                            }}
                          >
                            {item?.name}
                          </Typography>
                        </Grid>
                      </ShippingCard>
                    ))}
                  </Grid>
                  <Grid
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginBottom: '0',
                      marginTop: 'auto',
                    }}
                  >
                    <Button color="error" onClick={onClose}>
                      CANCEL
                    </Button>
                    <Button
                      color="info"
                      disabled={
                        selectedCouriers.length === 0 ||
                        selectedCouriers.length === clientsCouriers?.length
                      }
                      onClick={onConfirm}
                    >
                      CONFIRM
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </ModalContent>
        </ModalContentWrapper>
      </ModalWrapper>
    </Modal>
  );
};

const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;
const ShippingCard = styled.div<{
  isSelected: boolean;
}>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 145px;
  background: ${(props) => (props.isSelected ? '#F0FAF4' : '#FFFFFF')};
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  padding: 16px;
  opacity: ${(props) => (props.isSelected ? 'unset' : '0.5')};
  &:hover {
    border-color: ${(props) => props.isSelected && '#b8bbbf'};
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  background-color: #fefefe;
  margin: auto;
  max-width: 600px;
  width: 100%;
  max-height: 500px;
  box-shadow: 0px 25px 50px rgba(100, 116, 139, 0.25);
  border-radius: 16px;
`;

const ModalContent = styled.div`
  width: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const ModalHeaderText = styled.span`
  font-sx: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #212b36;
`;

const CompanyLogoImage = styled.img`
  width: 120px;
`;
export default ShippingCourierNew;
