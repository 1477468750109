import styled from '@emotion/styled';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { DBBranch, DBClientStockList, DBProduct } from '@prisma/client';
import api from '@tyrio/api-factory';
import { tyrioUI } from '@tyrio/ui-library';
import { WmsIcon, wmsIcons } from '@tyrio/wms-ui-library';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { StockListContext } from '../../../../context/StockListContext';
import { useWS } from '../../../../context/WSContext';
import { CodeSearchType } from '../../../../pages/price-compare/types';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';

interface ImportQtyKey {
  key: string;
  qty?: number;
}

type StockListResponse = DBClientStockList & {
  branch: DBBranch;
  product: DBProduct;
};

export const PasteList = () => {
  const ws = useWS();

  const { filterValues, setFilterValue } = useContext(StockListContext);

  const getStockListItems = useMutation(
    (payload: string[]) =>
      api.fetch<{
        request: {
          body: { eans: string[]; warehouseId: number };
        };
        requestBody: {
          body: { eans: string[]; warehouseId: number };
        };
        response: StockListResponse[];
      }>('stockByEanCodes', {
        body: {
          eans: payload,
          warehouseId: filterValues ? Number(filterValues['warehouseId']) : 0,
        },
      }),
    {
      mutationKey: 'stockByEanCodes',
      onSuccess: (data) => {
        const send = addProductToCartFromPasteList(data);
        ws.socket?.emit('add-to-cart-from-paste-list', {
          items: send,
        });
      },
    }
  );

  // VARIABLES
  const [listItems, setListItems] = useState<ImportQtyKey[]>([]);
  const [items, setItems] = useState<ImportQtyKey[]>([]);
  const [inputValues, setInputValues] = useState('');
  const [selectedType, setSelectedType] = useState<CodeSearchType>('ean');
  const [searchText, setSearchText] = useState('');

  const [missingKeys, _setMissingKeys] = useState<string[]>([]);

  //   FUNCTIONS
  const handleSubmit = () => {
    const splitInput = inputValues.split('\n');
    const lines = splitInput.map((line): ImportQtyKey => {
      const keys = line.split('\t');

      return {
        key: keys[0]?.trim(),
        qty: parseInt(keys[1]?.trim(), 10),
      };
    });

    const validOutputs: ImportQtyKey[] = [];
    const invalidOutputs: ImportQtyKey[] = [];

    lines.forEach((line) => {
      if (
        // Check Key
        !!line.key &&
        line.key.length > 3 &&
        // Check qty
        !!line.qty &&
        !isNaN(line.qty) &&
        line.qty > 0
      ) {
        validOutputs.push(line);
      } else {
        invalidOutputs.push(line);
      }
    });
    setListItems(validOutputs);
  };

  const getRequestedQuantity = (ean: string) => {
    return items.find((item) => ean === item.key)?.qty;
  };

  const addProductToCartFromPasteList = (items: StockListResponse[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const arr: any = [];
    items.forEach((singleItem: StockListResponse) => {
      const warehouseId = filterValues
        ? Number(filterValues['warehouseId'])
        : 0;

      const branch_key =
        singleItem.branchId +
        '_' +
        singleItem.branch.branchName +
        '_' +
        singleItem.branch.city +
        '_' +
        warehouseId;

      const key =
        singleItem['ean'] +
        '_' +
        singleItem['dot'] +
        '_' +
        singleItem['branchId'];

      const obj = {
        [key]: {
          quantity: getRequestedQuantity(singleItem['ean']),
          ean: singleItem['ean'],
          ipc: singleItem['ipc'],
          sku: singleItem['sku'],
          dot: singleItem['dot'],
          productionYear: singleItem['productionYear'],
          productName: singleItem.product.productName,
          stockListItemId: singleItem['id'],
          createdAt: new Date(),
          brand: '',
        },
      };

      const final = {
        [branch_key]: Object.assign({}, obj),
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const has = arr.find((a: any) => a[branch_key]);
      if (has === undefined) arr.push(final);
      else {
        has[branch_key] = { ...has[branch_key], ...obj };
      }
    });

    return Object.assign({}, ...arr);
  };

  const handleAddToCart = async () => {
    const eanList = items.map((item) => item.key);
    getStockListItems.mutateAsync(eanList);
  };

  //   HOOKS
  useEffect(() => {
    if (searchText !== '')
      setItems(listItems.filter((f) => f.key.includes(searchText)));
    else setItems(listItems);
  }, [searchText, listItems]);

  //   COMPONENTS
  const renderRadioButtons = () => {
    return (
      <FormControl sx={{ padding: '0 11px 27px 11px' }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => {
            setSelectedType(e.target.value as CodeSearchType);
          }}
          value={selectedType}
        >
          <FormControlLabel
            value="ean"
            control={<Radio color="info" />}
            label="EAN"
          />
          <FormControlLabel
            value="manufacturerCode"
            control={<Radio color="info" />}
            label="IPC/CAI"
          />
          <FormControlLabel
            value="sku"
            control={<Radio color="info" />}
            label="SKU"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const renderBulkTextField = () => {
    return (
      <TextField
        multiline
        rows={4}
        placeholder="Bulk paste from Excel or Tyrio document"
        helperText="Press ENTER to submit"
        onChange={(e) => setInputValues(e.target.value)}
        sx={{ minHeight: '100px', overflow: 'scroll' }}
        inputProps={{
          style: {
            color: 'black',
          },
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) handleSubmit();
        }}
      />
    );
  };

  const renderSingleItem = (
    singleItem: ImportQtyKey,
    deleteOneItem: (a: number) => void,
    index: number
  ) => {
    const { key, qty: quantity } = singleItem;

    const hasBorder = missingKeys.includes(key);

    return (
      <SingleItemWrapper key={index} border={hasBorder}>
        <Typography
          fontSize={16}
          sx={{ maxWidth: 'calc(100% - 105px)', width: '100%' }}
        >
          {key}
        </Typography>
        <div style={{ maxWidth: '105px', width: '100%' }}>
          <StyledInput
            value={quantity}
            inputProps={{
              disableunderline: true,
            }}
            disableUnderline={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              items[index].qty = Number(e.target.value);
              setItems([...items]);
            }}
          ></StyledInput>
          <WmsIconStyled
            icon={wmsIcons.close}
            sx={{ cursor: 'pointer' }}
            onClick={() => deleteOneItem(index)}
          ></WmsIconStyled>
        </div>
      </SingleItemWrapper>
    );
  };

  const renderItems = () => {
    const deleteAll = () => {
      setListItems([]);
    };

    const deleteOneItem = (index: number) => {
      listItems.splice(index, 1);
      setListItems((items) => {
        return [...items];
      });
    };

    return (
      <ItemsWrapper>
        <TextField
          variant="standard"
          label="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ maxWidth: '220px' }}
        />

        {items.map((item: ImportQtyKey, index: number) =>
          renderSingleItem(item, deleteOneItem, index)
        )}

        {items.length > 0 && (
          <ButtonWrapper>
            <Button
              startIcon={
                <WmsIcon icon={wmsIcons.close} color="error"></WmsIcon>
              }
              variant="outlined"
              color="error"
              sx={{ height: '30px' }}
              onClick={deleteAll}
            >
              Delete all
            </Button>
          </ButtonWrapper>
        )}
      </ItemsWrapper>
    );
  };

  const renderChild = () => {
    return (
      <div
        style={{
          padding: '0 12px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ height: 'calc(100% - 100px)' }}>
          {renderRadioButtons()}
          {renderBulkTextField()}
          {renderItems()}
        </div>

        <div
          style={{
            height: '90px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            background: 'white',
            position: 'sticky',
            bottom: 0,
          }}
        >
          <Divider
            variant="fullWidth"
            sx={{
              borderWidth: '2px',
              margin: '24px 0 24px 0',
              borderColor: '#E6E8F0',
            }}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ height: '60px', width: '200px' }}
              onClick={() => {
                const eanList = items.map((item) => item.key);
                setFilterValue({ eanList: eanList });
              }}
            >
              GENERATE LIST
            </Button>

            <Button
              variant="contained"
              color="info"
              sx={{ height: '60px', width: '200px' }}
              onClick={handleAddToCart}
              disableElevation
            >
              ADD TO CART
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return <SideMenuLayout type="pasteList" children={renderChild()} />;
};

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 14px 20px 14px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  gap: 12px;
  margin-top: 46px;
  height: calc(100% - 263px);
  overflow: scroll;
`;

export const SingleItemWrapper = styled.div<{
  border: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  gap: 8px;
  background: ${tyrioUI.colors.black.B20};
  align-items: center;
  border: ${(props) => (props.border ? '1px solid #db1e1e' : 'none')};
  border-radius: ${(props) => (props.border ? '8px' : 'none')};

  &:hover {
    background: #e4f5fe;
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
  }
`;

export const WmsIconStyled = styled(WmsIcon)`
  cursor: pointer;
  color: #b9c4ce;
  &:hover {
    color: #db1e1e;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledInput = styled(Input)`
  width: 70px;

  .MuiInput-input {
    text-align: center;
  }

  .MuiInput-input:focus {
    background: white;
  }
`;
