import { DBCustomerOrderStatusEnum, InProcessSubStatuses } from '@tyrio/dto';
import { getDropdownLabel } from '../../../../helpers/enum-parser';
import { CustomerOrderStatusColors } from '../../features/purchase-orders/helpers/generate-color';
import {
  CenterStatus,
  OrderStatus,
} from '../../features/purchase-orders/PurchaseOrderTable';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

interface OrderStatusProps {
  status: DBCustomerOrderStatusEnum;
  subStatus: InProcessSubStatuses;
  hasTracking?: boolean;
}

export const OrdersStatus = ({
  status,
  subStatus,
  hasTracking,
}: OrderStatusProps) => {
  return (
    <CenterStatus>
      <OrderStatus
        color={CustomerOrderStatusColors[status]}
        textColor={status === 'NEW' ? '#919EAB' : 'white'}
        border={status === 'NEW' ? true : false}
      >
        {getDropdownLabel(DBCustomerOrderStatusEnum, status).toUpperCase()}
        {status === 'IN_PROCESS' &&
          subStatus === InProcessSubStatuses.Ready_for_shipping &&
          !hasTracking && <PriorityHighIcon fontSize="small" />}
      </OrderStatus>
    </CenterStatus>
  );
};
