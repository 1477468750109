import styled from '@emotion/styled/macro';
import _ from 'lodash';

import { HeaderText } from '../../../../purchase-orders/PurchaseOrderOverview';
import { useFilter } from '../../../../../hooks/useFilter';

interface BrandSelectorProps {
  label: string;
  favoriteValues: string[];
  isMotoLabelStyle: boolean;
}

export const BrandSelector = ({
  label,
  favoriteValues,
  isMotoLabelStyle = false,
}: BrandSelectorProps) => {
  const { ...filtersHook } = useFilter();

  const get = (path: string) =>
    _.get(filtersHook.filterValues, path, []) as string[];

  const initialSelectedValues: string[] = get('brand');

  let selectedValues = initialSelectedValues;

  const handleSelectValue = (isSelected: boolean, checkboxKey: string) => {
    if (isSelected) {
      selectedValues.push(checkboxKey);
    } else selectedValues = selectedValues.filter((el) => el !== checkboxKey);

    filtersHook.setFilterValue({
      ...filtersHook.filterValues,
      brand: selectedValues,
    });
  };

  return (
    <Container>
      <HeaderText
        style={
          isMotoLabelStyle
            ? {
                fontSize: '16px',
                color: '#919eab',
                fontWeight: 400,
                marginTop: '16px',
              }
            : {}
        }
      >
        {label}
      </HeaderText>

      <CardWrapper>
        {favoriteValues.map((value, key) => {
          const isSelected = selectedValues.includes(value);

          return (
            <Card
              onClick={() => {
                handleSelectValue(!isSelected, value);
              }}
              selected={isSelected}
              key={key}
            >
              <StyledCheckbox
                type="checkbox"
                key={`checkbox_${value}`}
                checked={isSelected}
                onChange={() => null}
                style={{ marginRight: '10px' }}
              />
              {value}
            </Card>
          );
        })}
      </CardWrapper>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  // gap: 6px;
  padding: 5px;
`;

export const Card = styled.button<{
  selected: boolean;
  width?: number;
}>`
  width: ${(props) => (props.width ? props.width + 'px' : '270px')};
  display: flex;
  align-items: center;
  height: 70px;
  border-radius: 8px;
  margin-top: 16px;
  padding: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  background: ${(props) =>
    props.selected ? 'rgba(25, 118, 210, 0.15)' : 'none'};
  border: ${(props) =>
    props.selected ? '2px solid #1976d2' : '1px solid #dfe3e8'};

  &:hover {
    border: 2px solid #1976d2;
  }
`;

export const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
`;
