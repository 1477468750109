import moment from 'moment';

export function getCurrentDate(): string {
  const currentDate = new Date();
  return (
    currentDate.getDate() +
    '.' +
    (currentDate.getMonth() + 1) +
    '.' +
    currentDate.getFullYear() +
    ' - ' +
    currentDate.getHours() +
    ':' +
    currentDate.getMinutes() +
    ':' +
    currentDate.getSeconds()
  );
}

export const getDaysDifference = (date: Date) => {
  return moment(date).diff(moment(new Date()), 'days');
};

export const formatDate = (date: Date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatDateAndTime = (date: Date) => {
  return moment(date).format('DD.MM.YYYY - HH:mm');
};

export const formatEUDate = (date: Date) => {
  return moment(date).format('DD.MM.YYYY');
};

export const formatEUDateAndTime = (date: Date) => {
  return moment(date).format('DD.MM.YYYY - H:mm:ss');
};
