import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import qs from 'qs';
import { useState } from 'react';

interface SearchInputProps {
  filterQuery?: qs.ParsedQs;
  setFilterQuery: (value: qs.ParsedQs) => void;
  options: { value: string; name: string }[];
}

export const SearchInput = ({
  filterQuery,
  setFilterQuery,
  options,
}: SearchInputProps) => {
  const [searchKeyword, setSearchKeyword] = useState(
    _.get(filterQuery, 'searchKeyword') ?? ''
  );
  const [searchType, setSearchType] = useState(
    _.get(filterQuery, 'searchType') ?? null
  );

  return (
    <Container>
      <FormControl sx={{ maxWidth: '200px' }}>
        <InputLabel>Search type</InputLabel>
        <Select
          label="Search type"
          sx={{ borderRadius: '8px 0 0 8px', borderRight: 0 }}
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
        >
          {options.map((m) => (
            <MenuItem value={m.value} key={m.value}>
              {m.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        disabled={searchType === null}
        onChange={(e) => setSearchKeyword(e.target.value)}
        placeholder="Search..."
        value={searchKeyword}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onKeyDown={(e: any) => {
          if (e.keyCode === 13) {
            setFilterQuery({
              searchKeyword: searchKeyword === '' ? undefined : searchKeyword,
              searchType: searchKeyword === '' ? undefined : searchType ?? '',
            });
          }
        }}
        InputProps={{
          style: {
            borderRadius: '0 8px 8px 0',
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setFilterQuery({
                    searchKeyword:
                      searchKeyword === '' ? undefined : searchKeyword,
                    searchType:
                      searchKeyword === '' ? undefined : searchType ?? '',
                  });
                }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5px;
`;
