import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TOKEN } from '@tyrio/api-factory';
import { useAuth } from './AuthContext';

import { AdminWSState } from '@tyrio/dto';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io/dist/typed-events';
import { appConfig } from '@tyrio/config';

interface WSAdminContextShape {
  state: AdminWSState;
  ping: () => void;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | null;
}

export const WSAdminContext = React.createContext<WSAdminContextShape>({
  state: {
    admin: {},
    client: {},
  },
  ping: () => null,
  socket: null,
});

const useWSContextData = (): WSAdminContextShape => {
  const { user } = useAuth();
  const [state, setState] = useState<AdminWSState>({
    admin: {},
    client: {},
  });

  // 1. Initialize the socket only once (autoConnect disabled to control it manually).
  const socketRef = useRef<Socket | null>(null);
  if (!socketRef.current) {
    socketRef.current = io(appConfig.wsUrl, {
      autoConnect: false,
    });
  }
  const socket = socketRef.current;

  // 2. Set up all event listeners once, and handle cleanup.
  useEffect(() => {
    if (!socket) return;

    // Handlers
    const handleConnectError = (err: Error) => {
      console.log(`connect_error due to ${err.message}`);
    };

    const handleConnect = () => {
      // Identify as soon as the socket connects
      socket.emit('identify', { token: TOKEN.get() });
    };

    const handleData = (newState: AdminWSState) => {
      setState(newState);
    };

    // Attach listeners
    socket.on('connect_error', handleConnectError);
    socket.on('connect', handleConnect);
    socket.on('data', handleData);

    // Cleanup listeners on unmount or re-run
    return () => {
      socket.off('connect_error', handleConnectError);
      socket.off('connect', handleConnect);
      socket.off('data', handleData);
    };
  }, [socket]);

  // 3. Connect or disconnect depending on whether the user is present
  useEffect(() => {
    if (!socket) return;

    if (user && !socket.connected) {
      socket.connect();
    } else if (!user && socket.connected) {
      socket.disconnect();
    }
  }, [user, socket]);

  // Ping method
  const ping = useCallback(() => {
    if (socket) {
      socket.emit('ping');
    }
  }, [socket]);

  return {
    state,
    ping,
    socket,
  };
};

export const WSAdminProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const data = useWSContextData();
  return (
    <WSAdminContext.Provider value={data}>{children}</WSAdminContext.Provider>
  );
};

export const useWSAdmin = () => useContext(WSAdminContext);
