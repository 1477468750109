/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from '@mui/material';
import { DBBranchType, DBDeliveryTypes, DBDeliveryZone } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBBranchesApi } from '@tyrio/dto';
import { TyrioInputPhoneString, TyrioSelectInputOption } from '@tyrio/forms';
import { startCase } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { parseEnumToArray } from '../../../../../helpers/enum-parser';
import {
  getAutocompleteSelectChip,
  getAutocompleteSingleField,
  getTextField,
} from '../../form-helper';
import { DropdownItems } from '../../price-calculation/queries/get-rebates';
import FormSectionWithTitle from '../../products/pages/components/FormSectionWithTitle';

interface MainBranchesTabProps {
  branchId?: string;
  errors: any;
  register: any;
  control: any;
  selectedCountryId: number;
  shouldDisableField: (key: string) => boolean;
  rebatesData: DropdownItems[];
  countries: TyrioSelectInputOption[];
  deliveryMethods:
    | (DBDeliveryTypes[] & {
        zones?: DBDeliveryZone[];
      })
    | undefined;
}

const BRANCH_TYPES = parseEnumToArray(DBBranchType);

const MainBranchesTab = ({
  branchId,
  errors,
  register,
  control,
  selectedCountryId,
  shouldDisableField,
  rebatesData,
  countries,
  deliveryMethods,
}: MainBranchesTabProps) => {
  const { t } = useTranslation();
  const [branches, setBranches] = useState<TyrioSelectInputOption[]>([]);

  useQuery(
    ['get_branches'],
    async () => {
      return await api.fetch<DBBranchesApi['list']>('get_branches', {
        search: '',
      });
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data.data
          .filter((item) => item.branchType.includes(DBBranchType.WAREHOUSE))
          .map((item) => ({ label: item.branchName, value: item.id }));

        setBranches(dropdownItems);
      },
    }
  );

  const findCountryCode = () => {
    const findCountry = countries.find((f) => f.value === selectedCountryId);
    if (findCountry !== undefined)
      return findCountry?.code?.toLocaleLowerCase();
    else return 'ba';
  };

  return (
    <div>
      <Grid container spacing={2}>
        {branchId &&
          getTextField(
            t('Branch ID'),
            'branchId',
            () => true,
            errors,
            register,
            { lg: 3, xs: 12 },
            'auto generated - unique values',
            { disabled: true }
          )}

        {getTextField(
          t('Branch Name'),
          'branchName',
          () => true,
          errors,
          register,
          { lg: branchId ? 3 : 4, xs: 12 },
          'ex: Poslovnica SPLIT',
          {}
        )}

        {getTextField(
          t('Short Name'),
          'shortName',
          () => true,
          errors,
          register,
          { lg: branchId ? 3 : 4, xs: 12 }
        )}

        {getTextField(
          t('Sort Order'),
          'sortOrder',
          () => true,
          errors,
          register,
          { lg: branchId ? 3 : 4, xs: 12 }
        )}

        {getAutocompleteSelectChip(
          t('Branch Type'),
          'branchType',
          () => true,
          errors,
          control,
          { lg: 8, xs: 12 },
          BRANCH_TYPES.map((item) => startCase(item.value.toString()))
        )}

        {getAutocompleteSingleField(
          t('Main warehouse'),
          'mainWarehouse',
          () => false,
          errors,
          control,
          { lg: 4, xs: 12 },
          {},
          branches,
          shouldDisableField('mainWarehouse')
        )}

        {getAutocompleteSingleField(
          t('Country'),
          'country',
          () => false,
          errors,
          control,
          { lg: 5, xs: 12 },
          {},
          countries
        )}

        {getTextField(
          t('Zip code'),
          'zipCode',
          () => true,
          errors,
          register,
          { lg: 3, xs: 12 },
          errors['zipCode']
        )}

        {getTextField(
          t('City'),
          'city',
          () => true,
          errors,
          register,
          { lg: 4, xs: 12 },
          errors['city']
        )}

        {getTextField(
          t('Address'),
          'address',
          () => true,
          errors,
          register,
          { lg: 4, xs: 12 },
          errors['address']
        )}

        {getTextField(
          t('E-mail'),
          'email',
          () => false,
          errors,
          register,
          { lg: 4, xs: 12 },
          errors['email'],
          { required: false }
        )}

        {getTextField(
          t('Web site'),
          'webSite',
          () => false,
          errors,
          register,
          { lg: 4, xs: 12 },
          errors['webSite']
        )}

        {
          <TyrioInputPhoneString
            item={{
              label: t('Business Phone'),
              disabled: false,
              type: 'input.phone',
              id: 'businessPhone',
              width: { lg: 4, xs: 12 },
            }}
            id={'businessPhone'}
            gridProps={{ lg: 6, xs: 12 }}
            control={control}
            error={errors['businessPhone']}
            register={register}
            defaultCode={findCountryCode()}
          />
        }

        {branchId &&
          getTextField(t('Erp ID'), 'erpId', () => true, errors, register, {
            lg: 6,
            xs: 12,
          })}

        {getAutocompleteSingleField(
          t('Rebate group'),
          'rebateGroup',
          () => false,
          errors,
          control,
          { lg: 6, xs: 12 },
          {},
          rebatesData
        )}

        {getAutocompleteSingleField(
          t('Default transfer order delivery method'),
          'defaultDelivery',
          () => false,
          errors,
          control,
          { lg: 6, xs: 12 },
          {},
          deliveryMethods
            ?.filter((item) => item.isActive)
            .map((item) => ({ label: item.name, value: item.id })) ?? []
        )}

        <Grid item lg={12} xs={12}>
          <FormSectionWithTitle label={t('Responsible person')} />
        </Grid>

        {getTextField(
          t('Job title'),
          'jobTitle',
          () => true,
          errors,
          register,
          { lg: 4, xs: 12 },
          errors['jobTitle']
        )}

        {getTextField(
          t('First name'),
          'firstName',
          () => true,
          errors,
          register,
          { lg: 4, xs: 12 },
          errors['firstName']
        )}

        {getTextField(
          t('Last name'),
          'lastName',
          () => true,
          errors,
          register,
          { lg: 4, xs: 12 },
          errors['lastName']
        )}

        {getTextField(
          t('E-mail'),
          'mail',
          () => false,
          errors,
          register,
          { lg: 4, xs: 12 },
          errors['mail']
        )}

        {
          <TyrioInputPhoneString
            item={{
              label: t('Business Phone'),
              disabled: false,
              type: 'input.phone',
              id: 'userBusinessPhone',
              width: { lg: 4, xs: 12 },
            }}
            id={'userBusinessPhone'}
            gridProps={{ lg: 4, xs: 12 }}
            control={control}
            error={errors['userBusinessPhone']}
            register={register}
            defaultCode={findCountryCode()}
          />
        }

        {
          <TyrioInputPhoneString
            item={{
              label: t('Mobile Phone'),
              disabled: false,
              type: 'input.phone',
              id: 'userMobilePhone',
              width: { lg: 4, xs: 12 },
            }}
            id={'userMobilePhone'}
            gridProps={{ lg: 4, xs: 12 }}
            control={control}
            error={errors['userMobilePhone']}
            register={register}
            defaultCode={findCountryCode()}
          />
        }
      </Grid>
    </div>
  );
};

export default MainBranchesTab;
