import styled from '@emotion/styled/macro';
import HttpsIcon from '@mui/icons-material/Https';
import {
  Avatar,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { DBPaymentStatusEnum } from '@prisma/client';
import {
  CustomerPayload,
  DBCustomerOrderItemResponse,
  SSE_ENTITY_KEYS,
} from '@tyrio/dto';
import { tyrioUI } from '@tyrio/ui-library';
import _, { startCase } from 'lodash';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { OrdersStatus } from '../../components/Orders/OrdersStatus';
import { useAuth } from '../../context/AuthContext';
import { useWS } from '../../context/WSContext';
import {
  CellDetails,
  NumberTableCell,
} from '../purchase-orders/PurchaseOrderTable';
import { ArrowForward } from '@mui/icons-material';
enum SelectedProductStatuses {
  FAVORITE,
  SELECTED,
  NORMAL,
}

interface CustomerOrderTableProps {
  data: DBCustomerOrderItemResponse[];
  selectedOrderData: DBCustomerOrderItemResponse | null;
  setSelectedOrder: (val: DBCustomerOrderItemResponse | null) => void;
  isDirty: boolean;
  setIsDirty: (a: boolean) => void;
  isUploadInProcess: boolean;
  filters: qs.ParsedQs;
}

const CustomerOrderTable = ({
  data,
  setSelectedOrder,
  isDirty,
  setIsDirty,
  isUploadInProcess,
  filters,
}: CustomerOrderTableProps) => {
  const { user } = useAuth();
  const { state, socket } = useWS();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();

  window.addEventListener('beforeunload', () => {
    closeOrder();
  });

  function closeOrder() {
    const entity =
      state.locks &&
      state.locks[`${SSE_ENTITY_KEYS.CUSTOMER_ORDER}/${filters['selected']}`];

    const lockedBy = entity?.userId;

    if (lockedBy === user?.id) {
      setSelectedOrder(null);
      socket?.emit('unlock', {
        entityName: SSE_ENTITY_KEYS.CUSTOMER_ORDER,
        entityId: filters['selected'],
      });
    }
  }

  const openOrderDetails = (order: DBCustomerOrderItemResponse) => {
    if (isDirty) {
      const feedback = window.confirm(
        'You have unsaved changes. Are you sure you want to continue?'
      );
      if (feedback) {
        setSelectedOrder(order);
        setIsDirty(false);
      }
    } else if (isUploadInProcess) {
      window.alert(`You can't switch orders while uploading documents!`);
    } else {
      if (order.id !== filters['selected']) {
        setSelectedOrder(order);
      }
    }
  };

  const isFavorite = (orderId: string) => {
    const order = data.find((item) => item.id === orderId);
    if (user) return order?.favoritedBy?.includes(user?.id);
    return false;
  };

  const getColor = (paymentType: DBPaymentStatusEnum) => {
    if (paymentType === DBPaymentStatusEnum.PENDING) return '#FFCC4D';
    if (paymentType === DBPaymentStatusEnum.NOT_PAID) return '#DB1E1E';
    if (paymentType === DBPaymentStatusEnum.PAID) return '#38A169';

    return '#919EAB';
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ marginTop: '20px', borderRadius: 0 }}
    >
      <Table aria-label="simple table" style={{ whiteSpace: 'nowrap' }}>
        <TableHead style={{ position: 'sticky', top: 0, zIndex: 2 }}>
          <TableRow
            sx={{
              background: '#ECF0F4',
            }}
          >
            <NumberTableCell>Status</NumberTableCell>
            <TableCell>Order Number</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Payment</TableCell>
            <TableCell>Delivery</TableCell>
            <TableCell>Note</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((order) => {
            const lockEntity =
              state.locks &&
              state.locks[`${SSE_ENTITY_KEYS.CUSTOMER_ORDER}/${order.id}`];

            const customerFirstNameLastName = `${
              (order?.customer as unknown as CustomerPayload).firstName ?? ''
            } ${
              (order?.customer as unknown as CustomerPayload).lastName ?? ''
            }`;

            const customerCompanyInfo = (
              order?.customer as unknown as CustomerPayload
            )?.companyInfo;

            const customerTitle =
              customerCompanyInfo && customerCompanyInfo?.companyName !== ''
                ? customerCompanyInfo?.companyName
                : customerFirstNameLastName;

            const customerSubTitle =
              customerCompanyInfo &&
              customerCompanyInfo?.companyName !== '' &&
              customerFirstNameLastName;

            const delivery =
              order.delivery?.code === 'FIT'
                ? `${order.deliveryAddress?.city} - ${
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (order.deliveryAddress?.companyInfo as any)?.companyName
                  }`
                : order.deliveryAddress?.city;

            const status =
              params.get('selected') === order.id
                ? SelectedProductStatuses.SELECTED
                : isFavorite(order.id)
                ? SelectedProductStatuses.FAVORITE
                : SelectedProductStatuses.NORMAL;

            const orderCreatedAt = _.get(order, 'details.createdAt', '');

            const orderCreatedAtParsed = orderCreatedAt
              ? moment(orderCreatedAt).format('LLL')
              : moment(order.orderedAt).format('LLL');

            const shouldSetOpacity =
              lockEntity !== undefined && lockEntity !== null;

            const isButtonVisible =
              lockEntity === undefined ||
              lockEntity === null ||
              lockEntity?.userId === user?.id;

            return (
              <TableRowStyled
                status={status}
                onClick={() => openOrderDetails(order)}
                key={order?.id}
              >
                <StyledTableCell
                  opacity={shouldSetOpacity}
                  style={{ textAlign: 'center' }}
                >
                  <OrdersStatus
                    status={
                      JSON.parse(JSON.stringify(order?.orderStatus))?.status
                    }
                    subStatus={
                      JSON.parse(JSON.stringify(order?.orderStatus))?.subStatus
                    }
                    hasTracking={
                      !!order &&
                      !!order?.trackingInfoData &&
                      order?.trackingInfoData?.length > 0
                    }
                  />
                  <SubStatus>
                    {JSON.parse(JSON.stringify(order?.orderStatus))?.subStatus}
                  </SubStatus>
                </StyledTableCell>
                <StyledTableCell opacity={shouldSetOpacity}>
                  {_.get(order, 'details.number')}
                  <CellDetails style={{ color: 'grey' }}>
                    {orderCreatedAtParsed}
                  </CellDetails>
                </StyledTableCell>
                <StyledTableCell opacity={shouldSetOpacity}>
                  {customerTitle && customerTitle !== undefined
                    ? customerTitle
                    : ''}
                  <CellDetails style={{ color: 'grey' }}>
                    {customerSubTitle ? customerSubTitle : ''}
                  </CellDetails>
                </StyledTableCell>
                <StyledTableCell opacity={shouldSetOpacity}>
                  {order?.payment?.name}
                  <CellDetails style={{ color: getColor(order.paymentStatus) }}>
                    {startCase(order.paymentStatus)}
                  </CellDetails>
                </StyledTableCell>
                <StyledTableCell opacity={shouldSetOpacity}>
                  {order.delivery?.name}
                  <CellDetails>{delivery}</CellDetails>
                </StyledTableCell>
                <StyledTableCell opacity={shouldSetOpacity}>
                  {order.customerRemark}
                  <CellDetails style={{ color: 'red' }}>
                    {order.internalRemark}
                  </CellDetails>
                </StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" spacing={2} alignItems="center">
                    {order.assignedToId && (
                      <Tooltip
                        title={`Assigned to ${order.assignedTo?.firstName} ${order.assignedTo?.lastName}`}
                        placement="top"
                      >
                        <Avatar>{`${order.assignedTo?.firstName[0]}${order.assignedTo?.lastName[0]}`}</Avatar>
                      </Tooltip>
                    )}
                  </Stack>
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  onClick={(e) => {
                    if (isButtonVisible) {
                      e.stopPropagation();

                      const entity =
                        state.locks &&
                        state.locks[
                          `${SSE_ENTITY_KEYS.CUSTOMER_ORDER}/${order.id}`
                        ];

                      if (!entity)
                        socket?.emit('lock', {
                          entityName: SSE_ENTITY_KEYS.CUSTOMER_ORDER,
                          entityId: order.id,
                        });

                      history.push(`sales-orders/${order.id}`);
                    }
                  }}
                >
                  {isButtonVisible ? (
                    <IconButton>
                      <ArrowForward />
                    </IconButton>
                  ) : (
                    <Tooltip
                      title={`Locked by ${lockEntity?.firstName ?? ''}  ${
                        lockEntity?.lastName ?? ''
                      }`}
                    >
                      <HttpsIcon sx={{ height: '20px' }} color="error" />
                    </Tooltip>
                  )}
                </StyledTableCell>
              </TableRowStyled>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomerOrderTable;

export const TableRowStyled = styled(TableRow)<{
  status: SelectedProductStatuses;
}>`
  cursor: pointer;
  background: ${(props) =>
    props.status === SelectedProductStatuses.SELECTED
      ? tyrioUI.colors.blue.B10
      : props.status === SelectedProductStatuses.FAVORITE
      ? tyrioUI.colors.yellow.B10
      : 'white'};

  border-bottom: 1px solid
    ${(props) =>
      props.status === SelectedProductStatuses.SELECTED
        ? tyrioUI.colors.blue.B100
        : props.status === SelectedProductStatuses.FAVORITE
        ? tyrioUI.colors.yellow.B100
        : tyrioUI.colors.black.B40};

  &:hover {
    background: ${tyrioUI.colors.black.B10};
    border-bottom: 1.5px solid ${tyrioUI.colors.black.B40};
  }
`;

const StyledTableCell = styled(TableCell)<{ opacity?: boolean }>`
  border: 0;
  opacity: ${(props) => (props.opacity ? '0.3' : '1')};
  max-width: 250px;
  overflow-x: auto;
`;

const SubStatus = styled.p`
  margin: 5px 0 0 0;
  font-size: 12px;
  color: gray;
`;
