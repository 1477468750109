/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DBClientStockList,
  DBCustomerOrderItem,
  DBSupplierStock,
  DBWorkOrders,
  Prisma,
} from '@prisma/client';
import * as qs from 'qs';
import { DBBranchStockLineItems } from './branch-stock-list';
import { CheckPriceRes } from './price-calculation';
import { DBTransferOrderLineItem } from './transfer-order';
import { PosCartItem } from './sse';
import {
  CreateServiceReservationReq,
  CustomerOrderBillingAddress,
  CustomerOrderDeliveryAddress,
  CustomerOrderServices,
  CustomerPayload,
} from './customer-order-item';

export enum SetGenericOptions {
  ALL = 'all',
  SAME_AS_FRONT = 'same as front',
  SAME_OR_NEWER = '>= same or newer',
  SAME_OR_HIGHER = '>= same or higher',
  ALL_COMPOUNDS = 'all compounds',
}

export interface PosProductsRequest {
  categoryId?: string;
  brand?: string[];
  wheelPosition?: string[];
  specialMarking?: string;
  ean?: string;
  sku?: string;
  manufacturerCode?: string;
  modelName?: string;
  size?: string;
  productName?: string;
  supplierStockPage?: string;
  searchType?: string;
  searchKeyword?: string;
  season?: string;
  dimension?: string;
  customer?: string;
  subcategoryId?: string;
  formSets?: string; //true or false
  setRules?: any;
}

export interface PosProductResponse {
  productName: string;
  productName2: string;
  ean: string;
  sku: string;
  manufacturerCode: string;
  uid: string;
  wetGrip: string | null;
  rollingResistance: string | null;
  noisePerformance: number | null;
  width: string | null;
  height: string | null;
  size: string | null;
  weight: string | null;
  runFlat: boolean | null;
  seal: boolean | null;
  threepmfs: boolean | null;
  commMark: boolean | null;
  flank: boolean | null;
  silent: boolean | null;
  electric: boolean | null;
  rfd: string | null;
  specialMarking: string | null;
  euDirectiveNumber: string | null;
  rimDiameter: string | null;
  noiseClassType: string | null;
  origin?: string;
  pcd?: string[];
  productModelUid: string;
  oem?: boolean;
  iceGrip?: boolean;
  speedIndex?: string;
  model: {
    uid: string;
    modelName: string | null;
    season: string | null;
    brand: string | null;
    wheelPosition: string | null;
    photos: Prisma.JsonValue;
    sidewall?: string;
    category: {
      id: number;
      parent_category_id: number | null;
      name: string;
      slug: string;
      parent_category_name: string | null;
      taric_code: string;
    };
  };
  compound?: string | null;
  demo?: boolean | null;
  structure?: string | null;
  tubeTyre?: string | null;
}

export type PosItemStockType = 'CURRENT' | 'BRANCHES' | 'SUPPLIER';

export type RecommendedClass = 'PREMIUM' | 'MID' | 'ECONOMY';

export const RecommendedOrder = {
  PREMIUM: 0,
  MID: 1,
  ECONOMY: 2,
};

export interface PosItemSet {
  items: PosItem[];
  key: string;
}

export interface PosItem {
  id: string;
  lastUpdate: Date;
  product: PosProductResponse;
  price: number;
  cashPrice: number;
  quantity: number;
  stockType: PosItemStockType;
  isFavorite: boolean;
  features: {
    outletDot: string;
    sale: boolean;
    freeFitting: boolean;
    promo: boolean;
  };
  inclusiveVatPrice: number;
  vatCashPrice?: number | null;
  reserved: number;
  isRecommended: boolean;
  recommendedClass?: RecommendedClass;
}

export interface PosItemResponse {
  matchedSets: PosItemSet[];
  unmatchedItems: PosItem[];
}

export type PosLineItems = DBBranchStockLineItems & {
  price: number | null;
  calculatedPrice: Record<string, Partial<CheckPriceRes>>;
  cashPrice?: number | null;
  isOnSale?: boolean;
  oldPrice?: number;
  discount?: number;
  purchasePrice: number;
};

type Product = {
  product: {
    uid: string;
    productName: string;
    manufacturerCode: string;
    sku: string;
  };
};

export type BranchesStock = DBClientStockList &
  Product & {
    branch: {
      id: number;
      branchName: string;
      city: string;
    };
  };

export type BranchesStockResponse = {
  branchId: number;
  branchName: string;
  branchCity: string;
  deliveryDate: string;
  stockLocationId: string;
  lineItems: PosLineItems[];
};

interface DBBranchStockListOnePayload {
  ean?: string;
  warehouseId?: string;
  inWarehouse?: boolean;
  shouldShowDot?: boolean;
  customerId?: string;
}

export interface SupplierLineItems {
  ean: string;
  dot: string;
  price: number;
  cashPrice?: number | null;
  reserved: number;
  quantity: number;
  retailPrice: number;
  oldPrice?: number;
  isOnSale?: boolean;
  discount?: number;
  inclusiveVatPrice?: number;
  vatCashPrice?: number | null;
  purchasePrice: number;
}

export interface SupplierStockResponse {
  supplierName: string;
  supplierId: string;
  supplierSlug: string;
  deliveryDate: string;
  deliveryDateFrom: Date;
  deliveryDateTo: Date;
  lineItems: SupplierLineItems[];
}

export type SupplierStock = DBSupplierStock & {
  supplier: {
    companyOfficialName: string;
    companyShortName: string;
    slug: string;
  };
};

interface DBPosTypesApi {
  getBranchesStock: {
    requestBody: DBBranchStockListOnePayload;
    requestParams: DBBranchStockListOnePayload;
    request: DBBranchStockListOnePayload;
    response: BranchesStockResponse[];
  };
  getSuppliersStock: {
    requestBody: never;
    requestParams: never;
    request: {
      ean: string;
      sorting: string;
      warehouseId?: string;
      customerId: string;
    };
    response: SupplierStockResponse[];
  };
}

export interface PosProductsQuery {
  query: qs.ParsedQs;
}

export interface UpcomingOnStockPosResponse {
  branchId?: number | null | string;
  supplierId?: string | null;
  branchName: string;
  branchCity: string;
  deliveryDate?: Date | null | string;
  reserved?: number;
  available?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lineItems: DBTransferOrderLineItem[] | any[]; // TODO: fix this type!
}

export interface FavoritePosPayload {
  ean: string;
}

export type StockType =
  | 'ON STOCK'
  | 'UPCOMING ON STOCK'
  | 'BRANCHES STOCK'
  | 'SUPPLIERS STOCK'
  | 'SERVICES'
  | '';

export interface StockDetails {
  branchId: string;
  branchName: string;
  branchCity: string;
  lineItems: PosLineItems[];
}

export interface CartItems {
  branchId: number;
  items: PosCartItem[];
}

export interface SupplierStockItem {
  supplierId: string;
  supplierName: string;
  supplierSlug: string;
  items: PosCartItem[];
}

export type StockShape = CartItems | SupplierStockItem;

export interface SummaryShape {
  vatAmount: number;
  deliveryExVat: number;
  discountExVat: number;
  servicesExVat: number;
  subtotalExVat: number;
  grandTotalIncVat: number;
}

export interface VehicleShape {
  brand: string;
  model: string;
  year: string;
  licensePlateNumber: string;
  engineDisplacementCcm?: string;
  enginePower?: string;
  homologationNumber?: string;
  typeApprovalNumber?: string;
}

interface FinishOrderBody {
  branchesStock: StockShape[];
  myStock: StockShape[];
  supplierStock: StockShape[];
  delivery?: {
    code: string;
    deliveryRange?: string;
  };
  payment: {
    code: string;
  };
  appointments?: CreateServiceReservationReq[];
  services?: CustomerOrderServices[];
  mainWarehouseId: number; // it would be nice to send erpBranchId also
  customer?: CustomerPayload; //make sure this body from POS is the same as the one from shop!
  deliveryAddress?: CustomerOrderDeliveryAddress; //make sure this body from POS is the same as the one from shop!
  summary: SummaryShape;
  vehicleInfo?: VehicleShape;
  billingAddress?: CustomerOrderBillingAddress;
  createdBy: { firstName: string; lastName: string };
}

export interface InputOption {
  label: string;
  value: string | number;
}

interface PosApi {
  list: {
    requestParams: { warehouseId?: string };
    requestBody: PosProductsQuery;
    request: PosProductsQuery & { warehouseId?: string };
    response: PosItemResponse;
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  addFavorite: {
    requestBody: FavoritePosPayload;
    requestParams: FavoritePosPayload;
    request: FavoritePosPayload;
    response: PosItem;
  };
  finishOrder: {
    requestBody: FinishOrderBody;
    requestParams: never;
    request: FinishOrderBody;
    response: {
      response: DBCustomerOrderItem & {
        workOrders: DBWorkOrders[];
      };
      ritamResponse: string[];
    };
  };
  getFilters: {
    requestBody: qs.ParsedQs;
    requestParams: qs.ParsedQs;
    request: qs.ParsedQs;
    response: Record<string, string[] | InputOption[]>;
  };
}

export const defaultDot = 'XXXX';

export { DBPosTypesApi, PosApi };
