import React, { SyntheticEvent } from 'react';
import { Controller, Control } from 'react-hook-form';
import {
  Autocomplete,
  TextField,
  Checkbox,
  AutocompleteChangeReason,
} from '@mui/material';

interface InputProps {
  control: Control;
  id: string;
  title: string;
  data: Record<string, string[]>; // Hierarchical data
  disabled?: boolean;
}

export const HierarchicalSelect = ({
  control,
  id,
  title,
  data,
  disabled = false,
}: InputProps) => {
  const options = Object.keys(data).reduce<string[]>(
    (acc, parent) => [...acc, parent, ...data[parent]],
    []
  );

  const parentClass = Object.keys(data).map((item) => item);

  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, value } }: any) => {
        const validatedValue = (value || []).filter((item: any) =>
          options.includes(item)
        );
        const handleSelectionChange = (
          _: SyntheticEvent<Element, Event>,
          selectedOptions: string[],
          reason: AutocompleteChangeReason
        ) => {
          if (reason === 'clear') onChange([]);
          const updatedSelection = new Set(selectedOptions);
          // Add children when a parent is selected
          selectedOptions?.forEach((item) => {
            if (data[item]) {
              data[item].forEach((child) => updatedSelection.add(child));
            }
          });
          // Remove children when a parent is deselected
          value?.forEach((item: string) => {
            if (data[item] && !selectedOptions?.includes(item)) {
              data[item].forEach((child) => updatedSelection.delete(child));
            }
          });
          const finalSelection = Array.from(updatedSelection);
          onChange(finalSelection);
        };

        return (
          <Autocomplete
            disabled={disabled}
            multiple
            id={title}
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            defaultValue={[]}
            value={validatedValue} // Ensure all selected options are shown
            onChange={handleSelectionChange}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  display: 'flex',
                  justifyContent: parentClass.includes(option)
                    ? 'center'
                    : 'flex-start',
                }}
              >
                <Checkbox checked={validatedValue?.includes(option)} />
                {parentClass.includes(option) ? `${option}-CLASS` : option}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label={title} />}
          />
        );
      }}
    />
  );
};
