import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chip, IconButton, Typography } from '@mui/material';
import * as _ from 'lodash';
import { useContext } from 'react';
import { StockCountContext } from '../../../context/StockCountContext';
import { useGetCategories } from '../../pos/query/get-categories';

interface FilterProps {
  onOpen: () => void;
}

const SidebarFiltersList = ({ onOpen }: FilterProps) => {
  const { filters, setFilters } = useContext(StockCountContext);

  const { category_data } = useGetCategories(filters?.['categoryId'] as string);

  const get = (path: string) => _.get(filters, path, []) as string[];

  const handleDelete = (path: string, key: string) => {
    if (path === 'subcategoryId') {
      if (path === 'subcategoryId') {
        delete filters['subcategoryId'];
        setFilters(filters);
      }
    } else {
      setFilters({
        [path]: get(path).filter((elem) => elem !== key),
      });
    }
  };

  return (
    <Container>
      <IconWrapper>
        <IconButton onClick={onOpen}>
          <MoreVertIcon />
        </IconButton>
        <Typography fontSize={14} fontWeight={500}>
          Filter
        </Typography>
      </IconWrapper>

      <FilterWrapper>
        {category_data && filters?.['categoryId'] && (
          <>
            <Item>Category:</Item>
            <Chip
              label={category_data?.name}
              variant="outlined"
              size="small"
              color="info"
              onDelete={() => {
                setFilters({ categoryId: undefined });
              }}
              key={Number(filters?.['categoryId'])}
              style={{ marginBottom: 8, marginLeft: '5px' }}
            />
          </>
        )}

        {get('brand').length > 0 && (
          <>
            <Item>Brand:</Item>
            {get('brand').map((elem) => (
              <Chip
                label={elem}
                variant="outlined"
                color="info"
                size="small"
                onDelete={() => handleDelete('brand', elem)}
                key={elem}
                style={{ marginBottom: 8, marginLeft: '5px' }}
              />
            ))}
          </>
        )}

        {get('season').length > 0 && (
          <>
            <Item>Season:</Item>
            {get('season').map((elem) => (
              <Chip
                label={elem}
                variant="outlined"
                color="info"
                size="small"
                onDelete={() => handleDelete('season', elem)}
                key={elem}
                style={{ marginBottom: 8, marginLeft: '5px' }}
              />
            ))}
          </>
        )}

        {get('wheelPosition').length > 0 && (
          <>
            <Item>Wheel position:</Item>
            {get('wheelPosition').map((elem) => (
              <Chip
                label={elem.toUpperCase()}
                variant="outlined"
                color="info"
                size="small"
                onDelete={() => handleDelete('wheelPosition', elem)}
                key={elem}
                style={{ marginBottom: 8, marginLeft: '5px' }}
              />
            ))}
          </>
        )}

        {get('specialMarking').length > 0 && (
          <>
            <Item>Special marking:</Item>
            {get('specialMarking').map((elem) => (
              <Chip
                style={{ marginBottom: 8, marginLeft: '5px' }}
                label={elem}
                variant="outlined"
                size="small"
                color="info"
                onDelete={() => handleDelete('specialMarking', elem)}
                key={elem}
              />
            ))}
          </>
        )}

        {get('size').length > 0 && (
          <>
            <Item>Size:</Item>
            {get('size').map((elem) => (
              <Chip
                style={{ marginBottom: 8, marginLeft: '5px' }}
                label={elem}
                variant="outlined"
                size="small"
                color="info"
                onDelete={() => handleDelete('size', elem)}
                key={elem}
              />
            ))}
          </>
        )}

        {get('color').length > 0 && (
          <>
            <Item>Color:</Item>
            {get('color').map((elem) => (
              <Chip
                style={{ marginBottom: 8, marginLeft: '5px' }}
                label={elem}
                variant="outlined"
                size="small"
                color="info"
                onDelete={() => handleDelete('color', elem)}
                key={elem}
              />
            ))}
          </>
        )}

        {get('rimSize').length > 0 && (
          <>
            <Item>Rim size:</Item>
            {get('rimSize').map((elem) => (
              <Chip
                style={{ marginBottom: 8, marginLeft: '5px' }}
                label={elem}
                variant="outlined"
                size="small"
                color="info"
                onDelete={() => handleDelete('rimSize', elem)}
                key={elem}
              />
            ))}
          </>
        )}
      </FilterWrapper>
    </Container>
  );
};

export const Item = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  color: gray;
  font-style: italic;
  margin-bottom: 8px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const FilterWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  overflow-x: auto;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
`;

export default SidebarFiltersList;
