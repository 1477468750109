import { Badge, Grid, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DBStockCountStatus } from '@prisma/client';
import { DBStockCountItemCountByStatus } from '@tyrio/dto';
import { useContext, useState } from 'react';
import { getStockCountStatusColor } from '../../purchase-orders/helpers/generate-color';
import { StockCountContext } from '../../../context/StockCountContext';

interface TabsFilterProps {
  countOrders: DBStockCountItemCountByStatus[];
}

export const STOCK_COUNT_ORDER_STATUSES = [
  {
    value: 'all',
    label: 'ALL',
    color: 'red',
    width: '30px',
  },
  {
    value: DBStockCountStatus.NEW,
    label: 'NEW',
    color: '#10B981',
    width: '60px',
  },
  {
    value: DBStockCountStatus.IN_PROGRESS,
    label: 'IN PROGRESS',
    color: '#FFCC4D',
    width: '120px',
  },
  {
    value: DBStockCountStatus.COUNTED,
    label: 'COUNTED',
    color: '#3EB274',
    width: '110px',
  },
  {
    value: DBStockCountStatus.CANCELED,
    label: 'CANCELED',
    color: '#3EB274',
    width: '110px',
  },
];

const StatusTabs = ({ countOrders }: TabsFilterProps) => {
  const { filters, setFilters } = useContext(StockCountContext);

  const [shouldResetTabs, setShouldResetTabs] = useState(false);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: DBStockCountStatus | 'all'
  ) => {
    setFilters({
      ...filters,
      status: newValue,
    });
    setShouldResetTabs(false);
  };

  const getOrderCount = (status: DBStockCountStatus) => {
    return countOrders?.find((item) => item.status === status)?._count ?? 0;
  };

  const getNewCount = () => {
    return countOrders?.find((item) => item.status === 'NEW')?._count ?? 0;
  };

  return (
    <Grid
      sx={{
        width: '100%',
        overflow: 'auto',
      }}
    >
      <TabsStyled
        value={shouldResetTabs ? 'all' : filters['status'] ?? 'all'}
        onChange={handleChange}
        scrollButtons={false}
        variant="scrollable"
      >
        {STOCK_COUNT_ORDER_STATUSES.map((status) => {
          const orderCount = getOrderCount(
            DBStockCountStatus[status.value as keyof typeof DBStockCountStatus]
          );

          const color =
            status.label !== 'NEW'
              ? getStockCountStatusColor[
                  DBStockCountStatus[
                    status.value as keyof typeof DBStockCountStatus
                  ]
                ]
              : STOCK_COUNT_ORDER_STATUSES[1].color;

          return (
            <TabStyled
              value={status.value}
              iconPosition="start"
              sx={{
                '&.Mui-disabled': {
                  pointerEvents: 'auto',
                },
                '&:hover': {
                  color: '#1976D2',
                  opacity: 1,
                },
              }}
              key={status.label}
              icon={
                <Grid
                  sx={{
                    width: status.width,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                    }}
                  >
                    {status.label}
                  </Typography>

                  {((status.label !== 'NEW' && orderCount > 0) ||
                    (status.label === 'NEW' && getNewCount() > 0)) && (
                    <Badge
                      badgeContent={
                        status.label !== 'NEW' ? orderCount : getNewCount()
                      }
                      sx={{
                        '& .MuiBadge-badge': {
                          color: 'white',
                          backgroundColor: color,
                        },
                      }}
                    />
                  )}
                </Grid>
              }
            />
          );
        })}
      </TabsStyled>
    </Grid>
  );
};

const TabsStyled = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1976D2',
  },
});

const TabStyled = styled(Tab)({
  '&.Mui-selected': {
    color: '#1976D2',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#1976D2',
    color: '#1976D2',
  },
});

export default StatusTabs;
