import * as z from "zod"
import { DBClientType, DBBusinessType, DBCurrency } from ".prisma/client"
import { CompleteDBUser, RelatedDBUserModel, CompleteDBAddress, RelatedDBAddressModel, CompleteDBCompanyContact, RelatedDBCompanyContactModel, CompleteDBRole, RelatedDBRoleModel, CompleteDBSupplierStock, RelatedDBSupplierStockModel, CompleteDBSupplierClientSettings, RelatedDBSupplierClientSettingsModel, CompleteDBClientSupplierOrderItem, RelatedDBClientSupplierOrderItemModel, CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel, CompleteDBBranch, RelatedDBBranchModel, CompleteDBClientApiKey, RelatedDBClientApiKeyModel, CompleteDBClientPriceCalculation, RelatedDBClientPriceCalculationModel, CompleteDBClientRebateCalculation, RelatedDBClientRebateCalculationModel, CompleteDBShopTypes, RelatedDBShopTypesModel, CompleteDBPaymentTypes, RelatedDBPaymentTypesModel, CompleteDBDeliveryTypes, RelatedDBDeliveryTypesModel, CompleteDBClientStockList, RelatedDBClientStockListModel, CompleteDBFavoriteCategories, RelatedDBFavoriteCategoriesModel, CompleteDBTransferOrderItem, RelatedDBTransferOrderItemModel, CompleteDBStockInItems, RelatedDBStockInItemsModel, CompleteDBCollections, RelatedDBCollectionsModel, CompleteDBDispatch, RelatedDBDispatchModel, CompleteDBServiceCategory, RelatedDBServiceCategoryModel, CompleteDBCustomPriceCatalog, RelatedDBCustomPriceCatalogModel, CompleteDBBranchWarehouseAreas, RelatedDBBranchWarehouseAreasModel, CompleteDBAppointments, RelatedDBAppointmentsModel, CompleteDBClientShippingCourier, RelatedDBClientShippingCourierModel, CompleteDBPartner, RelatedDBPartnerModel, CompleteDBDocumentsLook, RelatedDBDocumentsLookModel, CompleteDBWarehouseCache, RelatedDBWarehouseCacheModel, CompleteDBProductsCache, RelatedDBProductsCacheModel, CompleteDBStockListImportLog, RelatedDBStockListImportLogModel, CompleteDBInboundInvoice, RelatedDBInboundInvoiceModel, CompleteDBHotel, RelatedDBHotelModel, CompleteDBStockCount, RelatedDBStockCountModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBClientModel = z.object({
  id: z.string(),
  clientType: z.nativeEnum(DBClientType),
  businessType: z.nativeEnum(DBBusinessType),
  businessCode: z.string().nullish(),
  intrastat: z.string().nullish(),
  vatObligee: z.string().nullish(),
  vatNumber: z.string(),
  vies: z.boolean(),
  viesVatNumber: z.string().nullish(),
  euVatNumber: z.string().nullish(),
  registrationNumber: z.string().nullish(),
  active: z.boolean(),
  remark: z.string(),
  addressId: z.string(),
  officialName: z.string(),
  shortName: z.string(),
  slug: z.string(),
  companyEmail: z.string().nullish(),
  companyWebSite: z.string().nullish(),
  companyBusinessPhone: z.string().nullish(),
  responsiblePersonJobTile: z.string().nullish(),
  responsiblePersonFirstName: z.string().nullish(),
  responsiblePersonLastName: z.string().nullish(),
  responsiblePersonEmail: z.string().nullish(),
  responsiblePersonBusinessPhone: z.string().nullish(),
  responsiblePersonMobilePhone: z.string().nullish(),
  referralId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  IBAN: z.string().nullish(),
  swift: z.string().nullish(),
  currency: z.nativeEnum(DBCurrency).nullish(),
  isUpdated: z.boolean(),
  generalSettings: jsonSchema,
  printNodeApiKey: z.string().nullish(),
  printers: jsonSchema,
  stockListImportSettings: jsonSchema,
})

export interface CompleteDBClient extends z.infer<typeof DBClientModel> {
  users: CompleteDBUser[]
  address: CompleteDBAddress
  companyContacts: CompleteDBCompanyContact[]
  DBRole: CompleteDBRole[]
  supplierStock: CompleteDBSupplierStock[]
  connectedSuppliers: CompleteDBSupplierClientSettings[]
  orders: CompleteDBClientSupplierOrderItem[]
  customerOrders: CompleteDBCustomerOrderItem[]
  branches: CompleteDBBranch[]
  apiKey: CompleteDBClientApiKey[]
  priceCalculations: CompleteDBClientPriceCalculation[]
  rebateCalculations: CompleteDBClientRebateCalculation[]
  shopTypes: CompleteDBShopTypes[]
  paymentTypes: CompleteDBPaymentTypes[]
  deliveryTypes: CompleteDBDeliveryTypes[]
  stockListItems: CompleteDBClientStockList[]
  favoriteCategories: CompleteDBFavoriteCategories[]
  DBTransferOrderItem: CompleteDBTransferOrderItem[]
  stockInItems: CompleteDBStockInItems[]
  collections: CompleteDBCollections[]
  dispatchedOrders: CompleteDBDispatch[]
  DBServiceCategory: CompleteDBServiceCategory[]
  customPriceCatalog: CompleteDBCustomPriceCatalog[]
  warehouses: CompleteDBBranchWarehouseAreas[]
  appointments: CompleteDBAppointments[]
  DBClientShippingCourier: CompleteDBClientShippingCourier[]
  partners: CompleteDBPartner[]
  documentsLook?: CompleteDBDocumentsLook | null
  warehouseCache: CompleteDBWarehouseCache[]
  productsCache: CompleteDBProductsCache[]
  stockListLogs: CompleteDBStockListImportLog[]
  inboundInvoices: CompleteDBInboundInvoice[]
  hotels: CompleteDBHotel[]
  stockCounts: CompleteDBStockCount[]
}

/**
 * RelatedDBClientModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBClientModel: z.ZodSchema<CompleteDBClient> = z.lazy(() => DBClientModel.extend({
  users: RelatedDBUserModel.array(),
  address: RelatedDBAddressModel,
  companyContacts: RelatedDBCompanyContactModel.array(),
  DBRole: RelatedDBRoleModel.array(),
  supplierStock: RelatedDBSupplierStockModel.array(),
  connectedSuppliers: RelatedDBSupplierClientSettingsModel.array(),
  orders: RelatedDBClientSupplierOrderItemModel.array(),
  customerOrders: RelatedDBCustomerOrderItemModel.array(),
  branches: RelatedDBBranchModel.array(),
  apiKey: RelatedDBClientApiKeyModel.array(),
  priceCalculations: RelatedDBClientPriceCalculationModel.array(),
  rebateCalculations: RelatedDBClientRebateCalculationModel.array(),
  shopTypes: RelatedDBShopTypesModel.array(),
  paymentTypes: RelatedDBPaymentTypesModel.array(),
  deliveryTypes: RelatedDBDeliveryTypesModel.array(),
  stockListItems: RelatedDBClientStockListModel.array(),
  favoriteCategories: RelatedDBFavoriteCategoriesModel.array(),
  DBTransferOrderItem: RelatedDBTransferOrderItemModel.array(),
  stockInItems: RelatedDBStockInItemsModel.array(),
  collections: RelatedDBCollectionsModel.array(),
  dispatchedOrders: RelatedDBDispatchModel.array(),
  DBServiceCategory: RelatedDBServiceCategoryModel.array(),
  customPriceCatalog: RelatedDBCustomPriceCatalogModel.array(),
  warehouses: RelatedDBBranchWarehouseAreasModel.array(),
  appointments: RelatedDBAppointmentsModel.array(),
  DBClientShippingCourier: RelatedDBClientShippingCourierModel.array(),
  partners: RelatedDBPartnerModel.array(),
  documentsLook: RelatedDBDocumentsLookModel.nullish(),
  warehouseCache: RelatedDBWarehouseCacheModel.array(),
  productsCache: RelatedDBProductsCacheModel.array(),
  stockListLogs: RelatedDBStockListImportLogModel.array(),
  inboundInvoices: RelatedDBInboundInvoiceModel.array(),
  hotels: RelatedDBHotelModel.array(),
  stockCounts: RelatedDBStockCountModel.array(),
}))
