import api from '@tyrio/api-factory';
import { DBStockCountApi } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetStockCounts = (req: qs.ParsedQs) => {
  const query = useQuery(['get_stock_counts', req], () =>
    api.fetch<DBStockCountApi['list']>('get_stock_counts', {
      ...req,
    })
  );

  return { ...query };
};
