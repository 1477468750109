/* eslint-disable @typescript-eslint/no-explicit-any */
export const useStockCountHelper = () => {
  function calculateQty(obj: any) {
    let total = 0;
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        total += calculateQty(obj[key]);
      } else if (typeof obj[key] === 'number') {
        total += obj[key];
      }
    }
    return total;
  }

  return { calculateQty };
};
