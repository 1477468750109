import { DBBranchResponse, FittingBoxesRes } from '@tyrio/dto';
import { isEmpty, startCase } from 'lodash';
import { DropdownItems } from '../../price-calculation/queries/get-rebates';

export const initialValues = (
  branchData: DBBranchResponse | undefined,
  rebatesData: DropdownItems[],
  deliveriesData: DropdownItems[],
  fittingBoxSettingsData?: FittingBoxesRes
) => {
  if (!branchData)
    return {
      branchName: '',
      shortName: '',
      sortOrder: '',
      branchType: [],
      mainWarehouse: '',
      country: '274e7e37-6d32-f25b-4a5f-daf91b949567',
      zipCode: '',
      city: '',
      address: '',
      email: '',
      webSite: '',
      erpId: '',
      businessPhone: '+385',
      jobTitle: '',
      firstName: '',
      lastName: '',
      mail: '',
      userBusinessPhone: '+385',
      userMobilePhone: '+385',
      printers: {},
      rebateGroup: '',
    };

  const printers = !isEmpty(branchData?.printers)
    ? JSON.parse(branchData.printers as string)
    : {};

  return {
    branchId: `00${branchData.branchNumber}`,
    branchName: branchData.branchName,
    shortName: branchData.shortName ?? '',
    sortOrder: branchData.sortOrder ?? '',
    branchType: branchData.branchType.map((item) => startCase(item)),
    mainWarehouse: branchData.mainWarehouseId || '',
    active: branchData.isActive,
    country: branchData.countryId || '',
    zipCode: branchData.zipCode,
    city: branchData.city,
    address: branchData.address,
    email: branchData.email,
    webSite: branchData.webSite,
    businessPhone: branchData.businessPhone,
    jobTitle: branchData.qualifiedPersonJobTitle,
    firstName: branchData.qualifiedPersonFirstName,
    lastName: branchData.qualifiedPersonLastName,
    mail: branchData.qualifiedPersonEmail,
    userBusinessPhone: branchData.qualifiedPersonBusinessPhone,
    userMobilePhone: branchData.qualifiedPersonMobilePhone,
    erpId: branchData.erpId ?? '',
    wmsShipping_a4Documents: printers?.wmsShipping_a4Documents,
    wmsShipping_thermalLabels: printers?.wmsShipping_thermalLabels,
    wmsShipping_posReceipts: printers?.wmsShipping_posReceipts,
    wmsStockIn_a4Documents: printers?.wmsStockIn_a4Documents,
    wmsStockIn_thermalLabels: printers?.wmsStockIn_thermalLabels,
    wmsStockIn_posReceipts: printers?.wmsStockIn_posReceipts,
    wmsDispatch_a4Documents: printers?.wmsDispatch_a4Documents,
    wmsDispatch_thermalLabels: printers?.wmsDispatch_thermalLabels,
    wmsDispatch_posReceipts: printers?.wmsDispatch_posReceipts,
    wmsFitting_a4Documents: printers?.wmsFitting_a4Documents,
    wmsFitting_thermalLabels: printers?.wmsFitting_thermalLabels,
    wmsFitting_posReceipts: printers?.wmsFitting_posReceipts,
    wmsCollection_a4Documents: printers?.wmsCollection_a4Documents,
    wmsCollection_posReceipts: printers?.wmsCollection_posReceipts,
    wmsCollection_thermalLabels: printers?.wmsCollection_thermalLabels,
    wmsDeclaration_a4Documents: printers?.wmsDeclaration_a4Documents,
    wmsDeclaration_posReceipts: printers?.wmsDeclaration_posReceipts,
    wmsDeclaration_thermalLabels: printers?.wmsDeclaration_thermalLabels,
    boxes: fittingBoxSettingsData?.data.map((item) => item),
    rebateGroup:
      rebatesData.find(
        (item) => item.value === branchData.rebateCalculation?.id
      ) ?? null,
    defaultDelivery:
      deliveriesData.find(
        (item) => item.value === branchData.defaultDeliveryId
      ) ?? null,
  };
};
