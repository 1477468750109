/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Summary } from './Summary';
import { Search } from './Search';
import { Details } from './Details';

const FieldComponent: any = {
  SUMMARY: Summary,
  SEARCH: Search,
  DETAILS: Details,
};

export const Steps = (step: string) => {
  if (Object.keys(FieldComponent).find((a) => a === step) === undefined) {
    console.log('Error - Step passed in is not expected!');
    return;
  }

  if (step in Object.keys(FieldComponent)) {
    console.log('Error - Step passed in is not expected!');
  }
  return React.createElement(FieldComponent[step]);
};
