import { DBUser, DBUserRole } from '@prisma/client';
import { FEATURE_FLAGS } from '@tyrio/feature-flags';
import { useCallback, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PageNotFound from '../components/PageNotFound/PageNotFound';
import { useAuth } from '../context/AuthContext';
import { SalesOrdersFlow } from '../features/sales-orders/SalesOrdersFlow';
import { QuantitiesNotAvailableModal } from '../features/stock-list/components/QuantitiesNotAvailable/QuantitiesNotAvailable';
import { PosSessionExpiredModal } from '../features/pos/PosSessionExpiredModal';
import { SessionExpiredModal } from '../features/stock-list/components/Timer/SessionExpiredModal';
import MainLayout from '../layouts/MainLayout';
import { CatalogImport } from '../pages/dashboard/CatalogImport';
import ComingSoon from '../pages/dashboard/ComingSoon';
import CustomerOrders from '../pages/dashboard/CustomerOrders';
import DashboardOverview from '../pages/dashboard/Overview';
import POS from '../pages/dashboard/POS';
import PurchaseOrders from '../pages/dashboard/PurchaseOrders';
import StockIn from '../pages/dashboard/wms/StockIn';
import StockList from '../pages/dashboard/wms/StockList';
import StockLocations from '../pages/dashboard/wms/StockLocations';
import TransferOrders from '../pages/dashboard/wms/TransferOrders';
import { AdminRouter } from './AdminRouter';
import { ApiKeyRouter } from './ApiKeyRouter';
import { CategoryRouter } from './CategoryRouter';
import { ClientRouter } from './ClientRouter';
import { CompanySettingsRouter } from './CompanySettingsRouter';
import { CustomerRebatesRouter } from './CustomerRebatesRouter';
import { PartnerRouter } from './PartnerRouter';
import { PriceCalculationRouter } from './PriceCalculationRouter';
import { PriceComparisonRouter } from './PriceComparisonRouter';
import { ProductModelRouter } from './ProductModelRouter';
import { ProductRouter } from './ProductRouter';
import { RoleRouter } from './RoleRouter';
import { ServicesRouter } from './ServicesRouter';
import { SupplierRouter } from './SupplierRouter';
import { ImportStatus } from '../pages/dashboard/ImportStatus';
import { WSAdminProvider } from '../context/WSAdminContext';
import CustomPriceCatalog from '../features/custom-price-catalog/CustomPriceCatalog';
import DashboardCustomPriceCatalog from '../pages/dashboard/CustomPriceCatalog';
import DashboardWmsLocationDetails from '../pages/dashboard/WmsLocationDetails';
import WmsLocationDetails from '../features/wms-location-details/WmsLocationDetails';
import Warehouse from '../pages/dashboard/Warehouse';
import AppointmentsOverview from '../features/appointments/Appointments';
import { StockListImport } from '../features/stock-list-import/StockListImport';
import { InboundInvoiceOverview } from '../features/inbound-invoice/InboundInvoiceOverview';
import { WorkOrdersDashboard } from '../pages/dashboard/wms/WorkOrders';
import InboundInvoice from '../pages/dashboard/wms/InboundInvoice';
import StockCount from '../pages/dashboard/StockCount';

interface DashboardRouterProps {
  user: DBUser;
}

export const DashboardRouter = ({ user }: DashboardRouterProps) => {
  const { path } = useRouteMatch();
  const { permissionList, roleName } = useAuth();

  const [openSidebar, setOpenSidebar] = useState(false);

  const userRole = user.userRole;

  const clientId = user.clientId ? user.clientId : '';

  const isRouteVisibleToUser = useCallback(
    (roles: DBUserRole[], viewPermission?: boolean) => {
      return (
        roles.includes(userRole) &&
        (viewPermission === undefined || viewPermission)
      );
    },
    [userRole]
  );

  if (!Object.keys(permissionList).length) return null;

  return (
    <MainLayout
      user={user}
      roleName={roleName}
      viewProductCatalog={permissionList.catalogues?.view}
      viewSettings={permissionList.settings?.view}
      permissionList={permissionList}
      open={openSidebar}
      setOpen={setOpenSidebar}
    >
      <PosSessionExpiredModal />
      <SessionExpiredModal />
      <QuantitiesNotAvailableModal />

      <Switch>
        {isRouteVisibleToUser([DBUserRole.ADMIN, DBUserRole.CLIENT]) && (
          <Route exact path={`${path}`}>
            <DashboardOverview />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.ADMIN]) && (
          <Route exact path={`${path}/catalog`}>
            <CatalogImport />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.ADMIN, DBUserRole.CLIENT]) && (
          <Route exact path={`${path}/overview`}>
            <DashboardOverview />
          </Route>
        )}

        {isRouteVisibleToUser(
          [DBUserRole.ADMIN, DBUserRole.CLIENT],
          permissionList.settings?.roles?.view
        ) && (
          <Route path={`${path}/roles`}>
            <RoleRouter
              permissions={permissionList.settings?.roles}
              clientId={clientId}
            />
          </Route>
        )}
        {isRouteVisibleToUser(
          [DBUserRole.CLIENT],
          permissionList.settings?.companyInfo?.view
        ) && (
          <Route path={`${path}/company-settings`}>
            <CompanySettingsRouter
              clientId={clientId}
              permissions={permissionList.settings?.companyInfo}
            />
          </Route>
        )}
        {/* {isRouteVisibleToUser(
          [DBUserRole.CLIENT],
          permissionList.settings.users?.view
        ) && (
          <Route path={`${path}/users`}>
            <UserRouter
              loggedUser={user.id}
              clientId={clientId}
              permissions={permissionList.settings?.users}
              roleId={roleId}
            />
          </Route>
        )} */}
        {isRouteVisibleToUser(
          [DBUserRole.ADMIN, DBUserRole.CLIENT],
          permissionList.catalogues?.productItems?.view
        ) && (
          <Route path={`${path}/products`}>
            <ProductRouter />
          </Route>
        )}
        {isRouteVisibleToUser(
          [DBUserRole.ADMIN, DBUserRole.CLIENT],
          permissionList.catalogues?.productItems?.view
        ) && (
          <Route path={`${path}/product-models`}>
            <ProductModelRouter />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.ADMIN]) && (
          <Route path={`${path}/clients`}>
            <ClientRouter />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.ADMIN]) && (
          <Route path={`${path}/admin`}>
            <AdminRouter />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.ADMIN]) && (
          <Route path={`${path}/supplier`}>
            <SupplierRouter />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.ADMIN]) && (
          <Route path={`${path}/categories`}>
            <CategoryRouter />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.ADMIN]) && (
          <Route path={`${path}/shipping-couriers`}>
            <ComingSoon />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.ADMIN]) && (
          <Route path={`${path}/attributes`}>
            <ComingSoon />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.ADMIN]) && (
          <Route path={`${path}/countries`}>
            <ComingSoon />
          </Route>
        )}
        {isRouteVisibleToUser(
          [DBUserRole.ADMIN, DBUserRole.CLIENT],
          permissionList.purchasing?.supplierSettings?.view
        ) && (
          <Route path={`${path}/supplier`}>
            <SupplierRouter />
          </Route>
        )}
        {isRouteVisibleToUser(
          [DBUserRole.CLIENT],
          permissionList.purchasing?.priceCompare.view
        ) && (
          <Route path={`${path}/price-comparison`}>
            <PriceComparisonRouter />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) &&
          FEATURE_FLAGS.SHOW_STOCK_LOCATIONS && (
            <Route path={`${path}/stock-locations`}>
              <StockLocations />
            </Route>
          )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) &&
          FEATURE_FLAGS.SHOW_STOCK_LIST && (
            <Route path={`${path}/stock-list`}>
              <StockList sidebarOpen={openSidebar} />
            </Route>
          )}
        {isRouteVisibleToUser([DBUserRole.ADMIN]) && (
          <Route exact path={`${path}/import-status`}>
            <WSAdminProvider>
              <ImportStatus />
            </WSAdminProvider>
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.ADMIN, DBUserRole.CLIENT]) && (
          <Route exact path={`${path}/overview`}>
            <DashboardOverview />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) &&
          FEATURE_FLAGS.SHOW_TRANSFER_ORDERS && (
            <Route path={`${path}/transfer-orders`}>
              <TransferOrders />
            </Route>
          )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) &&
          FEATURE_FLAGS.SHOW_STOCK_IN && (
            <Route path={`${path}/stock-in`}>
              <StockIn sidebarOpen={openSidebar} />
            </Route>
          )}
        {isRouteVisibleToUser(
          [DBUserRole.CLIENT],
          permissionList.purchasing?.purchaseOrders?.view
        ) && (
          <Route path={`${path}/purchase-orders`}>
            <PurchaseOrders sidebarOpen={openSidebar} />
          </Route>
        )}
        {isRouteVisibleToUser(
          [DBUserRole.CLIENT],
          permissionList.sales?.customerOrders?.view ?? true
        ) && (
          <Route path={`${path}/customer-orders`}>
            <CustomerOrders sidebarOpen={openSidebar} />
          </Route>
        )}
        {isRouteVisibleToUser(
          [DBUserRole.CLIENT],
          permissionList.sales?.priceCalculation?.view
        ) && (
          <Route path={`${path}/price-calculation`}>
            <PriceCalculationRouter />
          </Route>
        )}
        {isRouteVisibleToUser(
          [DBUserRole.CLIENT],
          permissionList.sales?.customerRebates?.view
        ) && (
          <Route path={`${path}/customer-rebates`}>
            <CustomerRebatesRouter />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/custom-price-catalog`}>
            <DashboardCustomPriceCatalog>
              <CustomPriceCatalog />
            </DashboardCustomPriceCatalog>
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/location-details`}>
            <DashboardWmsLocationDetails>
              <WmsLocationDetails />
            </DashboardWmsLocationDetails>
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.ADMIN, DBUserRole.CLIENT]) && (
          <Route path={`${path}/api-keys`}>
            <ApiKeyRouter />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/sales-orders/:orderId`}>
            <SalesOrdersFlow />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/pos`}>
            <POS sidebarOpen={openSidebar} />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/warehouse`}>
            <Warehouse />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/stock-count`}>
            <StockCount />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/partners`}>
            <PartnerRouter />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/services-category`}>
            <ServicesRouter />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/inbound-invoice`}>
            <InboundInvoiceOverview />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/appointments`}>
            <AppointmentsOverview />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/stock-list-import`}>
            <StockListImport />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/work-orders`}>
            <WorkOrdersDashboard />
          </Route>
        )}
        {isRouteVisibleToUser([DBUserRole.CLIENT]) && (
          <Route path={`${path}/inbound-invoices`}>
            <InboundInvoice sidebarOpen={openSidebar} />
          </Route>
        )}

        {isRouteVisibleToUser([DBUserRole.ADMIN, DBUserRole.CLIENT]) && (
          <Route path="*">
            <PageNotFound />
          </Route>
        )}
      </Switch>
    </MainLayout>
  );
};
