import OrderDetailsGenerator from './OrderDetailsGenerator';
import { ProductDetailsDataInterface } from '@tyrio/dto';

export type Language = 'croatian' | 'english';

interface EmailLinkGeneratorProps {
  email: string;
  cc: string;
  products: ProductDetailsDataInterface[];
  branchDetails: {
    name?: string;
    address: string;
    zipCode: string;
    city: string;
    mobilePhone: string;
  };
  clientName: string;
  deliveryNote?: string;
  reference?: string;
  countryId: string;
}

const EmailLinkGenerator = ({
  email,
  cc,
  products,
  branchDetails,
  clientName,
  deliveryNote,
  reference,
  countryId,
}: EmailLinkGeneratorProps): string => {
  let language: Language = 'english';
  if (countryId === '274e7e37-6d32-f25b-4a5f-daf91b949567')
    language = 'croatian';
  const emailContent = OrderDetailsGenerator({
    products,
    clientName,
    branchDetails,
    deliveryNote,
    reference,
    language,
  });

  const generatedEmail = `mailto:${email}?cc=${cc}&subject=${reference}&body=${encodeURIComponent(
    emailContent
  )}`;
  return generatedEmail;
};

export default EmailLinkGenerator;
