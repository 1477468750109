import { DBPaymentTypesApi } from '@tyrio/dto';

export const initialValues = (
  data?: DBPaymentTypesApi['getOne']['response']
) => {
  if (data !== undefined) {
    return {
      code: data?.code,
      name: data?.name,
      description: data?.description ?? '',
      paymentType: data?.paymentType ?? '',
      priceType: data?.priceType ?? null,
      cashDiscount: data?.cashDiscount,
      restrictions: {
        minAmount: data?.restrictions?.minAmount ?? NaN,
        maxAmount: data?.restrictions?.maxAmount ?? NaN,
      },

      isFiscalizationEnabled: data?.isFiscalizationEnabled ?? false,
      fiscalType: data?.fiscalType,
      paymentGateway: data?.paymentGateway ?? '',
      cardSettings: {
        installements: data?.cardSettings?.installements ?? '',
        acceptedCards: data?.cardSettings?.acceptedCards?.map((a) => {
          return { label: a, value: a };
        }),
      },
    };
  } else
    return {
      code: '',
      name: '',
      description: '',
      paymentType: '',
      priceType: null,
      cashDiscount: false,
      restrictions: {
        minAmount: NaN,
        maxAmount: NaN,
      },
      isFiscalizationEnabled: false,
      fiscalType: '',
      paymentGateway: 'no payment gateway',
      cardSettings: {
        installements: '',
        acceptedCards: [],
      },
    };
};
