import styled from '@emotion/styled/macro';
import { ClickAwayListener } from '@mui/material';
import { DBTrafficType } from '@prisma/client';
import { InboundInvoiceApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import _, { startCase } from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom';
import { CartHeader } from '../../components/Cart/Header';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import {
  getVATKey,
  InboundInvoiceContext,
} from '../../context/InboundInvoiceContext';
import { transportModeData } from '../../helpers/transport-mode';
import { TableFooter } from './components/TableFoooter';
import { useCalculatePrice } from './helpers/calculation';
import { initialValues } from './helpers/initial-values';
import { InboundInvoiceValidation } from './helpers/zod-validation';
import { useCreateInboundInvoice } from './query/create';
import { Table } from './Table/Table';
import { InboundInvoiceTabs } from './Tabs/InboundInvoiceTabs';
import { ConfirmModal } from './components/ConfirmModal';
import { useUpdateWorkOrderAfterInvoice } from './query/update-work-orders';
import { WorkOrdersModal } from './components/WorkOrdersModal';

export const InboundInvoice = () => {
  const history = useHistory();
  const {
    isVatIncluded,
    setIsVatIncluded,
    input,
    setInput,
    splitInvoice,
    preparedInvoices,
    isEU,
    branches,
    isCancelModalOpen,
    customSummary,
    openConfirmModal,
    setOpenConfirmModal,
    shouldUpdateWorkOrder,
    createTransferOrder,
  } = useContext(InboundInvoiceContext);

  const [isNavigating, setIsNavigating] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const {
    totalQty,
    itemsPrice,
    variableCost,
    customsTotal,
    subTotalExlVat,
    grandTotal,
    vat,
    vatAmount,
  } = useCalculatePrice(
    preparedInvoices?.items ?? [],
    preparedInvoices?.vatChargeType ?? 'V05'
  );

  const {
    data,
    isLoading,
    isSuccess,
    mutateAsync: createNewWorkOrders,
  } = useUpdateWorkOrderAfterInvoice();

  const prepItemsForWorkOrdersUpdate = () => {
    const items = (preparedInvoices?.items ?? []).filter(
      (item) => item.customerOrderId !== null
    );

    return {
      items,
      branchId: preparedInvoices?.branchId,
      shouldCreateTransferOrder: createTransferOrder,
    };
  };

  const handleSuccess = () => {
    if (!shouldUpdateWorkOrder) {
      if (input?.type === 'purchaseOrder')
        history.push('/dashboard/purchase-orders');
      else
        history.push(
          `/dashboard/stock-in?searchType=reference&searchKeyword=&branchId=all&orderStatus=new`
        );
    } else {
      const body = prepItemsForWorkOrdersUpdate();

      if (_.isEmpty(body)) {
        if (input?.type === 'purchaseOrder')
          history.push('/dashboard/purchase-orders');
        else
          history.push(
            `/dashboard/stock-in?searchType=reference&searchKeyword=&branchId=all&orderStatus=new`
          );
      } else {
        if (!_.isEmpty(body.items)) {
          setOpenModal(true);

          createNewWorkOrders({ body });

          if (isSuccess)
            history.push(
              `/dashboard/stock-in?searchType=reference&searchKeyword=&branchId=all&orderStatus=new`
            );
        } else {
          ToastHelper.showToast(
            'Work orders',
            ToastType.WARNING,
            ToastMessageType.UPDATE,
            'There are no work order items available for update. Please check if this stock in has customer order!'
          );
          history.push(
            `/dashboard/stock-in?searchType=reference&searchKeyword=&branchId=all&orderStatus=new`
          );
        }
      }
    }
  };

  const { createInvoice, isLoading: isCreateInProcess } =
    useCreateInboundInvoice(setInput, handleSuccess);

  const generateDefaultValues = useCallback(() => {
    return initialValues(
      input?.preparedInvoices ?? undefined,
      transportModeData
    );
  }, [input?.preparedInvoices]);

  const {
    register,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
    handleSubmit,
    getValues,
  } = useForm({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: { ...generateDefaultValues() } as any,
    reValidateMode: 'onChange',
    shouldFocusError: false,
    shouldUseNativeValidation: true,
    resolver: (formValues) => {
      const errors: Record<string, string> = {};
      const validationResult =
        InboundInvoiceValidation(isEU).safeParse(formValues);

      // Check if there are errors
      if (validationResult.success === false) {
        const paths: string[] = [];

        validationResult.error.errors.forEach((error) => {
          const path = error.path[0].toString();
          paths.push(path);
          errors[path] = 'This field is required!';
        });
      }

      return {
        values: formValues,
        errors,
      };
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    if (
      (customSummary.grandTotal.toString() !==
        grandTotal.toFixed(2).replace('.', ',') ||
        customSummary.vatAmount.toString() !==
          vatAmount.toFixed(2).replace('.', ',') ||
        customSummary.subtotalExlVat.toString() !==
          subTotalExlVat.toFixed(2).replace('.', ',')) &&
      !openConfirmModal
    ) {
      setOpenConfirmModal(true);
      return;
    }

    setIsNavigating(false);
    const transportMode = transportModeData
      .find((item) => item.value === data.modeOfTransport)
      ?.label.replace(/\d+ - /, '')
      .replaceAll(' ', '_')
      .toUpperCase() as DBTrafficType;

    const body: InboundInvoiceApi['create']['requestBody'] = {
      branchId: Number(data.branchId),
      branchName:
        branches.find((item) => item.value === data.branchId)?.label ?? '',
      date: moment(data.date).toDate(),
      supplierName: data.supplierName,
      vatChargeType: getVATKey(data.vatChargeType) ?? 'R1',
      currency: data.currency ?? 'EUR',
      exchangeRate: data.exchangeRate,
      supplierInvoice: data.supplierInvoiceNumber,
      issueDate: moment(data.invoiceIssueDate).toDate(),
      delay: _.toNumber(data.delay),
      dueDate: moment(data.invoiceDueDate).toDate(),
      internalRemark: data.internalRemark,
      deliveryTermsType: data.deliveryTermsAndConditions,
      transactionNature: Number(data.natureOfTransaction),
      transportMode: transportMode ?? undefined,
      dispatchingCountryId: data.dispatchingCountry,
      summary: {
        totalQty,
        purchaseAmount: _.round(itemsPrice, 2),
        variableCost,
        customs: _.round(customsTotal, 2),
        subtotalExVat: _.round(subTotalExlVat, 2),
        vat,
        vatAmount,
        grandTotalIncVat: _.round(grandTotal, 2),
      },
      items: preparedInvoices?.items ?? [],
      splitInvoice,
      variableCost: {
        domestic: data.domestic ?? 0,
        domesticOther: data.domesticOther ?? 0,
        international: data.international ?? 0,
        internationalOther: data.internationalOther ?? 0,
        totalVarCost: data.totalVarCost ?? 0,
      },
      partnerOib: preparedInvoices?.partner?.viesVatNumber ?? '',
      stockIns: Object.keys(input.checkedStockIns),
      partner: preparedInvoices?.partner ?? undefined,
      customSummary: {
        subtotalExlVat: Number(
          Number(customSummary.subtotalExlVat.replace(',', '.')).toFixed(2)
        ),
        vatAmount: Number(
          Number(customSummary.vatAmount.replace(',', '.')).toFixed(2)
        ),
        grandTotal: Number(
          Number(customSummary.grandTotal.replace(',', '.')).toFixed(2)
        ),
      },
      orderType: input.type,
    };

    setOpenConfirmModal(false);

    createInvoice(body);
  };

  useEffect(() => {
    reset(generateDefaultValues());
  }, [generateDefaultValues, reset]);

  useEffect(() => {
    if (Object.values(errors)?.length > 0)
      ToastHelper.showToast(
        'Inbound invoice',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        Object.values(errors)?.length === 1
          ? 'Field ' + startCase(Object.keys(errors)[0]) + ' has errors.'
          : 'Fields ' +
              Object.keys(errors).map((err) => ' ' + startCase(err)) +
              ' have errors.'
      );
  }, [errors]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let unblock: any;

    if (isCancelModalOpen)
      unblock = history.block(() => {
        if (!isNavigating) {
          setIsNavigating(true);
          return false; // block the route change
        }
        return true; // allow the route change
      });
    else if (unblock) unblock();

    return () => {
      if (unblock) unblock(); // clean up the blocking on unmount or modal close
    };
  }, [isCancelModalOpen, isNavigating, history]);

  return (
    <ClickAwayListener onClickAway={() => setIsNavigating(true)}>
      <PageTemplateWrapper>
        {openConfirmModal && (
          <ConfirmModal
            open={openConfirmModal}
            setOpen={setOpenConfirmModal}
            handleConfirm={onSubmit}
            formData={getValues()}
            isLoading={isCreateInProcess}
          />
        )}
        <WorkOrdersModal
          open={openModal}
          handleClose={() => {
            // if (!isLoading) setOpenModal(false);
          }}
          isLoading={isLoading}
          data={data}
        />
        <PageTemplateContent
          style={{
            width: 'calc(100% - 736px)',
            transition: 'all 0.3s ease',
          }}
        >
          <CartHeader
            isVatIncluded={isVatIncluded}
            setIsVatIncluded={setIsVatIncluded}
            onDelete={() => history.goBack()}
            text="INBOUND INVOICE"
            shouldShowVat={false}
            showEmptyCart={false}
          />

          <TableWrapper>
            <Table />
          </TableWrapper>

          <FooterWrapper>
            <TableFooter
              isVatIncluded={isVatIncluded}
              totalQty={totalQty}
              purchasePrice={itemsPrice}
              variableCost={variableCost}
              customs={customsTotal}
              subTotalExlVat={subTotalExlVat}
              grandTotal={grandTotal}
              vat={vat}
            />
          </FooterWrapper>
        </PageTemplateContent>
        <PageTemplateContent
          style={{
            background: 'white',
            minWidth: '500px',
            maxWidth: '500px',
            marginRight: '0px',
            height: 'calc(100svh - 44px)',
            zIndex: 99,
            transition: 'all 0.3s ease',
          }}
        >
          <InboundInvoiceTabs
            errors={errors}
            control={control}
            register={register}
            setValue={setValue}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            watch={watch}
            isLoading={isCreateInProcess}
          />
        </PageTemplateContent>
        <Prompt
          when={isNavigating}
          message="Are you sure you want to leave? Any unsaved changes will be lost."
        />
      </PageTemplateWrapper>
    </ClickAwayListener>
  );
};

const FooterWrapper = styled.div`
  position: sticky;
  top: 100%;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
