import { MenuItem } from '@tyrio/wms-ui-library';
import { Dispatch, SetStateAction } from 'react';
import { MenuList } from './MenuList';
import { MenuWrapper } from '../../../components/StepsMenu/style/Menu.style';
import { ActiveStep } from '../../../context/StockCountContext';

interface MenuProps {
  activeStep: ActiveStep;
  setActiveStep: Dispatch<SetStateAction<ActiveStep>>;
}

export const Menu = ({ activeStep, setActiveStep }: MenuProps) => {
  const handleClick = (m: ActiveStep, isDisabled: boolean) => {
    if (!isDisabled) {
      setActiveStep(m);
    }
  };

  return (
    <MenuWrapper>
      {MenuList.map((m, index) => {
        return (
          <MenuItem
            icon={m.icon}
            text={m.text}
            status={m.text === activeStep ? 'HOVER' : 'DEFAULT'}
            disabled={false}
            onClick={() => handleClick(m.text as ActiveStep, false)}
            isBadgeInvisible={false}
            key={index}
            activeStep={activeStep}
            badgeContent={0}
            componentType="WAREHOUSE"
          />
        );
      })}
    </MenuWrapper>
  );
};
