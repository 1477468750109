import styled from '@emotion/styled';
import { Button, Checkbox, Chip, Grid, Typography } from '@mui/material';
import { DBProductApi, DBStockCountScannedExpectedMeta } from '@tyrio/dto';
import { ProductDetailsGenerator } from '@tyrio/products';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { StockCountContext } from '../../../context/StockCountContext';
import { QuantityTypography } from '../components/ProductsItem';
import { useRecountStockCount } from '../queries/recount-stock-count';
import { Container } from './style/styled';

export const Details = () => {
  const { selectedProduct, setActiveDrawer, selectedStockCountData } =
    useContext(StockCountContext);

  const [checkboxState, setCheckboxState] = useState<{
    [key: string]: boolean;
  }>({});

  const [parentChecked, setParentChecked] = useState(false);

  function formatDataByEAN(data: any, eanPrefix: string) {
    const formattedData = {};
    Object.keys(data).forEach((key) => {
      if (key.startsWith(eanPrefix)) {
        const productData = data[key];
        const newKey = key.split('_')[1];
        Object.keys(productData.locations).forEach((locationKey) => {
          const newLocationKey = `${locationKey}_${newKey}`;
          _.set(
            formattedData,
            newLocationKey,
            productData.locations[locationKey]
          );
        });
      }
    });
    return formattedData;
  }

  const data = formatDataByEAN(
    selectedStockCountData?.products,
    selectedProduct?.ean as string
  );

  const handleParentCheckboxChange = () => {
    const newParentChecked = !parentChecked;
    setParentChecked(newParentChecked);

    const updatedCheckboxState = Object.keys(data).reduce(
      (acc, key) => ({ ...acc, [key]: newParentChecked }),
      {}
    );
    setCheckboxState(updatedCheckboxState);
  };

  const handleChildCheckboxChange = (key: string) => {
    const updatedCheckboxState = {
      ...checkboxState,
      [key]: !checkboxState[key],
    };
    setCheckboxState(updatedCheckboxState);

    const allChecked = Object.values(updatedCheckboxState).every((val) => val);
    const noneChecked = Object.values(updatedCheckboxState).every(
      (val) => !val
    );
    setParentChecked(allChecked ? true : noneChecked ? false : parentChecked);
  };

  const { mutate } = useRecountStockCount();

  return (
    <Container>
      {selectedProduct ? (
        <Grid>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <ProductDetailsGenerator
              product={
                selectedProduct as unknown as DBProductApi['getOne']['response']
              }
              stockListItemId={selectedProduct.uid ?? ''}
              isFavoriteByUser={false}
              setActiveDrawer={setActiveDrawer}
            />
          </div>
          <Grid>
            <Grid marginTop={2}>
              <TitleWrapper>
                <StorageName>
                  <Checkbox
                    color="info"
                    size="medium"
                    checked={parentChecked}
                    indeterminate={
                      !parentChecked &&
                      Object.values(checkboxState).some((val) => val)
                    }
                    onChange={handleParentCheckboxChange}
                  />
                  <Typography variant="subtitle1" style={{ fontSize: '16px' }}>
                    Shelf locations
                  </Typography>
                </StorageName>
                <StockWrapper>
                  <Typography variant="body2" fontWeight={500}>
                    Expected
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    Counted
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    Difference
                  </Typography>
                </StockWrapper>
              </TitleWrapper>
            </Grid>
            <Grid display="flex" flexDirection="column">
              {Object.entries(data).map(([key, value]) => {
                const splitted = key.split('_');
                const shelf = splitted[0];
                const dot = splitted[1];
                const { expected, scanned } = value as {
                  expected: number;
                  scanned: number;
                };
                const diff = scanned - expected;

                const getScannedColor = () => {
                  if (scanned === 0) return '#212B36';
                  if (expected === scanned) return '#38A169';
                  if (expected > scanned) return '#FFCC4D';
                  return '#DB1E1E';
                };

                const getDiffColor = () => {
                  if (diff < 0) return '#DB1E1E';
                  return '#212B36';
                };

                const isBolded = () => {
                  return scanned !== 0 || diff < 0;
                };

                const countedBy = _.get(
                  selectedStockCountData?.countedByMeta,
                  shelf
                );

                return (
                  <Wrapper key={key}>
                    <Grid>
                      <Grid>
                        <Checkbox
                          color="info"
                          checked={checkboxState[key] || false}
                          onChange={() => handleChildCheckboxChange(key)}
                        />
                        {shelf}
                      </Grid>
                      {countedBy !== '' && (
                        <CountedBy>Counted by: {countedBy}</CountedBy>
                      )}
                    </Grid>
                    <Chip
                      label={`${dot}`}
                      color="error"
                      variant="outlined"
                      size="small"
                    />
                    <NumbersWrapper>
                      <QuantityTypography>{expected}</QuantityTypography>
                      <QuantityTypography
                        sx={{
                          fontWeight: isBolded() ? 600 : 400,
                          color: getScannedColor(),
                        }}
                      >
                        {scanned}
                      </QuantityTypography>
                      <QuantityTypography
                        sx={{
                          color: getDiffColor(),
                          fontWeight: isBolded() ? 600 : 400,
                        }}
                      >
                        {diff}
                      </QuantityTypography>
                    </NumbersWrapper>
                  </Wrapper>
                );
              })}
            </Grid>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              color="error"
              sx={{ marginTop: '10px' }}
              onClick={() => {
                const selectedKeys = Object.keys(checkboxState).filter(
                  (key) => checkboxState[key]
                );

                const prefixes = selectedKeys.map((str) => str.split('_')[0]);

                const racks = (selectedStockCountData?.expectedMeta ??
                  {}) as unknown as DBStockCountScannedExpectedMeta;

                Object.entries(racks).forEach(([_rackKey, rackValue]) => {
                  Object.entries(rackValue.locations).forEach(
                    ([locationKey, _locationStatus]) => {
                      if (
                        prefixes.some((prefix) =>
                          locationKey.startsWith(prefix)
                        )
                      ) {
                        rackValue.locations[locationKey] = 'RECOUNT';
                      }
                    }
                  );
                });

                mutate({
                  expectedMeta: racks,
                  id: selectedStockCountData?.id as string,
                });
              }}
            >
              Recount shelf
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Typography
          variant="h6"
          fontWeight={500}
          color="#B9C4CE"
          style={{ fontSize: '20px', width: '70%' }}
        >
          Please select product on the left side to see product details
        </Typography>
      )}
    </Container>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
`;

const StorageName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: '240px';
  width: 100%;
  justify-content: space-around;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: '160px';
  height: 100%;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: '#f4f6f8';
  border-radius: 8px 0px 0px 8px;
`;

const NumbersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  align-items: center;
  max-width: 250px;
  width: 100%;
  justify-content: space-around;
`;

const CountedBy = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
  padding-left: 20px;
`;
