import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TOKEN } from '@tyrio/api-factory';
import { useAuth } from './AuthContext';
import { WSState } from '@tyrio/dto';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io/dist/typed-events';
import { appConfig } from '@tyrio/config';

interface WSContextShape {
  state: WSState;
  ping: () => void;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | null;
}

export const WSContext = React.createContext<WSContextShape>({
  state: {
    locks: {},
    activeEdits: {},
    cart: {
      userCart: {},
    },
    posCart: {
      userCart: {},
    },
    ordersInProcess: {},
    activePickingAppointmentsList: {},
    activeServicesList: {},
    appointments: {},
    activeStockIns: {},
    workOrdersUpdate: {},
  },
  ping: () => null,
  socket: null,
});

const connectionConfig = {
  autoConnect: false,
};

const useWSContextData = (): WSContextShape => {
  const { user } = useAuth();
  const [state, setState] = useState<WSState>({
    locks: {},
    activeEdits: {},
    cart: {
      userCart: {},
    },
    posCart: {
      userCart: {},
    },
    ordersInProcess: {},
    activePickingAppointmentsList: {},
    activeServicesList: {},
    appointments: {},
    activeStockIns: {},
    workOrdersUpdate: {},
  });

  // Initialize the socket only once (autoConnect disabled to control it manually)
  const socketRef = useRef<Socket | null>(null);
  if (!socketRef.current) {
    socketRef.current = io(appConfig.wsUrl, connectionConfig);
  }

  const socket = socketRef.current;

  const handleMessage = useCallback((event: WSState): void => {
    console.log({ event });
    setState(event);
  }, []);

  // Ping method
  const ping = useCallback(() => {
    if (socket) socket.emit('ping');
    if (socket) socket.emit('activityCheckLive');
  }, [socket]);

  const handleActivityCheck = useCallback(() => {
    socket.emit('activityCheckLive');
    console.log('activityCheckLive');
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    socket.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    const handleConnect = () => {
      socket.emit('identify', { token: TOKEN.get() });
    };

    // Set up listeners based on user and connection state
    if (user && !socket.connected) {
      socket.on('connect', handleConnect);
      socket.on('data', handleMessage);
      socket.on('activityCheck', handleActivityCheck);
    } else if (user && socket.connected) {
      handleConnect();
      socket.on('data', handleMessage);
    } else if (!user && socket.connected) {
      socket.disconnect();
    }
  }, [handleMessage, socket, user, handleActivityCheck]);

  // Manually connect/disconnect when user changes
  useEffect(() => {
    if (!socket) return;

    if (user && !socket.connected) {
      socket.connect();
    } else if (!user && socket.connected) {
      socket.disconnect();
    }
  }, [socket, user]);

  // Periodically check for disconnection and attempt to reconnect if needed
  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Checking connection.');
      if (user && socket && !socket.connected) {
        console.log('Socket not connected, attempting to reconnect...');
        socket.connect();
      }
    }, 20000);

    return () => clearInterval(interval);
  }, [socket, user]);

  return { state, ping, socket };
};

export const WSProvider = ({ children }: { children: React.ReactNode }) => {
  const data = useWSContextData();
  return <WSContext.Provider value={data}>{children}</WSContext.Provider>;
};

export const useWS = () => useContext(WSContext);
