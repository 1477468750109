import api from '@tyrio/api-factory';
import { PosApi } from '@tyrio/dto';
import { useState } from 'react';
import { useQuery } from 'react-query';

export const useGetPosFilters = (filterValues: qs.ParsedQs) => {
    const [filtersPos, setFiltersPos] = useState<PosApi['getFilters']['response']>({})

    const { data } = useQuery(
        ['get_search_filters'],
        () => api.fetch<PosApi['getFilters']>('get_search_filters', filterValues),
        {
          onSuccess: (res) => {
            setFiltersPos(res);
          },
        }
      );
    

  return {
   filtersPos,
   setFiltersPos
  };
};
