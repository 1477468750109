export const BRAND_GROUP = {
  1: { id: 1, name: 'APOLLOVREDESTEIN' },
  2: { id: 2, name: 'BRIDGESTONE' },
  3: { id: 3, name: 'CONTINENTAL' },
  4: { id: 4, name: 'GOODYEAR' },
  5: { id: 5, name: 'HANKOOK' },
  6: { id: 6, name: 'KUMHO' },
  7: { id: 7, name: 'MICHELIN' },
  8: { id: 8, name: 'NEXEN' },
  9: { id: 9, name: 'OMNI UNITED' },
  10: { id: 10, name: 'PIRELLI' },
  11: { id: 11, name: 'TOYO' },
  12: { id: 12, name: 'VANDENBAN' },
};

export const BRANDS: Record<
  string,
  {
    slug: string;
    name: string;
    brandClass: number;
    brandGroup: string | number;
    origin: string;
    manufacturer?: string;
    default?: boolean;
  }
> = {
  // Premium brands
  BRIDGESTONE: {
    slug: 'bridgestone',
    name: 'BRIDGESTONE',
    brandClass: 1,
    brandGroup: 2, // BRIDGESTONE
    origin: 'JP',
    manufacturer:
      'Bridgestone\n10-1 Kyobashi 1-chome Chuo-ku\nTokyo 104 - 8340, Japan\n',
  },
  CONTINENTAL: {
    slug: 'continental',
    name: 'CONTINENTAL',
    brandClass: 1,
    brandGroup: 3, // CONTINENTAL
    origin: 'DE',
    manufacturer:
      'Continental Aktiengesellschaft\nBüttnerstrasse 25\nD-30165 Hanover, Germany',
    default: true,
  },
  DUNLOP: {
    slug: 'dunlop',
    name: 'DUNLOP',
    brandClass: 1,
    brandGroup: 4, // GOODYEAR
    origin: 'DE',
    manufacturer: 'Goodyear Dunlop Tires\nDunlopstraße 2\nHanau 63450, Germany',
  },
  GOODYEAR: {
    slug: 'goodyear',
    name: 'GOODYEAR',
    brandClass: 1,
    brandGroup: 4, // GOODYEAR
    origin: 'DE',
    manufacturer: 'Goodyear Dunlop Tires\nDunlopstraße 2\nHanau 63450, Germany',
  },
  MICHELIN: {
    slug: 'michelin',
    name: 'MICHELIN',
    brandClass: 1,
    brandGroup: '7', // MICHELIN
    origin: 'FR',
    manufacturer:
      'MICHELIN\nPlaces des Carmes Dechaux\nCLERMONT-FERRAND, France\n',
  },
  PIRELLI: {
    slug: 'pirelli',
    name: 'PIRELLI',
    brandClass: 1,
    brandGroup: '10', // PIRELLI
    origin: 'IT',
    manufacturer: 'PIRELLI\nSede Legale: Viala Sarca 222\n20216 MILANO ITALY',
  },
  METZELER: {
    slug: 'metzeler',
    name: 'METZELER',
    brandClass: 1,
    brandGroup: '10', // PIRELLI
    origin: 'DE',
    manufacturer: '',
  },
  VREDESTEIN: {
    slug: 'vredestein',
    name: 'VREDESTEIN',
    brandClass: 1,
    brandGroup: 1, // APOLLOVREDESTEIN
    origin: 'NL',
    manufacturer: '',
  },
  // Mid brands
  AVON: {
    slug: 'avon',
    name: 'AVON',
    brandClass: 2,
    brandGroup: '',
    origin: 'UK',
    manufacturer: '',
  },
  BARUM: {
    slug: 'barum',
    name: 'BARUM',
    brandClass: 2,
    brandGroup: 3, // CONTINENTAL
    origin: 'CZ',
    manufacturer: '',
  },
  BFGOODRICH: {
    slug: 'bfgoodrich',
    name: 'BFGOODRICH',
    brandClass: 2,
    brandGroup: '7', // MICHELIN
    origin: 'FR',
    manufacturer:
      'MICHELIN\nPlaces des Carmes Dechaux\nCLERMONT-FERRAND, France',
  },
  BKT: {
    slug: 'bkt',
    name: 'BKT',
    brandClass: 2,
    brandGroup: '',
    origin: 'IN',
    manufacturer: '',
  },
  CARLISLE: {
    slug: 'carlisle',
    name: 'CARLISLE',
    brandClass: 2,
    brandGroup: '',
    origin: 'US',
    manufacturer:
      'Carlisle Corporate Headquarters\n11605 N. Community House Rd.\nSuite 600  Charlotte, NC 28277, USA',
  },
  COOPER: {
    slug: 'cooper',
    name: 'COOPER',
    brandClass: 2,
    brandGroup: '',
    origin: 'US',
  },
  FALKEN: {
    slug: 'falken',
    name: 'FALKEN',
    brandClass: 2,
    brandGroup: '',
    origin: 'JP',
  },
  FIRESTONE: {
    slug: 'firestone',
    name: 'FIRESTONE',
    brandClass: 2,
    brandGroup: 2, // BRIDGESTONE
    origin: 'JP',
    manufacturer:
      'Bridgestone\n10-1 Kyobashi 1-chome Chuo-ku\nTokyo 104 - 8340, Japan',
  },
  FULDA: {
    slug: 'fulda',
    name: 'FULDA',
    brandClass: 2,
    brandGroup: 4, // GOODYEAR
    origin: 'DE',
  },
  GENERAL: {
    slug: 'general',
    name: 'GENERAL',
    brandClass: 2,
    brandGroup: 3, // CONTINENTAL
    origin: 'US',
    manufacturer:
      'Continental Tire US Headquarters \n1830 MacMillan Park Dr., Fort Mill, SC 29707\nUSA',
  },
  GITI: {
    slug: 'giti',
    name: 'GITI',
    brandClass: 2,
    brandGroup: '',
    origin: 'CH',
    manufacturer:
      'Giti Tire Pte. Ltd.\n150 Beach Road, #22-01/08 Gateway West, Singapore 189720',
  },
  'GT RADIAL': {
    slug: 'gt-radial',
    name: 'GT RADIAL',
    brandClass: 2,
    brandGroup: '',
    origin: 'SG',
    manufacturer:
      ' PT Gajah Tunggal Tbk\nHead office : Wisma Hayam Wuruk 10th Fl\nJl. Hayam Wuruk 8, Jakarta 10120, Indonesia',
  },
  HANKOOK: {
    slug: 'hankook',
    name: 'HANKOOK',
    brandClass: 2,
    brandGroup: '5', // HANKOOK
    origin: 'KR',
    manufacturer:
      'Hankook Tire Co.,Ltd. \nBldg. 133 Teheran-ro (Yeoksam-dong),\n Gangnam-gu, Seoul, Korea',
  },
  HEIDENAU: {
    slug: 'heidenau',
    name: 'HEIDENAU',
    brandClass: 2,
    brandGroup: '',
    origin: 'DE',
    manufacturer:
      'Reifenwerk Heidenau GmbH & Co. KG\nHauptstrasse 44\n01809 Heidenau GERMANY',
  },
  KLEBER: {
    slug: 'kleber',
    name: 'KLEBER',
    brandClass: 2,
    brandGroup: '7', // MICHELIN
    origin: 'FR',
    manufacturer:
      'MICHELIN\nPlaces des Carmes Dechaux\nCLERMONT-FERRAND, France',
  },
  KUMHO: {
    slug: 'kumho',
    name: 'KUMHO',
    brandClass: 2,
    brandGroup: '6', // KUMHO
    origin: 'KR',
    manufacturer: 'KUMHO TYRE\nBrüsseler Platz 1\n63067 Offenbach - Germany',
    default: true,
  },
  MARANGONI: {
    slug: 'marangoni',
    name: 'MARANGONI',
    brandClass: 2,
    brandGroup: '',
    origin: 'IT',
  },
  MAXXIS: {
    slug: 'maxxis',
    name: 'MAXXIS',
    brandClass: 2,
    brandGroup: '',
    origin: 'TW',
    manufacturer:
      'Cheng Shin Rubber Ind. Co., Ltd.\n8th Fl, World Trade Building\n50, Sec. 1 Shin-Sheng S. Rd.,Taipei, Taiwan',
  },
  MITAS: {
    slug: 'mitas',
    name: 'MITAS',
    brandClass: 2,
    brandGroup: '',
    origin: 'CZ',
  },
  NOKIAN: {
    slug: 'nokian',
    name: 'NOKIAN',
    brandClass: 2,
    brandGroup: '',
    origin: 'FI',
  },
  PREMADA: {
    slug: 'premada',
    name: 'PREMADA',
    brandClass: 2,
    brandGroup: '',
    origin: 'CN',
    manufacturer:
      'Interpneu Handelsgesellschaft mbH\nAn der Rossweid 23 - 25\nDE-76229 Karlsruhe, Germany',
  },
  TOYO: {
    slug: 'toyo',
    name: 'TOYO',
    brandClass: 2,
    brandGroup: '11', // TOYO
    origin: 'CN',
  },
  UNIROYAL: {
    slug: 'uniroyal',
    name: 'UNIROYAL',
    brandClass: 2,
    brandGroup: 3, // CONTINENTAL
    origin: 'DE',
  },
  YOKOHAMA: {
    slug: 'yokohama',
    name: 'YOKOHAMA',
    brandClass: 2,
    brandGroup: '',
    origin: 'JP',
  },
  // Economy brands
  AEOLUS: {
    slug: 'aeolus',
    name: 'AEOLUS',
    brandClass: 3,
    brandGroup: '',
    origin: 'CN',
    manufacturer:
      'AEOLUS TYRE CO. LTD.\n48 Jiaodong South Road, Jiaozuo, Henan \nChina',
  },
  APOLLO: {
    slug: 'apollo',
    name: 'APOLLO',
    brandClass: 3,
    brandGroup: 1, // APOLLOVREDESTEIN
    origin: 'IN',
    manufacturer:
      ' Apollo Tyres Ltd\n7 Institutional Area  Sector 32 - Gurgaon 122001\nIndia ',
  },
  DAVANTI: {
    slug: 'davanti',
    name: 'DAVANTI',
    brandClass: 3,
    brandGroup: '',
    origin: 'CN',
    manufacturer:
      'DAVANTI WORLD LIMITED\nUnit 4, North Florida Road, Haydock, St Helens, Merseyside, United Kingdom, WA11 9UB',
    default: true,
  },
  DAYTON: {
    slug: 'dayton',
    name: 'DAYTON',
    brandClass: 3,
    brandGroup: 2, // BRIDGESTONE
    origin: 'PL',
    manufacturer:
      'Bridgestone\n10-1 Kyobashi 1-chome Chuo-ku\nTokyo 104 - 8340, Japan',
  },
  DEBICA: {
    slug: 'debica',
    name: 'DEBICA',
    brandClass: 3,
    brandGroup: 4, // GOODYEAR
    origin: 'PL',
    manufacturer: 'Goodyear Dunlop Tires\nDunlopstraße 2\nHanau 63450, Germany',
  },
  EVERGREEN: {
    slug: 'evergreen',
    name: 'EVERGREEN',
    brandClass: 3,
    brandGroup: '',
    origin: 'CN',
    manufacturer:
      'Shandong Jinyu Tyre Co.,Ltd. \nDawang Industrial Zone, dongying, Shandong 257335\nChina   ',
  },
  FEDERAL: {
    slug: 'federal',
    name: 'FEDERAL',
    brandClass: 3,
    brandGroup: '',
    origin: 'TW',
  },
  FUZION: {
    slug: 'fuzion',
    name: 'FUZION',
    brandClass: 3,
    brandGroup: 2, // BRIDGESTONE
    origin: 'CN',
  },
  GISLAVED: {
    slug: 'gislaved',
    name: 'GISLAVED',
    brandClass: 3,
    brandGroup: 3, // CONTINENTAL
    origin: 'SK',
    manufacturer:
      'Continental Aktiengesellschaft\nBüttnerstrasse 25\nD-30165 Hanover, Germany',
  },
  GRIPMAX: {
    slug: 'gripmax',
    name: 'GRIPMAX',
    brandClass: 3,
    brandGroup: '',
    origin: 'CN',
    manufacturer:
      'Qingdao Grip Tyre Co. Ltd.\nNo. 917 Tongan Road, Qingdao,   266061  China',
  },
  HERCULES: {
    slug: 'hercules',
    name: 'HERCULES',
    brandClass: 3,
    brandGroup: '',
    origin: 'US',
  },
  KENDA: {
    slug: 'kenda',
    name: 'KENDA',
    brandClass: 3,
    brandGroup: '',
    origin: 'TW',
  },
  KELLY: {
    slug: 'kelly',
    name: 'KELLY',
    brandClass: 3,
    brandGroup: 4, // GOODYEAR
    origin: 'US',
  },
  KORMORAN: {
    slug: 'kormoran',
    name: 'KORMORAN',
    brandClass: 3,
    brandGroup: '7', // MICHELIN
    origin: 'RS',
  },
  LANDSAIL: {
    slug: 'landsail',
    name: 'LANDSAIL',
    brandClass: 3,
    brandGroup: '',
    origin: 'CN',
  },
  LASSA: {
    slug: 'lassa',
    name: 'LASSA',
    brandClass: 3,
    brandGroup: 2, // BRIDGESTONE
    origin: 'TR',
  },
  LAUFENN: {
    slug: 'laufenn',
    name: 'LAUFENN',
    brandClass: 3,
    brandGroup: '5', // HANKOOK
    origin: 'KR',
  },
  MABOR: {
    slug: 'mabor',
    name: 'MABOR',
    brandClass: 3,
    brandGroup: 3, // CONTINENTAL
    origin: 'PT',
  },
  MARSHAL: {
    slug: 'marshal',
    name: 'MARSHAL',
    brandClass: 3,
    brandGroup: '6', // KUMHO
    origin: 'KR',
  },
  MATADOR: {
    slug: 'matador',
    name: 'MATADOR',
    brandClass: 3,
    brandGroup: 3, // CONTINENTAL
    origin: 'SK',
  },
  MOMO: {
    slug: 'momo',
    name: 'MOMO',
    brandClass: 3,
    brandGroup: '',
    origin: 'IT',
  },
  NANKANG: {
    slug: 'nankang',
    name: 'NANKANG',
    brandClass: 3,
    brandGroup: '',
    origin: 'TW',
  },
  NEXEN: {
    slug: 'nexen',
    name: 'NEXEN',
    brandClass: 3,
    brandGroup: '8', // NEXEN
    origin: 'KR',
  },
  NITTO: {
    slug: 'nitto',
    name: 'NITTO',
    brandClass: 3,
    brandGroup: '11', // TOYO
    origin: 'JP',
  },
  NOVEX: {
    slug: 'novex',
    name: 'NOVEX',
    brandClass: 3,
    brandGroup: '12', // VANDENBAN,
    origin: 'CN',
  },
  PETLAS: {
    slug: 'petlas',
    name: 'PETLAS',
    brandClass: 3,
    brandGroup: '',
    origin: 'TR',
  },
  PLATIN: {
    slug: 'platin',
    name: 'PLATIN',
    brandClass: 3,
    brandGroup: '',
    origin: 'CN',
  },
  POINTS: {
    slug: 'points',
    name: 'POINTS',
    brandClass: 3,
    brandGroup: '',
    origin: 'CN',
  },
  PRESTIVO: {
    slug: 'prestivo',
    name: 'PRESTIVO',
    brandClass: 3,
    brandGroup: '',
    origin: 'JP',
  },
  RADAR: {
    slug: 'radar',
    name: 'RADAR',
    brandClass: 3,
    brandGroup: '9', // OMNI UNITED
    origin: 'CN',
  },
  RIKEN: {
    slug: 'riken',
    name: 'RIKEN',
    brandClass: 3,
    brandGroup: '7', // MICHELIN
    origin: 'FR',
  },
  ROADSTONE: {
    slug: 'roadstone',
    name: 'ROADSTONE',
    brandClass: 3,
    brandGroup: '8', // NEXEN
    origin: 'CN',
  },
  SAVA: {
    slug: 'sava',
    name: 'SAVA',
    brandClass: 3,
    brandGroup: 4, // GOODYEAR
    origin: 'SI',
  },
  SEMPERIT: {
    slug: 'semperit',
    name: 'SEMPERIT',
    brandClass: 3,
    brandGroup: 3, // CONTINENTAL
    origin: 'AT',
  },
  SYRON: {
    slug: 'syron',
    name: 'SYRON',
    brandClass: 3,
    brandGroup: '',
    origin: 'DE',
  },
  TAURUS: {
    slug: 'taurus',
    name: 'TAURUS',
    brandClass: 3,
    brandGroup: '7', // MICHELIN
    origin: 'HU',
  },
  TIGAR: {
    slug: 'tigar',
    name: 'TIGAR',
    brandClass: 3,
    brandGroup: '7', // MICHELIN
    origin: 'RS',
  },
  TOMKET: {
    slug: 'tomket',
    name: 'TOMKET',
    brandClass: 3,
    brandGroup: '',
    origin: 'RS',
  },
  TORQUE: {
    slug: 'torque',
    name: 'TORQUE',
    brandClass: 3,
    brandGroup: '', //
    origin: 'CN',
  },
  VIKING: {
    slug: 'viking',
    name: 'VIKING',
    brandClass: 3,
    brandGroup: 3, // CONTINENTAL
    origin: 'SK',
  },
  ZETUM: {
    slug: 'zetum',
    name: 'ZETUM',
    brandClass: 3,
    brandGroup: '6', // KUMHO
    origin: 'KR',
  },
  // Budget brands
  ACCELERA: {
    slug: 'accelera',
    name: 'ACCELERA',
    brandClass: 4,
    brandGroup: '7', // MICHELIN
    origin: 'ID',
  },
  ACHILLES: {
    slug: 'achilles',
    name: 'ACHILLES',
    brandClass: 4,
    brandGroup: '',
    origin: 'ID',
  },
  ADMIRAL: {
    slug: 'admiral',
    name: 'ADMIRAL',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  ALTENZO: {
    slug: 'altenzo',
    name: 'ALTENZO',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  APLUS: {
    slug: 'aplus',
    name: 'APLUS',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  ARISUN: {
    slug: 'arisun',
    name: 'ARISUN',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  ATLAS: {
    slug: 'atlas',
    name: 'ATLAS',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  ATTURO: {
    slug: 'atturo',
    name: 'ATTURO',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  AUTOGRIP: {
    slug: 'autogrip',
    name: 'AUTOGRIP',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  CEAT: {
    slug: 'ceat',
    name: 'CEAT',
    brandClass: 4,
    brandGroup: '',
    origin: 'IN',
  },
  CHAOYANG: {
    slug: 'chaoyang',
    name: 'CHAOYANG',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  CST: {
    slug: 'cst',
    name: 'CST',
    brandClass: 4,
    brandGroup: '',
    origin: 'TW',
  },
  CULTOR: {
    slug: 'cultor',
    name: 'CULTOR',
    brandClass: 4,
    brandGroup: '',
    origin: 'CZ',
  },
  DELINTE: {
    slug: 'delinte',
    name: 'DELINTE',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  DIPLOMAT: {
    slug: 'diplomat',
    name: 'DIPLOMAT',
    brandClass: 4,
    brandGroup: '',
    origin: 'PL',
  },
  DMACK: {
    slug: 'dmack',
    name: 'DMACK',
    brandClass: 4,
    brandGroup: '',
    origin: 'UK',
  },
  'DOUBLE COIN': {
    slug: 'double-coin',
    name: 'DOUBLE COIN',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  'DOUBLE STAR': {
    slug: 'double-star',
    name: 'DOUBLE STAR',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  DURO: {
    slug: 'duro',
    name: 'DURO',
    brandClass: 4,
    brandGroup: '',
    origin: 'TW',
  },
  DURATURN: {
    slug: 'duraturn',
    name: 'DURATURN',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  FIRENZA: {
    slug: 'firenza',
    name: 'FIRENZA',
    brandClass: 4,
    brandGroup: '',
    origin: 'SG',
  },
  FORMULA: {
    slug: 'formula',
    name: 'FORMULA',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  FORTUNA: {
    slug: 'fortuna',
    name: 'FORTUNA',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  FULLRUN: {
    slug: 'fullrun',
    name: 'FULLRUN',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  GOLDLINE: {
    slug: 'gooldline',
    name: 'GOLDLINE',
    brandClass: 4,
    brandGroup: '12', // VANDENBAN,
    origin: 'CN',
  },
  GOODRIDE: {
    slug: 'goodride',
    name: 'GOODRIDE',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  HAIDA: {
    slug: 'haida',
    name: 'HAIDA',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  HIFLY: {
    slug: 'hifly',
    name: 'HIFLY',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  IMPERIAL: {
    slug: 'imperial',
    name: 'IMPERIAL',
    brandClass: 4,
    brandGroup: '',
    origin: 'BE',
  },
  INFINITY: {
    slug: 'infinity',
    name: 'INFINITY',
    brandClass: 4,
    brandGroup: '',
    origin: 'AE',
  },
  INTERSTATE: {
    slug: 'interstate',
    name: 'INTERSTATE',
    brandClass: 4,
    brandGroup: '',
    origin: 'US',
  },
  JINYU: {
    slug: 'jinyu',
    name: 'JINYU',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  KABAT: {
    slug: 'kabat',
    name: 'KABAT',
    brandClass: 4,
    brandGroup: '',
    origin: 'PL',
  },
  LEAO: {
    slug: 'lEAO',
    name: 'LEAO',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  LINGLONG: {
    slug: 'linglong',
    name: 'LINGLONG',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  MAXTREK: {
    slug: 'maxtrek',
    name: 'MAXTREK',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  MASTERSTEEL: {
    slug: 'mastersteel',
    name: 'MASTERSTEEL',
    brandClass: 4,
    brandGroup: '',
    origin: 'NL',
  },
  MENTOR: {
    slug: 'mentor',
    name: 'MENTOR',
    brandClass: 4,
    brandGroup: '',
    origin: 'RS',
  },
  METEOR: {
    slug: 'meteor',
    name: 'METEOR',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  MINERVA: {
    slug: 'minerva',
    name: 'MINERVA',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  MOHAWK: {
    slug: 'mohawk',
    name: 'MOHAWK',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  NEUTON: {
    slug: 'neuton',
    name: 'NEUTON',
    brandClass: 4,
    brandGroup: '',
    origin: 'SG',
  },
  NORDEXX: {
    slug: 'nordexx',
    name: 'NORDEXX',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  ROADHOG: {
    slug: 'roadhog',
    name: 'ROADHOG',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  ROADX: {
    slug: 'roadx',
    name: 'ROADX',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  ROTALLA: {
    slug: 'rotalla',
    name: 'ROTALLA',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  ROVELO: {
    slug: 'rovelo',
    name: 'ROVELO',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  ROCKSTONE: {
    slug: 'rockstone',
    name: 'ROCKSTONE',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  SAILUN: {
    slug: 'sailun',
    name: 'SAILUN',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  SENTURY: {
    slug: 'sentury',
    name: 'SENTURY',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  SHINKO: {
    slug: 'shinko',
    name: 'SHINKO',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  SILVERSTONE: {
    slug: 'silverstone',
    name: 'SILVERSTONE',
    brandClass: 4,
    brandGroup: '11', // TOYO
    origin: 'MY',
  },
  SPORTIVA: {
    slug: 'sportiva',
    name: 'SPORTIVA',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  STARMAXX: {
    slug: 'starmaxx',
    name: 'STARMAXX',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  SUMITOMO: {
    slug: 'sumitomo',
    name: 'SUMITOMO',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  SUNITRAC: {
    slug: 'sunitrac',
    name: 'SUNITRAC',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  SUNNY: {
    slug: 'sunny',
    name: 'SUNNY',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  SUPERIA: {
    slug: 'superia',
    name: 'SUPERIA',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  TIANLI: {
    slug: 'tianli',
    name: 'TIANLI',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  TRAZANO: {
    slug: 'trazano',
    name: 'TRAZANO',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  TRAYAL: {
    slug: 'trayal',
    name: 'TRAYAL',
    brandClass: 4,
    brandGroup: '',
    origin: 'RS',
  },
  TRIANGLE: {
    slug: 'triangle',
    name: 'TRIANGLE',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  TRISTAR: {
    slug: 'tristar',
    name: 'TRISTAR',
    brandClass: 4,
    brandGroup: '',
    origin: 'NL',
  },
  TYFOON: {
    slug: 'tyfoon',
    name: 'TYFOON',
    brandClass: 4,
    brandGroup: '',
    origin: 'NL',
  },
  'VEE RUBBER': {
    slug: 'vee-rubber',
    name: 'VEE RUBBER',
    brandClass: 4,
    brandGroup: '',
    origin: 'TH',
  },
  WANDA: {
    slug: 'wanda',
    name: 'WANDA',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  WANLI: {
    slug: 'wanli',
    name: 'WANLI',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  WESTLAKE: {
    slug: 'westlake',
    name: 'WESTLAKE',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
  ZEETEX: {
    slug: 'zeetex',
    name: 'ZEETEX',
    brandClass: 4,
    brandGroup: '',
    origin: 'ID',
  },
  ZETA: {
    slug: 'zeta',
    name: 'ZETA',
    brandClass: 4,
    brandGroup: '',
    origin: 'CN',
  },
};

export const BRANDS_LIST = Object.values(BRANDS).map((b) => b.name);
export const PREMIUM_BRANDS = Object.values(BRANDS)
  .filter((b) => b.brandClass === 1)
  .map((b) => b.name);
export const MID_BRANDS = Object.values(BRANDS)
  .filter((b) => b.brandClass === 2)
  .map((b) => b.name);
export const ECONOMY_BRANDS = Object.values(BRANDS)
  .filter((b) => b.brandClass === 3)
  .map((b) => b.name);
export const BUDGET_BRANDS = Object.values(BRANDS)
  .filter((b) => b.brandClass === 4)
  .map((b) => b.name);

export const PREMIUM_BRANDS_DETAILS = Object.values(BRANDS).filter(
  (b) => b.brandClass === 1
);
export const MID_BRANDS_DETAILS = Object.values(BRANDS).filter(
  (b) => b.brandClass === 2
);
export const ECONOMY_BRANDS_DETAILS = Object.values(BRANDS).filter(
  (b) => b.brandClass === 3
);
