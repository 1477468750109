/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { AdminWSState, ClientImportState } from '@tyrio/dto';
import { useMemo } from 'react';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import { PageTemplateHeader } from '../../components/PageTemplateV2/PageTemplateV2';
import { useWSAdmin } from '../../context/WSAdminContext';
import { ImportStatusAdminTable } from './import-status/ImportStatusAdminTable';
import { ImportStatusClientTable } from './import-status/ImportStatusClientTable';

// const getImportStatusColor = (state: AdminImportStateItem) => {
//   console.log({ state });
//
//   const isImporting = Object.values(state.activeImports ?? {})
//     .map((activeImport) => {
//       return {
//         importing: !!activeImport.status?.isImporting,
//         finished: !!activeImport.status?.finishedAt,
//         errored: !!activeImport.status?.lastError,
//       };
//     })
//     .reduce(
//       (acc, curr) => {
//         return {
//           importing: curr.importing ?? acc.importing,
//           finished: curr.finished ?? acc.importing,
//           errored: curr.errored ?? acc.errored,
//         };
//       },
//       { importing: false, finished: false, errored: false }
//     );
//
//   console.log({ isImporting });
//   if (isImporting.importing) return 'orange';
//   if (isImporting.errored) return 'red';
//   if (isImporting.finished) return 'limegreen';
//
//   return 'gray';
// };

export const ImportStatus = () => {
  const status = useWSAdmin();

  const clientMap = useMemo(() => {
    const state: Partial<AdminWSState> = {};

    Object.keys(status.state ?? {}).forEach((k: string) => {
      if (k !== 'admin') {
        state[k] = status.state[k];
      }
    });

    return Object.values(state as ClientImportState);
  }, [status.state]);

  return (
    <PageTemplateWrapper>
      <PageTemplateContent>
        <PageTemplateHeader title={'Import status'} />
        <Wrapper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ImportStatusAdminTable />
              {clientMap.map((client) => (
                <ImportStatusClientTable
                  key={`${client.clientId}`}
                  status={client}
                />
              ))}
            </Grid>

            <Grid item xs={12}></Grid>
          </Grid>
        </Wrapper>
      </PageTemplateContent>
    </PageTemplateWrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px;
`;

const StatusCircle = styled.div<{
  status: 'red' | 'orange' | 'green' | 'gray' | string;
}>`
  width: 6px;
  height: 6px;
  background: ${(props) => props.status};
  box-shadow: 0px 0px 3px ${(props) => props.status};
  border-radius: 3px;
  margin-right: 10px;
`;

const AccordionHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
