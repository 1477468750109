import {
  DBClientTransferOrder,
  DBOrderDocument,
  DBTransferOrderItem,
  DBTransferOrderType,
  DBUser,
} from '@prisma/client';
import { InProcessSubStatuses } from './customer-order-item';
import { PrintDataShape } from './pdf';
import { CheckPriceRes } from './price-calculation';

export interface OrderStatusCondition {
  path: string[];
  string_contains: string;
}

export interface DBTransferOrderItemFilters {
  searchType?: 'orderId' | 'reference' | 'ean' | 'erpId';
  searchKeyword?: string;
  source?: string;
  destination?: string;
  startDate?: Date;
  endDate?: Date;
  orderStatus?: DBTransferOrderStatusEnum | 'all';
  pageSize?: string;
  page?: string;
  ean?: string;
  dot?: string | null;
}

export interface TransferOrderByEanResponse {
  createdAt: Date;
  orderName: string;
  branchName: string;
  branchCity: string;
  reserved: number;
  reference: string;
  available: number;
  dots: string[];
  type: DBTransferOrderType;
  documents?: DBOrderDocument[];
  orderNumber?: string;
}

export interface UpcomingTransferOrderByEanResponse {
  branchId: number;
  branchName: string;
  branchCity: string;
  deliveryDate?: Date | null;
  reserved?: number;
  available?: number;
  lineItems: DBTransferOrderLineItem[];
}

export interface LineItem {
  ean: string;
  sku: string;
  quantity: number;
  received: number;
  productName: string;
  productionYear: number;
  manufacturerCode: number;
}

export interface DBTransferOrderCreateRequest {
  items: DBTransferOrderCreateObject[];
  type: DBTransferOrderType;
}

export interface StockOutInfo {
  user: string;
  userId: string;
  issueDate: Date;
  numOfPackages?: number;
  issuedBy: {
    firstName: string;
    lastName: string;
  };
}

//INBOUND ORDERS: destination->inboundBranchId, from->outboundBranchId
//OUTBOUND ORDERS: destination->outboundBranchId, from->inboundBranchId
export interface DBTransferOrderCreateObject {
  inboundBranchId: number;
  outboundBranchId: number;
  outboundBranchDetails: DBTransferOrderBranchDetails;
  remark: string;
  lineItems: DBTransferOrderLineItem[];
  customerOrderReference?: string;
  erpOrderId?: string;
  erpDocNumber?: string;
}

export interface ReservedOnLocation {
  stockLocationId: string;
  qrCode: string;
  reserved: number;
}

export interface DBTransferOrderLineItem {
  ean: string;
  manufacturerCode: string;
  productName: string;
  productionYear: string;
  quantity: number;
  sku: string;
  uid: string;
  received: number;
  dot: string;
  // id of stock list item for which the order was created
  connectedToItem?: string;
  price: number | null;
  cashPrice?: number | null;
  vatCashPrice?: number | null;
  calculatedPrice: Record<string, Partial<CheckPriceRes>>;
  reservedOnLocation?: ReservedOnLocation[];
  inclusiveVatPrice?: number;
  brand?: string;
}

export interface DBTransferOrderBranchDetails {
  branchName: string;
  branchCity?: string;
  address?: string;
  zipCode?: string;
}

interface DBTransferOrderOnePayload {
  orderId?: string;
  status?: DBTransferOrderStatusEnum;
}

interface DBTransferOrderUpdateRequest {
  orderId: string;
  remark?: string;
  orderStatus: TransferOrderStatusShape;
  deliveryType: string | null; // TODO: fix this in db, delivery type can't be null!! default value is local delivery
}

export interface DBTransferOrderItemCountByStatus {
  _count: number;
  orderStatus: DBTransferOrderStatusEnum;
}

export interface DBTransferOrderItemResponse extends DBTransferOrderItem {
  order: DBClientTransferOrder | null;
  DBOrderDocument: DBOrderDocument[] | null;
  createdBy: DBUser | null;
}

export enum DBTransferOrderDeliveryType {
  LCD = 'LCD',
  GLS = 'GLS',
  DPD = 'DPD',
  COLLECTION = 'COLLECTION',
}

export enum DBTransferOrderStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW',
  IN_PROCESS = 'IN_PROCESS',
  ON_HOLD = 'ON_HOLD',
  SHIPPED = 'SHIPPED',
  COLLECTED = 'COLLECTED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
}

export interface TransferOrderStatusShape {
  status: DBTransferOrderStatusEnum;
  subStatus: InProcessSubStatuses | string;
}

interface DBTransferOrderItemApi {
  list: {
    requestBody: DBTransferOrderItemFilters;
    request: DBTransferOrderItemFilters;
    response: { data: DBTransferOrderItemResponse[]; count: number };
  };
  getOne: {
    requestBody: DBTransferOrderOnePayload;
    requestParams: DBTransferOrderOnePayload;
    request: DBTransferOrderOnePayload;
    response: DBTransferOrderItem;
  };
  create: {
    requestBody: DBTransferOrderCreateRequest;
    requestParams: never;
    request: DBTransferOrderCreateRequest;
    response: DBClientTransferOrder[];
  };
  createMany: {
    requestBody: { req: DBTransferOrderCreateRequest[] };
    requestParams: never;
    request: { req: DBTransferOrderCreateRequest[] };
    response: DBClientTransferOrder[];
  };
  updateOne: {
    requestBody: DBTransferOrderUpdateRequest;
    requestParams: DBTransferOrderOnePayload;
    request: DBTransferOrderUpdateRequest;
    response: DBClientTransferOrder;
  };
  preparePrintData: {
    requestBody: never;
    requestParams: { id: string };
    request: { id: string };
    response: PrintDataShape;
  };
  getActive: {
    requestBody: never;
    requestParams: { id?: string };
    request: { id?: string };
    response: DBTransferOrderItem[];
  };
}

export { DBTransferOrderItemApi };
