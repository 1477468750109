/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DBPaymentStatusEnum } from '@prisma/client';
import { cartIcons, TyrioSelectInputOption, tyrioUI } from '@tyrio/ui-library';
import {
  getDropdownLabel,
  parseEnumToArray,
} from '../../../../../helpers/enum-parser';
import styled from '@emotion/styled/macro';
import { FiberManualRecord } from '@mui/icons-material';
import api from '@tyrio/api-factory';
import {
  CustomerOrderServices,
  DBCustomerOrderItemResponse,
  DBCustomerOrderStatusEnum,
  DBCustomerOrderUpdateAddressPayload,
  DBDeliveryTypesApi,
  DBOrderItems,
  LastUpdateDetails,
  OrderSubStatuses,
  SummaryDetails,
  VehicleInfo,
} from '@tyrio/dto';
import _, { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  formatDateAndTime,
  formatEUDate,
  formatEUDateAndTime,
} from '../../../../../helpers/date-helper';
import { InfoCard } from '../../../components/InfoCard/InfoCard';
import pencil_alt from '../assets/pencil-alt.svg';
import {
  AddressCard,
  AddressType,
} from '../components/AddressCard/AddressCard';
import { AppointmentList } from '../components/Appointments';
import { PriceItem } from '../components/PriceItem';
import SelectInput from '../components/SelectInput';
import { TableProductItem } from '../components/TableProductItem';
import { PaymentDetailsModal } from '../modals/PaymentDetailsModal';
import {
  CardInfo,
  CardWrapper,
  CreatedWrapper,
  DropdownWrapper,
  InfoTypography,
  PaymentDetailsWrapper,
  PaymentNameTypography,
  PaymentTypeCodeTypography,
  PaymentTypeTitleTypography,
  ProductCardsWrapper,
  StockWrapper,
  TableHeaderWrapper,
  TotalWrapper,
  Wrapper,
} from '../styles/OrderTabs.style';
import { VehicleInfoCard } from '../components/VehicleInfoCard';
import { vehicle } from 'libs/ui-library/src/assets/cart-icons';

interface OrderTabProps {
  selectedOrderData: any;
  setSelectedOrderData: (val: DBCustomerOrderItemResponse) => void;
  isDisabled: boolean;
  setAddressUpdateData: Dispatch<
    SetStateAction<DBCustomerOrderUpdateAddressPayload>
  >;
  setIsDirty?: (val: boolean) => void;
  isProcessOrder?: boolean;
}

export const OrderTabs = ({
  selectedOrderData,
  setSelectedOrderData,
  isDisabled,
  setAddressUpdateData,
  setIsDirty,
  isProcessOrder,
}: OrderTabProps) => {
  const orderStatuses = parseEnumToArray(DBCustomerOrderStatusEnum).filter(
    (item) => item.value !== 'READY_FOR_SHIPPING'
  );
  const orderSubstatus = parseEnumToArray(OrderSubStatuses);

  const [isPaymentDetailsModalOpen, setIsPaymentDetailsModalOpen] =
    useState(false);

  const lineItems = selectedOrderData.lineItems;

  const [deliveryTypes, setDeliveryTypes] = useState<TyrioSelectInputOption[]>(
    []
  );
  const [shouldIncludeVat, setShouldIncludeVat] = useState(
    (selectedOrderData?.customer as unknown as any)?.customerType === 'B2C'
  );

  useQuery(
    'get_client_delivery_types',
    () => api.fetch<DBDeliveryTypesApi['list']>('get_client_delivery_types'),
    {
      onSuccess: (data) => {
        const dropdownData = data
          .filter((item) => item.isActive)
          .map((item) => ({
            value: item.id,
            label: item.name,
          }));

        setDeliveryTypes(dropdownData);
      },
    }
  );

  const getPrice = (price: number) => {
    const vat = selectedOrderData?.details?.vatPercent;

    const vatAmount = price * (vat / 100);

    if (shouldIncludeVat) return price + vatAmount;
    else return price;
  };

  const getDiscount = (discount: number) => {
    const vat = selectedOrderData?.details?.vatPercent;

    const vatAmount = discount * (vat / 100);

    if (shouldIncludeVat) return discount + vatAmount;
    else return discount;
  };

  const mapServices = () => {
    if (selectedOrderData?.services && !isEmpty(selectedOrderData?.services)) {
      return (selectedOrderData?.services as CustomerOrderServices[]).map(
        (item) => {
          const price = Number(item.price.toString().replace(',', '.'));

          const p = getPrice(price);

          return (
            <TableProductItem
              key={item.erpId}
              title={item.name}
              sku={''}
              ipc={''}
              price={p.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
                currencyDisplay: 'symbol',
              })}
              total={(p * item.quantity).toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
                currencyDisplay: 'symbol',
              })}
              discount={Number(0).toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
                currencyDisplay: 'symbol',
              })}
              qty={item.quantity}
              componentType="product"
              showDate={false}
              isService={true}
              serviceErpId={item.erpId}
            />
          );
        }
      );
    }
    return null;
  };

  const priceSummary: {
    items: number;
    discount: number;
    delivery: number;
    total: number;
    vat: number;
    discountPercentage: number;
  } = useMemo(() => {
    const summary = selectedOrderData?.summary as unknown as SummaryDetails;

    const totalPriceWithoutVat =
      summary.subtotalExVat + summary.deliveryExVat + summary.servicesExVat;

    const vatPercentage = Math.trunc(
      Math.round(
        (summary.vatAmount / (totalPriceWithoutVat - summary.discountExVat)) *
          100
      )
    );

    const obj = {
      items: summary.servicesExVat + summary.subtotalExVat,
      discount: summary.discountExVat,
      delivery: summary.deliveryExVat,
      total: totalPriceWithoutVat + summary.vatAmount - summary.discountExVat,
      vat: vatPercentage,
    };

    const discountPercentage = Math.trunc(
      Math.round((summary.discountExVat / totalPriceWithoutVat) * 100)
    );

    if (!shouldIncludeVat) {
      return { ...obj, discountPercentage };
    } else {
      const vat = vatPercentage / 100 + 1;

      const newDiscount = obj.discount * vat;

      return {
        items: obj.items * vat,
        discount: newDiscount,
        delivery: obj.delivery * vat,
        total: totalPriceWithoutVat * vat - newDiscount,
        vat: vatPercentage,
        discountPercentage,
      };
    }
  }, [shouldIncludeVat, selectedOrderData]);

  const remark = selectedOrderData?.customerRemark?.trim();

  const editedBy = selectedOrderData?.lastEditedBy;

  const lastEditedBy: LastUpdateDetails[] = Array.isArray(editedBy)
    ? editedBy
    : _.isEmpty(editedBy)
    ? []
    : [editedBy];

  const History = () => {
    if (_.isEmpty(lastEditedBy))
      return (
        <Typography variant="caption" color={tyrioUI.colors.black.B50}>
          Order was not edited
        </Typography>
      );

    return (
      <StepperStyled orientation="vertical">
        {lastEditedBy.map((entry, index) => (
          <Step key={index} active={true}>
            <StepLabel icon={<FiberManualRecord fontSize="small" />}>
              <Typography variant="body1">{`User: ${entry.lastUpdatedBy}`}</Typography>
            </StepLabel>
            <StepContent>
              <Typography variant="body2" color="text.secondary">
                {formatDateAndTime(entry.lastUpdateTime)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {entry?.module}
                {entry.purchaseOrderNumber && (
                  <Typography variant="body2" color="text.secondary">
                    ({entry.purchaseOrderNumber})
                  </Typography>
                )}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </StepperStyled>
    );
  };

  const showCardType =
    selectedOrderData.payment?.paymentType === 'CARD_POS' ||
    selectedOrderData.payment?.paymentType === 'CARD_ONLINE' ||
    selectedOrderData.payment?.paymentType === 'CARD_ON_DELIVERY';

  const vehicleInfo = selectedOrderData?.vehicleInfo as unknown as VehicleInfo;

  return (
    <div
      style={{
        maxHeight: 'calc(100svh - 259px)',
        overflowY: 'auto',
      }}
      id="order_details"
    >
      {isPaymentDetailsModalOpen && (
        <PaymentDetailsModal
          open={isPaymentDetailsModalOpen}
          setOpen={setIsPaymentDetailsModalOpen}
          handleClose={() => setIsPaymentDetailsModalOpen(false)}
          selectedOrderData={selectedOrderData}
          setSelectedOrderData={setSelectedOrderData}
        />
      )}

      <DropdownWrapper>
        <SelectInput
          valueName="orderStatus"
          attributeKey={'status'}
          label="Order status"
          dropdownData={orderStatuses}
          selectedOrderData={selectedOrderData}
          setSelectedOrderData={setSelectedOrderData}
          isDisabled={isDisabled || !isProcessOrder}
          setIsDirty={setIsDirty}
        />

        <FormControl fullWidth>
          <InputLabel>Delivery type</InputLabel>
          <Select
            label="Delivery type"
            value={selectedOrderData.deliveryId}
            disabled={isDisabled || !isProcessOrder}
            onChange={(e) => {
              selectedOrderData &&
                setSelectedOrderData({
                  ...selectedOrderData,
                  deliveryId: e.target.value,
                });
            }}
          >
            {deliveryTypes.map((item) => (
              <MenuItem value={item.value} key={item.label}>
                <Typography> {item.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedOrderData.orderStatus.status ===
          DBCustomerOrderStatusEnum.ORDERED && (
          <SelectInput
            valueName="orderStatus"
            attributeKey={'subStatus'}
            label="Order status"
            dropdownData={orderSubstatus}
            selectedOrderData={selectedOrderData}
            setSelectedOrderData={setSelectedOrderData}
            isDisabled={isDisabled || !isProcessOrder}
          />
        )}
      </DropdownWrapper>

      <CardWrapper>
        <AddressCard
          selectedOrderData={selectedOrderData}
          addressDetails={
            selectedOrderData?.billingAddress
              ? selectedOrderData?.billingAddress
              : ''
          }
          addressType={AddressType.billing}
          setAddressUpdateData={setAddressUpdateData}
          isDisabled={isDisabled || !isProcessOrder}
          setIsDirty={setIsDirty}
        />
        <AddressCard
          selectedOrderData={selectedOrderData}
          addressDetails={
            selectedOrderData?.deliveryAddress
              ? selectedOrderData?.deliveryAddress
              : ''
          }
          addressType={AddressType.shipping}
          setAddressUpdateData={setAddressUpdateData}
          isDisabled={isDisabled || !isProcessOrder}
          setIsDirty={setIsDirty}
        />
      </CardWrapper>

      <div
        style={{
          padding: '0 16px',
        }}
      >
        {remark && remark !== '' && (
          <InfoCard
            title={remark}
            subtitle={
              selectedOrderData.details.customerReference
                ? `Reference: ${selectedOrderData.details.customerReference}`
                : ''
            }
            border={`1px solid ${tyrioUI.colors.black.B40}`}
            borderRadius="8px"
          />
        )}
      </div>

      {!_.isEmpty(vehicleInfo) && (
        <div style={{ padding: '16px' }}>
          <VehicleInfoCard
            icon={cartIcons.vehicle}
            text={`${
              vehicleInfo?.brand ? vehicleInfo?.brand.toUpperCase() + ' •' : ''
            } ${
              vehicleInfo?.model ? vehicleInfo?.model.toUpperCase() + ' •' : ''
            } ${vehicleInfo?.year ? vehicleInfo?.year : ''}`}
            details={`${
              vehicleInfo?.engineDisplacementCcm
                ? '' + vehicleInfo?.engineDisplacementCcm + ' ccm •'
                : ''
            } ${
              vehicleInfo?.enginePower ? vehicleInfo?.enginePower + ' kW •' : ''
            } ${
              vehicleInfo?.homologationNumber
                ? vehicleInfo?.homologationNumber + ' •'
                : ''
            }  ${vehicleInfo?.typeApprovalNumber ?? ''}`}
            detailsText={`${vehicleInfo?.licensePlateNumber ?? ''}`}
          />
        </div>
      )}

      {!_.isEmpty(selectedOrderData.appointments) && (
        <Box padding={2}>
          <AppointmentList appointments={selectedOrderData.appointments} />
        </Box>
      )}

      <TableHeaderWrapper>
        <StockWrapper>
          <Typography variant="body2">Price</Typography>
          <Typography variant="body2">Discount</Typography>
          <Typography variant="body2">Qty</Typography>
          <div
            style={{ width: '90px', display: 'flex', justifyContent: 'center' }}
          >
            <Typography variant="body2">Total</Typography>
          </div>
        </StockWrapper>
      </TableHeaderWrapper>
      <ProductCardsWrapper
        style={{
          borderTop: `1px solid ${tyrioUI.colors.black.B40}`,
          borderBottom: `1px solid ${tyrioUI.colors.black.B40}`,
          borderLeft: '0',
          borderRight: '0',
          borderRadius: '0',
        }}
      >
        {selectedOrderData &&
          !_.isEmpty(lineItems) &&
          (lineItems as DBOrderItems[]).map(
            (p: DBOrderItems, index: number) => {
              const price = getPrice(p?.sellingPrice) ?? 1;
              const cashDiscount = p?.cashDiscount ?? 0;
              const discount = getDiscount(cashDiscount * p.quantity);

              const itemPrice = price * p.quantity - discount;

              return (
                <TableProductItem
                  key={index}
                  title={`${p?.brand} ${p?.productName}`}
                  ean={p?.ean}
                  sku={p?.sku}
                  ipc={p?.manufacturerCode}
                  price={price.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                  })} //fali
                  total={itemPrice.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                  })} //fali
                  discount={(discount ?? 0).toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                  })}
                  qty={p?.quantity}
                  componentType="product"
                  deliveryDate={
                    p.deliveryDateFrom && p.deliveryDateTo
                      ? `${p.deliveryDateFrom ?? ''} - ${
                          p.deliveryDateTo ?? ''
                        }`
                      : selectedOrderData.estimatedDeliveryDateFrom &&
                        selectedOrderData.estimatedDeliveryDateTo
                      ? `${
                          formatEUDate(
                            selectedOrderData.estimatedDeliveryDateFrom
                          ) ?? ''
                        } - ${
                          formatEUDate(
                            selectedOrderData.estimatedDeliveryDateTo
                          ) ?? ''
                        }`
                      : ''
                  }
                  slug={p?.purchasing?.supplierSettings?.supplierSlug}
                  purchasePrice={p?.purchasing?.purchasePrice}
                />
              );
            }
          )}
        {mapServices()}
      </ProductCardsWrapper>

      <div style={{ margin: '20px 0', padding: '0 16px' }}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <FormControlLabel
            control={
              <Switch
                id="vatAmount"
                color="info"
                disabled={false}
                checked={shouldIncludeVat}
                size="small"
              />
            }
            label="prices inclusive VAT"
            onClick={() => setShouldIncludeVat(!shouldIncludeVat)}
          />
        </div>
        <Wrapper>
          <ProductCardsWrapper
            style={{
              width: '45%',
              gap: '10px',
              paddingRight: '16px',
            }}
          >
            <Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <PaymentTypeTitleTypography>
                  Payment type:
                </PaymentTypeTitleTypography>
                {!(isDisabled || !isProcessOrder) && (
                  <Tooltip title="Edit payment info">
                    <img
                      src={pencil_alt}
                      alt="pencil_alt"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (!isDisabled) setIsPaymentDetailsModalOpen(true);
                      }}
                    />
                  </Tooltip>
                )}
              </Grid>
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '12px',
                }}
              >
                <PaymentTypeCodeTypography>
                  {selectedOrderData.payment?.code}
                </PaymentTypeCodeTypography>
                <PaymentNameTypography>
                  {selectedOrderData.payment?.name}
                </PaymentNameTypography>
              </Grid>

              <PaymentDetailsWrapper>
                {selectedOrderData.paymentStatus && (
                  <CardInfo>
                    <div>Status:</div>
                    <InfoTypography
                      color={
                        getDropdownLabel(
                          DBPaymentStatusEnum,
                          selectedOrderData.paymentStatus
                        ) === 'Not paid'
                          ? 'red'
                          : '#38A169'
                      }
                    >
                      {getDropdownLabel(
                        DBPaymentStatusEnum,
                        selectedOrderData.paymentStatus
                      )}
                    </InfoTypography>
                  </CardInfo>
                )}
                {selectedOrderData.paymentDetails?.cardInfo && showCardType && (
                  <>
                    {selectedOrderData?.paymentDetails.cardInfo.cardType && (
                      <CardInfo>
                        <div>Card Type:</div>
                        <InfoTypography weight={500}>
                          {selectedOrderData?.paymentDetails.cardInfo.cardType.toUpperCase()}
                        </InfoTypography>
                      </CardInfo>
                    )}
                    {selectedOrderData?.paymentDetails.cardInfo.instalment && (
                      <CardInfo>
                        <div>Number of instalments:</div>
                        <InfoTypography weight={500}>
                          {
                            selectedOrderData?.paymentDetails.cardInfo
                              .instalment
                          }
                        </InfoTypography>
                      </CardInfo>
                    )}
                  </>
                )}

                {selectedOrderData.paymentDetails?.cardInfo &&
                  selectedOrderData?.paymentDetails.cardInfo.transactionId && (
                    <CardInfo>
                      <div>Transaction ID:</div>
                      <InfoTypography>
                        {
                          selectedOrderData?.paymentDetails.cardInfo
                            .transactionId
                        }
                      </InfoTypography>
                    </CardInfo>
                  )}
              </PaymentDetailsWrapper>
            </Grid>
          </ProductCardsWrapper>

          <ProductCardsWrapper
            style={{
              width: '55%',
              background: '#DFE3E8',
              padding: 0,
            }}
          >
            <div style={{ padding: '8px' }}>
              <PriceItem
                title="Items:"
                price={priceSummary.items}
                fontSize="14px"
              />
              {priceSummary.discount > 0 && (
                <PriceItem
                  title={`Discount (${priceSummary.discountPercentage}%):`}
                  price={priceSummary.discount}
                  fontSize="14px"
                  negativeValue={true}
                />
              )}
              <Divider style={{ padding: '5px' }} />
              <PriceItem
                title="Delivery:"
                price={priceSummary.delivery}
                fontSize="14px"
              />
              {!shouldIncludeVat && (
                <PriceItem
                  title={`VAT (${priceSummary.vat}%):`}
                  price={selectedOrderData?.summary.vatAmount ?? 1}
                  fontSize="14px"
                />
              )}
            </div>

            <TotalWrapper>
              <DropdownWrapper style={{ justifyContent: 'space-between' }}>
                <Typography color={'white'} fontSize={'18px'} fontWeight={700}>
                  Total:
                </Typography>
                <Typography color={'white'} fontWeight={700}>
                  {priceSummary.total.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                  })}
                </Typography>
              </DropdownWrapper>
            </TotalWrapper>
          </ProductCardsWrapper>
        </Wrapper>
      </div>

      {selectedOrderData?.details?.couponCode &&
        !_.isEmpty(selectedOrderData?.details?.couponCode) && (
          <div
            style={{
              padding: '0 16px',
            }}
          >
            <InfoCard
              title={'PROMO CODE'}
              subtitle={selectedOrderData?.details?.couponCode ?? ''}
              border={`1px solid ${tyrioUI.colors.black.B40}`}
              borderRadius="8px"
            />
          </div>
        )}

      <div style={{ padding: '0 16px' }}>
        <TextField
          id="internal-remark"
          variant="outlined"
          label="Internal remark"
          disabled={isDisabled || !isProcessOrder}
          fullWidth
          style={{ marginTop: '16px' }}
          value={selectedOrderData?.internalRemark ?? ''}
          onChange={(e) => {
            selectedOrderData &&
              setSelectedOrderData({
                ...selectedOrderData,
                internalRemark: e.target.value,
              });
          }}
        />
      </div>

      <div style={{ padding: '0 16px' }}>
        <CreatedWrapper>
          <div style={{ width: '100%' }}>
            <Typography>Created:</Typography>
            <Typography variant="caption" color={tyrioUI.colors.black.B50}>
              {selectedOrderData?.createdBy &&
              !_.isEmpty(selectedOrderData?.createdBy)
                ? `${selectedOrderData?.createdBy?.firstName ?? ''}
              ${
                selectedOrderData?.createdBy?.lastName
                  ? selectedOrderData?.createdBy?.lastName + ' -'
                  : ''
              }  ${formatEUDateAndTime(selectedOrderData?.orderedAt)}`
                : `${selectedOrderData?.customer?.firstName ?? ''}  
              ${
                selectedOrderData?.customer?.lastName
                  ? selectedOrderData?.customer?.lastName + ' -'
                  : ''
              } ${formatEUDateAndTime(selectedOrderData?.orderedAt)}`}
            </Typography>
          </div>
          <div style={{ width: '100%' }}>
            <Typography>Last edited:</Typography>

            <History />
          </div>
        </CreatedWrapper>
      </div>
    </div>
  );
};

const StepperStyled = styled(Stepper)`
  max-height: 150px;
  overflow: scroll;
  padding-bottom: 10px;
`;
