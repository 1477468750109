import styled from '@emotion/styled';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DBStockCountType } from '@prisma/client';
import { DBStockCountCreate } from '@tyrio/dto';
import { useState } from 'react';
import { useAddStockCount } from '../queries/add-stock-count';
import { useBranchQuery } from '../../../hooks/api/useBranchQuery';
import { useAuth } from '../../../context/AuthContext';

interface AddStockCountProps {
  open: boolean;
  onClose: () => void;
}

export const AddStockCount = (props: AddStockCountProps) => {
  const { user } = useAuth();
  const [calendarOpen, setCalendarOpen] = useState(false);

  const { data: branches, status } = useBranchQuery();

  const [data, setData] = useState<DBStockCountCreate | null>({
    branchId: user?.currentBranchId as number,
    scheduledDate: new Date(),
    dotScan: false,
    dotMandatory: false,
    description: '',
    countType: DBStockCountType.FULL,
  });

  const setReqData = (
    _key: keyof DBStockCountCreate,
    val: DBStockCountType | string | number | Date | boolean
  ) => {
    setData({ ...data, [_key]: val } as DBStockCountCreate);
  };

  const getStyle = (key: DBStockCountType) => {
    if (data?.countType === key) return 'contained';

    return 'outlined';
  };

  const { mutate } = useAddStockCount({ setData });

  const { open, onClose } = props;

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Grid sx={modalStyle}>
        <Grid
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <HeaderTitle>STOCK COUNT</HeaderTitle>
          <HeaderSubtitle>create new counting order</HeaderSubtitle>
          <CountTypeLabel>Choose count type</CountTypeLabel>
          <ButtonGroup
            disableElevation
            variant="outlined"
            aria-label="Disabled button group"
            size="large"
            color="info"
          >
            <Button
              variant={getStyle('FULL')}
              onClick={() => setReqData('countType', 'FULL')}
            >
              Full count
            </Button>
            <Button
              variant={getStyle('PARTIAL')}
              onClick={() => setReqData('countType', 'PARTIAL')}
            >
              Partial count
            </Button>
          </ButtonGroup>
          {status === 'success' && branches.data && (
            <Grid sx={{ width: '60%', marginTop: '36px' }}>
              <FormControl fullWidth>
                <InputLabel>Branch</InputLabel>
                <Select
                  value={data?.branchId}
                  label="Branch"
                  size="medium"
                  onChange={(e) => {
                    setReqData('branchId', e.target.value);
                  }}
                >
                  {branches.data.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.branchName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid sx={{ width: '60%', marginTop: '36px' }}>
            <DatePicker
              label="datePicker"
              inputFormat="dd.MM.yyyy"
              value={data?.scheduledDate ?? new Date()}
              open={calendarOpen}
              onChange={(e) => setReqData('scheduledDate', e as Date)}
              onClose={() => setCalendarOpen(false)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Scheduled date"
                  size="medium"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={() => setCalendarOpen(true)}
                        style={{ cursor: 'pointer' }}
                      >
                        <DateRangeIcon />
                      </InputAdornment>
                    ),
                    endAdornment: null,
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            sx={{ width: '60%', marginTop: '36px' }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="info"
                  defaultChecked
                  checked={data?.dotMandatory}
                  onChange={(e) => {
                    setReqData('dotMandatory', e.target.checked);
                  }}
                />
              }
              label={'DOT mandatory'}
              key={'dotMandatory'}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="info"
                  defaultChecked
                  checked={data?.dotScan}
                  onChange={(e) => {
                    setReqData('dotScan', e.target.checked);
                  }}
                />
              }
              label={'DOT scan'}
              key={'dotScan'}
            />
          </Grid>
          <TextField
            sx={{ width: '60%', marginTop: '36px' }}
            label="Description"
            value={data?.description}
            type="text"
            onChange={(e) => setReqData('description', e.target.value)}
          />

          <ButtonsWrapper>
            <Button
              color="info"
              style={{
                width: '100%',
                height: '60px',
                border: '1px solid rgba(25, 118, 210, 1)',
              }}
              onClick={() => onClose()}
            >
              CANCEL
            </Button>

            <Button
              style={{
                width: '100%',
                height: '60px',
                backgroundColor: 'rgba(25, 118, 210, 1)',
                color: 'white',
              }}
              onClick={() => {
                mutate(data as DBStockCountCreate);
                onClose();
              }}
            >
              SUBMIT
            </Button>
          </ButtonsWrapper>
        </Grid>
      </Grid>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '22px',
  boxShadow: 24,
  p: 4,
  padding: '16px',
};

const HeaderTitle = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.30000001192092896px;
  color: #212b36;
`;

const HeaderSubtitle = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
`;

const CountTypeLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  color: #152b47;
  margin-top: 24px;
  margin-bottom: 8px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: sticky;
  top: 100%;
  gap: 20px;
`;
