import styled from '@emotion/styled';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useContext } from 'react';
import {
  ColumnCardWrapper,
  HeaderText,
} from '../../../steps/Search/SelectStepper';
import { PosSearchContext } from '../SearchContext';

export const DesiredOptionSelector = () => {
  const {
    setCurrentStepIndex,
    currentStepIndex,
    motoDesiredOption,
    setMotoDesiredOption,
    setSelectedMotoDesiredOptions,
  } = useContext(PosSearchContext);

  return (
    <ColumnCardWrapper>
      <HeaderText>Select the desired option</HeaderText>
      <FormControl>
        <CustomRadioGroup
          aria-labelledby="radio-buttons-front-rear-label"
          defaultValue=""
          name="radio-buttons-group"
        >
          <SeasonCard
            value="front"
            control={<Radio checked={motoDesiredOption === 'FRONT'} />}
            selected={motoDesiredOption === 'FRONT'}
            label="Front tyre"
            onClick={() => {
              setCurrentStepIndex(currentStepIndex + 1);
              setSelectedMotoDesiredOptions(['FRONT']);
              setMotoDesiredOption('FRONT');
            }}
          />
          <SeasonCard
            value="rear"
            control={<Radio checked={motoDesiredOption === 'REAR'} />}
            selected={motoDesiredOption === 'REAR'}
            label="Rear tyre"
            onClick={() => {
              setCurrentStepIndex(currentStepIndex + 1);
              setSelectedMotoDesiredOptions(['REAR']);
              setMotoDesiredOption('REAR');
            }}
          />
          <SeasonCard
            value="front/rear"
            control={<Radio checked={motoDesiredOption === 'FRONT/REAR'} />}
            selected={motoDesiredOption === 'FRONT/REAR'}
            label="Front & Rear tyre set"
            onClick={() => {
              setCurrentStepIndex(currentStepIndex + 1);
              setSelectedMotoDesiredOptions(['FRONT/REAR']);
              setMotoDesiredOption('FRONT/REAR');
            }}
          />
        </CustomRadioGroup>
      </FormControl>
    </ColumnCardWrapper>
  );
};

const SeasonCard = styled(FormControlLabel)<{ selected: boolean }>`
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  height: 70px;
  border-radius: 8px;
  border: 1px solid #dfe3e8;
  margin-top: 16px;
  padding: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  background: ${(props) =>
    props.selected ? 'rgba(25, 118, 210, 0.15)' : 'none'};
  border: ${(props) =>
    props.selected ? '2px solid #1976d2' : '1px solid #dfe3e8'};

  &:hover {
    border: 2px solid #1976d2;
  }
`;

const CustomRadioGroup = styled(RadioGroup)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
