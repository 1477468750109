/* eslint-disable @typescript-eslint/no-empty-function */
import { DBServicesApi, PosCartItem } from '@tyrio/dto';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useAuth } from './AuthContext';
import { POSContext } from './POSContext';
import { Timer } from './TimerClass';

export type ActiveInfo =
  | 'CUSTOMER'
  | 'DELIVERY_METHOD'
  | 'APPOINTMENT'
  | 'VEHICLE'
  | 'PAYMENT'
  | '';

export type StockType =
  | 'BRANCHES STOCK'
  | 'SUPPLIERS STOCK'
  | 'ON STOCK'
  | 'UPCOMING ON STOCK'
  | 'SERVICES'
  | '';

export enum VAT {
  R1 = 'Pdv obračunat',
  E1 = 'Prijenos porezne obveze',
  NF = 'Otpremnica',
}

export interface CartData {
  key: string;
  req: PosCartItem;
  stockType: StockType;
  branchId?: number;
  supplierId?: string;
  supplierName?: string;
  supplierSlug?: string;
}

export interface PaymentMethod {
  selectedMethod: string;
  amount: number;
  code: string;
  applyCashDiscount: boolean;
}

export interface CustomerProps {
  firstName: string;
  lastName: string;
  country: string;
  address: string;
  zipCode: string;
  city: string;
  mobilePhone: string;
  email: string;
  passport: string;
  remark: string;
  internalRemark: string;
  companyName: string;
  vat: string;
}

export interface AddressProps {
  address: string;
  zip: string;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  companyName: string;
  fromCustomer: boolean;
}

export interface DeliveryType {
  id: string;
  code: string;
  delivery: string;
  text: string;
  icon: string;
  price: number;
  disabled: boolean;
}

export interface DeliveryMethod {
  address?: AddressProps[];
  deliveryType?: DeliveryType;
}

export interface VehicleProps {
  brand: string;
  year: string;
  model: string;
  engine_displacement: string;
  engine_power: string;
  homologation_nr: string;
  type_approval_number: string;
  licence_plate: string;
}

export interface LocalStorageShape {
  customer: Record<string, string>;
  appointment: Record<string, string>;
  isCustomerEditDisabled: boolean;
  isCustomerSwitchActive: boolean;
  isTyrioCustomer: boolean;
  isSudRegCustomer: boolean;
  delivery_method: DeliveryMethod;
  payment: PaymentMethod[];
  vehicle: Record<string, string>;
  shouldDisableFields?: boolean;
}

interface IPosCartContext {
  activeInfo: ActiveInfo;
  setActiveInfo: (activeInfo: ActiveInfo) => void;
  cartData: CartData[] | [];
  setCartData: Dispatch<SetStateAction<CartData[] | []>>;

  //customer or company details
  customerDetails: Record<string, string>;
  setCustomerDetails: Dispatch<SetStateAction<Record<string, string>>>;
  clearCustomerDetails: () => void;

  //appointments
  appointmentDetails: Record<string, string>;
  setAppointmentDetails: Dispatch<SetStateAction<Record<string, string>>>;
  selectedAppointment: string | null;
  setSelectedAppointment: Dispatch<SetStateAction<string | null>>;

  //vehicle details
  vehicleDetails: Record<string, string>;
  setVehicleDetails: (a: Record<string, string>) => void;
  clearVehicleForm: () => void;

  // payment details
  paymentDetails: PaymentMethod[];
  setPaymentDetails: (a: PaymentMethod[]) => void;
  promoCode: string;
  setPromoCode: (a: string) => void;
  discount: { amount: number; option: string };
  setDiscount: (a: { amount: number; option: string }) => void;
  recommended: DBServicesApi['recommended']['response'];
  setRecommended: Dispatch<
    SetStateAction<DBServicesApi['recommended']['response']>
  >;
  isRecommendedOpen: boolean;
  setIsRecommendedOpen: (a: boolean) => void;

  // delivery address details
  deliveryAddress: AddressProps[];
  setDeliveryAddress: Dispatch<SetStateAction<AddressProps[]>>;
  deliveryPrice: number;
  setDeliveryPrice: Dispatch<SetStateAction<number>>;

  // local storage
  input: LocalStorageShape;
  setInput: Dispatch<SetStateAction<LocalStorageShape>>;

  // timer
  hasItems: boolean;
  setHasItems: Dispatch<SetStateAction<boolean>>;
  openSessionExpiredModal: boolean;
  setOpenSessionExpiredModal: Dispatch<SetStateAction<boolean>>;
  timer: Timer | null;
  clearAllPosCartData: () => void;

  // barcode input
  scannedBarcode: string;
  setScannedBarcode: Dispatch<SetStateAction<string>>;

  // cash discount price
  useCashPrice: boolean;
  setUseCashPrice: Dispatch<SetStateAction<boolean>>;
}

const initializeCartData = () => {
  return [
    {
      key: '',
      req: {
        productName: '',
        productBrand: '',
        productDescription: '',
        price: 0,
        discount: 0,
        rebate: 0,
        quantity: 0,
        total: 0,
        ean: '',
        sku: '',
        uid: '',
        dot: '',
        reserved: 0,
        realQuantity: 0,
        categoryId: 0,
        inclusiveVat: 0,
        cashPrice: 0,
        vatCashPrice: 0,
        purchasePrice: 0,
      },
      stockType: '' as StockType,
    },
  ];
};

export const initializeInput = () => {
  return {
    customer: {},
    appointment: {},
    isCustomerEditDisabled: false,
    isCustomerSwitchActive: false,
    isTyrioCustomer: false,
    isSudRegCustomer: false,
    delivery_method: { address: [] },
    vehicle: {},
    payment: [],
    shouldDisableFields: false,
  };
};

export const PosCartContext = React.createContext<IPosCartContext>({
  activeInfo: '',
  setActiveInfo: () => {},
  cartData: initializeCartData(),
  setCartData: () => {},
  customerDetails: {},
  setCustomerDetails: () => {},
  clearCustomerDetails: () => {},
  appointmentDetails: {},
  setAppointmentDetails: () => {},
  selectedAppointment: null,
  setSelectedAppointment: () => {},
  vehicleDetails: {},
  setVehicleDetails: () => {},
  clearVehicleForm: () => {},
  paymentDetails: [],
  setPaymentDetails: () => {},
  promoCode: '',
  setPromoCode: () => {},
  discount: { amount: 0, option: '%' },
  setDiscount: () => {},
  recommended: [],
  setRecommended: () => {},
  deliveryAddress: [],
  setDeliveryAddress: () => {},
  deliveryPrice: 0,
  setDeliveryPrice: () => {},
  input: initializeInput(),
  setInput: () => {},
  hasItems: false,
  setHasItems: () => {},
  openSessionExpiredModal: false,
  setOpenSessionExpiredModal: () => {},
  timer: null,
  scannedBarcode: '',
  setScannedBarcode: () => {},
  isRecommendedOpen: true,
  setIsRecommendedOpen: () => {},
  useCashPrice: false,
  setUseCashPrice: () => {},
  clearAllPosCartData: () => {},
});

interface PosCartProviderProps {
  children: React.ReactNode;
  value: {
    openSessionExpiredModal: boolean;
    setOpenSessionExpiredModal: Dispatch<SetStateAction<boolean>>;
    timer: Timer | null;
  };
}

const PosCartProvider = ({ children, value }: PosCartProviderProps) => {
  const { client } = useAuth();
  const { setIsPosCheckout } = useContext(POSContext);

  const [input, setInput] = useLocalStorage<LocalStorageShape>(
    '@@pos_cart',
    initializeInput()
  );

  const [activeInfo, setActiveInfo] = useState<ActiveInfo>('');
  const [cartData, setCartData] = useState<CartData[] | []>([]);
  const [customerDetails, setCustomerDetails] = useState<
    Record<string, string>
  >(input.customer ?? { country: client?.address?.countryId ?? '' });
  const [appointmentDetails, setAppointmentDetails] = useState<
    Record<string, string>
  >(input.appointment);
  const [selectedAppointment, setSelectedAppointment] = useState<string | null>(
    null
  );

  const [vehicleDetails, setVehicleDetails] = useState<Record<string, string>>(
    input.vehicle
  );

  const [deliveryAddress, setDeliveryAddress] = useState<AddressProps[]>(
    input.delivery_method.address ?? []
  );
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  const [paymentDetails, setPaymentDetails] = useState<PaymentMethod[]>(
    input.payment
  );
  const [promoCode, setPromoCode] = useState('');
  const [discount, setDiscount] = useState({ amount: 0, option: '%' });

  const [scannedBarcode, setScannedBarcode] = useState('');

  // services
  const [recommended, setRecommended] = useState<
    DBServicesApi['recommended']['response']
  >([]);

  const [isRecommendedOpen, setIsRecommendedOpen] = useState<boolean>(
    recommended.length ? true : false
  );

  // payment with discount
  const [useCashPrice, setUseCashPrice] = useState(false);

  // timer
  const [hasItems, setHasItems] = useState(false);

  const clearCustomerDetails = () => {
    setInput(initializeInput());
    setCustomerDetails({ country: client?.address?.countryId ?? '' });
    setDeliveryAddress([]);
  };

  const clearVehicleForm = () => {
    setVehicleDetails({});
  };

  const clearAllPosCartData = () => {
    clearCustomerDetails();
    clearVehicleForm();
    setPaymentDetails([]);
    setAppointmentDetails({});
    setIsPosCheckout(false);
  };

  return (
    <PosCartContext.Provider
      value={{
        activeInfo,
        setActiveInfo,
        cartData,
        setCartData,
        customerDetails,
        setCustomerDetails,
        clearCustomerDetails,
        appointmentDetails,
        setAppointmentDetails,
        selectedAppointment,
        setSelectedAppointment,
        vehicleDetails,
        setVehicleDetails,
        clearVehicleForm,
        paymentDetails,
        setPaymentDetails,
        promoCode,
        setPromoCode,
        discount,
        setDiscount,
        recommended,
        setRecommended,
        deliveryAddress,
        setDeliveryAddress,
        input,
        setInput,
        hasItems,
        setHasItems,
        openSessionExpiredModal: value.openSessionExpiredModal,
        setOpenSessionExpiredModal: value.setOpenSessionExpiredModal,
        timer: value.timer,
        deliveryPrice,
        setDeliveryPrice,
        scannedBarcode,
        setScannedBarcode,
        isRecommendedOpen,
        setIsRecommendedOpen,
        useCashPrice,
        setUseCashPrice,
        clearAllPosCartData,
      }}
    >
      {children}
    </PosCartContext.Provider>
  );
};

export default PosCartProvider;

export const usePosCartCtx = () => React.useContext(PosCartContext);
