import api from '@tyrio/api-factory';
import { DBStockCountApi, DBStockCountDesktopReq } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { useMutation } from 'react-query';
import { queryClient } from '../../../../../../src/app/query-client';

export const useUpdateStatus = () => {
  const mutation = useMutation(
    (req: DBStockCountDesktopReq) =>
      api.fetch<DBStockCountApi['update_status_desc_stock_count']>(
        'update_status_desc_stock_count',
        {
          ...req,
        }
      ),
    {
      mutationKey: 'update_status_desc_stock_count',
      onSuccess: () => {
        queryClient.invalidateQueries('get_stock_counts');
        queryClient.invalidateQueries('count_stock_count_status');

        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
      },
    }
  );

  return { ...mutation };
};
